<template>
    <div class="container">
        <div class="controls">
            <button
                @click="mali"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-turquoise
                        hover:bg-custom-turquoise-hover
                        focus:outline-none">
                MALI
            </button>

            <!-- <button
                @click="malawi"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-button-green
                        hover:bg-button-green-hover
                        focus:outline-none">
                MALAWI
            </button> -->

            <button
                @click="ethiopia"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-mustard
                        hover:bg-custom-yellow
                        focus:outline-none">
                ETHIOPIA
            </button>

            <button
                @click="kenya"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-orange
                        hover:bg-custom-orange-hover
                        focus:outline-none">
                KENYA
            </button>
        </div>
        <div class="message-section">
            <div
                class="card"
                :style="cardColor"
                v-show="cardMessageOne">
                <span class="card-title">
                    {{cardTitleOne}}
                </span>
                <div class="card-message">
                    {{cardMessageOne}}
                </div>
                <div class="card-image">
                    <img
                        src="../../../assets/images/pregnancy/mother-icon.png"
                        alt="">
                </div>
            </div>

            <div
                class="card"
                :style="cardColor ">
                <span class="card-title">
                    {{cardTitleTwo}}
                </span>
                <div class="card-message">
                    {{cardMessageTwo}}
                </div>
                <div class="card-image">
                    <img
                        src="../../../assets/images/pregnancy/people-icon.png"
                        alt="">
                </div>
            </div>

            <div
                class="card"
                :style="cardColor"
                v-show="cardMessageThree">
                <span class="card-title">
                    {{cardTitleThree}}
                </span>
                <div class="card-message">
                    {{cardMessageThree}}
                </div>
                <div class="card-image">
                    <img
                        src="../../../assets/images/pregnancy/nurse-icon.png"
                        alt="">
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            cardColor: '--gradient: #29CAD3;',
            cardTitleOne: 'PREGNANT WOMAN',
            cardTitleTwo: 'SOCIETY’S EXPECTATION',
            cardTitleThree: 'HEALTH PRACTITIONNERS',
            cardMessageOne: '',
            cardMessageTwo: 'As traumatic as the experience might have been, there’s no space nor person for the new mother to share about it in a vulnerable way. The advices, orders and expectations continue to now care for the newborn appropriately. As a father, the fear (especially financial) remains high: the costs of a child continue to be unpredictable, especially in terms of health care.',
            cardMessageThree: '',

        };
    },
    methods: {
        mali() {
            this.cardColor = '--gradient: #29CAD3;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = '';
            this.cardMessageTwo = 'As traumatic as the experience might have been, there’s no space nor person for the new mother to share about it in a vulnerable way. The advices, orders and expectations continue to now care for the newborn appropriately. As a father, the fear (especially financial) remains high: the costs of a child continue to be unpredictable, especially in terms of health care.';
            this.cardMessageThree = '';
        },

        malawi() {
            this.cardColor = '--gradient: #00475C;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'No findings.';
            this.cardMessageTwo = 'No findings.';
            this.cardMessageThree = 'No findings.';
        },

        ethiopia() {
            this.cardColor = '--gradient: #DCC332;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'The delivery is a very hard moment, but most of the mothers leave the health center 2h to 8h hours after delivery either because it’s not comfortable, they don’t have food or either the midwife sent them home because of lack of bed capacity. Mothers are not able to retain discharge information – also they don\'t see the importance of talking about family planning.';
            this.cardMessageTwo = 'Mothers-in-law and close family members are at home preparing the house to receive the women and the baby, cleaning and cooking for example. They expect the new mother to come home shortly after delivery for them to oversee and care for her and the baby.';
            this.cardMessageThree = 'Its a stressfull moment since I have to keep an eye on the mothers and babies condition. Discharge moment – they feel tired of repeting all the same information, and in discharge they only prioritize immunization and family planning';
        },

        kenya() {
            this.cardColor = '--gradient: #ED6E53;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'I am relieved that I have had my baby and I want to go home. But there is a lot of important information from the nurses and I am worried that I might not be able to remember all of it.';
            this.cardMessageTwo = 'Family members usually take care of the mother around this time, bringing food and making sure that the new mother is comfortable.';
            this.cardMessageThree = '';
        },
    },

};
</script>

<style lang="css" scoped>
.controls {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 1em;
    margin: -5px 0;
    /* flex-wrap: wrap; */

}

.controls > button {
    /* padding: 0.5em 45px 0.5em 45px; */
      width: 100%;

}

.message-section {
     display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1em;
    /* border: 1px solid blue; */
    background-color: white;

}

.card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* word-wrap: break-word; */
    overflow-wrap: break-word;
    /* --gradient:#29CAD3; */
    background-color: var(--gradient);
    width: 30%;
    min-width: 250px;
    border-radius: 5px;
    padding: 1em;
    margin: auto;
    margin-bottom: 3em;
    position: relative;
    animation: moveInBottom 1s ease-out;
}

.card-title {
    font-family: var(--big-john-bold);
    font-size: 0.7em;
    margin: 1.5em 0 1em 0;
}

.card-message {
    font-family: var(--lato);
    font-size: 0.7em;
    min-height: 200px;
}

.card-image {
    margin: 1em auto -3.7em auto;
    height: 55px;
    width: 55px;
}

.card:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--gradient);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 197px;
  bottom: -19px;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }
</style>
