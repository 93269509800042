<template>
    <div class="container">
        <h3>“I’ve always heard general complaints about
            midwives, but I’ve never had a pregnant woman
            tell me to my face the suffering she has gone
            through because of a midwife, until today.
            This really impacted me.”
        </h3>

        <span class="author">
            - Midwife in Mali
        </span>
    </div>
</template>

<script>
export default {

};
</script>

<style lang="css" scoped>
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: moveInBottom 1s ease-out;
    /* border: 1px solid red; */
}

.testimony-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: moveInBottom 1s ease-out;
}

h3 {
    font-family: var(--playfair);
    font-size: 1.7em;
    font-weight: 600;
    text-align: center;
    width: 60%;
    padding: 1em;
}

.author {
    font-family: var(--lato);
    font-weight: 200;
    font-size: 0.7em;
    padding: 1em;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@media (max-width: 420px) {
    h3 {
    font-size: 1.1em;
    width: 100%;
}

.author {
    font-size: 0.7em;
}
}
</style>
