<template>
    <div class="content">
        <div class="parent-container">
            <div class="info-section">
                <h2 class="title">
                    <span>THE PREGNANCY</span>
                    <span>JOURNEY</span>
                </h2>

                <div class="description-section">
                    <span>
                        Dive in the pregnancy journey to learn more about the emotions and needs of pregnant women and newborn mothers across the different countries.
                    </span>
                </div>

                <div class="button-section">
                    <router-link to="/pregnancy">
                        <button
                            class="
                            rounded px-3 py-2
                            font-bold
                            m-1 text-white
                            bg-button-green
                            hover:bg-custom-turquoise-hover
                            transition duration-500 ease-in-out
                            transform hover:-translate-y-1 hover:scale-110
                            focus:outline-none">
                            VIEW THE JOURNEY
                        </button>
                    </router-link>
                </div>

            </div>

            <div class="image-section">
                <img
                    src="../../assets/images/home/journey.svg"
                    alt="">
                <img
                    class="steps"
                    src="../../assets/images/home/steps.svg"
                    alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PregnancyJourneyComp',
};
</script>

<style lang="css" scoped>
.content {
    display: flex;
    justify-content: center;
    /* flex-direction: column; */
    /* height: 90vh; */
    background: var(--light-green);
    /* border: 1px solid goldenrod; */
}

.parent-container {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    padding: 1em;
    width: 80%;
    /* border: 1px solid red; */
}

.info-section, .title, .description-section {
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
}

.info-section {
    padding: 1.4em;
    /* border: 1px solid red; */
    /* margin: 10em 0 0 12em; */
    margin: 5em -3em 0 0;
}

.title {
    font-family: var(--lato);
    font-weight: 600;
    font-size: 3em;
    font-family: var(--big-john-bold);
    /* color: #055975; */
    /* border: 1px solid goldenrod; */
}

.description-section {
    margin: 1.5em 0;
    font-size: 0.8rem;
    /* color: black; */
}

.image-section {
    /* margin: 7em 0 0 -5em; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid blue; */
    }

.image-section > img {
    width: 600px;
    /* height: 600px; */
}

.steps {
    /* border: 1px solid goldenrod; */
    /* margin-top: -600px; */
}

/* media queries */
@media (max-width: 920px) {
    .parent-container {
        flex-wrap: wrap;
    }
}

@media (max-width: 420px) {
    .info-section {
    /* padding: 0em;
    border: 1px solid red;*/
    text-align: center;
    margin: 0;
}

.title {
    /* font-family: var(--lato); */
    /* font-weight: 600; */
    font-size: 2em;
    /* font-family: var(--big-john-bold); */
    /* color: #055975; */
    /* border: 1px solid goldenrod; */
}

.description-section {
    /* margin: 1.5em 0; */
    font-size: 0.8rem;
    /* color: black; */
}

}
/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1200px) {
    .description-section {
        max-width: 400px;
    }
}
</style>
