<template>
    <div class="container">
        <div class="controls">
            <button
                @click="mali"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-turquoise
                        hover:bg-custom-turquoise-hover
                        focus:outline-none">
                MALI
            </button>

            <button
                @click="malawi"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-button-green
                        hover:bg-button-green-hover
                        focus:outline-none">
                MALAWI
            </button>

            <!-- <button
                @click="ethiopia"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-mustard
                        hover:bg-custom-yellow
                        focus:outline-none">
                ETHIOPIA
            </button> -->

            <button
                @click="kenya"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-orange
                        hover:bg-custom-orange-hover
                        focus:outline-none">
                KENYA
            </button>
        </div>
        <div class="message-section">
            <div
                class="card"
                :style="cardColor ">
                <span class="card-title">
                    {{cardTitleOne}}
                </span>
                <div class="card-message">
                    {{cardMessageOne}}
                </div>
                <div class="card-image">
                    <img
                        src="../../../assets/images/pregnancy/mother-icon.png"
                        alt="">
                </div>
            </div>

            <div
                class="card"
                :style="cardColor ">
                <span class="card-title">
                    {{cardTitleTwo}}
                </span>
                <div class="card-message">
                    {{cardMessageTwo}}
                </div>
                <div class="card-image">
                    <img
                        src="../../../assets/images/pregnancy/people-icon.png"
                        alt="">
                </div>
            </div>

            <div
                class="card"
                :style="cardColor">
                <span class="card-title">
                    {{cardTitleThree}}
                </span>
                <div class="card-message">
                    {{cardMessageThree}}
                </div>
                <div class="card-image">
                    <img
                        src="../../../assets/images/pregnancy/nurse-icon.png"
                        alt="">
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            cardColor: '--gradient: #29CAD3;',
            cardTitleOne: 'PREGNANT WOMAN',
            cardTitleTwo: 'SOCIETY’S EXPECTATION',
            cardTitleThree: 'HEALTH PRACTITIONNERS',
            cardMessageOne: 'I often feel fatigue, nausea, pain and emotional ups & downs. I have many doubts, questions & fears, regarding my experience of pregnancy, but also how to discuss it with my husband & within my social circles. I’m not supposed to disclose it at this early stage, so I can’t turn to anyone for support.',
            cardMessageTwo: 'Pregnancy is not something to disclose in the open as long as that isn’t necessary (e.g. visible), and you therefore deal with much of the changes and difficulties of pregnancy silently, discretely, with little overt changes in your lifestyle. Your mother-in-law should be your go-to advisor, regardless of the trust & relationship you have with her.',
            cardMessageThree: 'Many women find excuses for not coming to the health centre as soon as they discover their pregnancy, which makes my understanding of their condition and my ability to support them adequatly much harder and more risky.',

        };
    },
    methods: {
        mali() {
            this.cardColor = '--gradient: #29CAD3;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'I often feel fatigue, nausea, pain and emotional ups & downs. I have many doubts, questions & fears, regarding my experience of pregnancy, but also how to discuss it with my husband & within my social circles. I’m not supposed to disclose it at this early stage, so I can’t turn to anyone for support.';
            this.cardMessageTwo = 'Pregnancy is not something to disclose in the open as long as that isn’t necessary (e.g. visible), and you therefore deal with much of the changes and difficulties of pregnancy silently, discretely, with little overt changes in your lifestyle. Your mother-in-law should be your go-to advisor, regardless of the trust & relationship you have with her.';
            this.cardMessageThree = 'Many women find excuses for not coming to the health centre as soon as they discover their pregnancy, which makes my understanding of their condition and my ability to support them adequatly much harder and more risky.';
        },

        malawi() {
            this.cardColor = '--gradient: #00475C;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'I have to convince my husband that I need to go to the hospital so that he can give me transport money. I must show him that I am really pregnant. Otherwise he will think that I am trying to avoid my duties at home.';
            this.cardMessageTwo = 'Pregnancy is not a reason to avoid household chores or other income generation tasks. A respectable woman / wife should be able to still carry on with her duties , with no interruption – related to the pregnancy.';
            this.cardMessageThree = 'Some first-time mothers will come to the hospital and expect to start receiving care immediately. But I have to turn them back until the pregnancy is palpable or until they are three months pregnant. I can only wait until the pregnancy starts to show because I have no test kits that will help me confirm that pregnancy.';
        },

        ethiopia() {
            this.cardColor = '--gradient: #DCC332;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'No findings.';
            this.cardMessageTwo = 'No findings.';
            this.cardMessageThree = 'No findings.';
        },

        kenya() {
            this.cardColor = '--gradient: #ED6E53;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'I didn’t know that I was pregnant until I felt sick or until my belly became bigger and went to take a test at the chemist. I’m scared of telling by boyfriend and parents that I am pregnant. I’m not sure what to do next, and I’m worried that I might have to drop out of school or stop working.';
            this.cardMessageTwo = 'I might end up with a bad reputation if people find out that I am pregnant. My parents are not likely to support me either. I cannot reveal this pregnancy to anyone at this time, let alone starting to proactively seek pre-natal care.';
            this.cardMessageThree = 'The chemist is one of the most common places that young woman will make contact with during the early phases of pregnancy. However, at this moment, young women who frequent the chemist are in crisis upon discovering the pregnancy. They feel confused about what to do next. This state of confusion means that they are not likely to start actively caring for the pregnancy.';
        },
    },

};
</script>

<style lang="css" scoped>
.controls {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 1em;
    margin: -5px 0;
    /* flex-wrap: wrap; */

}

.controls > button {
    /* padding: 0.5em 45px 0.5em 45px; */
      width: 100%;

}

.message-section {
     display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1em;
    /* border: 1px solid blue; */
    background-color: white;

}

.card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* word-wrap: break-word; */
    overflow-wrap: break-word;
    /* --gradient:#29CAD3; */
    background-color: var(--gradient);
    width: 30%;
    min-width: 250px;
    border-radius: 5px;
    padding: 1em;
    margin: auto;
    margin-bottom: 3em;
    position: relative;
    animation: moveInBottom 1s ease-out;
}

.card-title {
    font-family: var(--big-john-bold);
    font-size: 0.7em;
    margin: 1.5em 0 1em 0;
}

.card-message {
    font-family: var(--lato);
    font-size: 0.7em;
    min-height: 200px;
}

.card-image {
    margin: 1em auto -3.7em auto;
    height: 55px;
    width: 55px;
}

.card:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--gradient);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 197px;
  bottom: -19px;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }
</style>
