<template>
    <div class="container">
        <h3>“…If you go to the health facility before
            your pregnancy starts showing, the nurses
            will send you back home.”
        </h3>

        <span class="author">
            - Pregnant Woman in Malawi
        </span>
    </div>
</template>

<script>
export default {

};
</script>

<style lang="css" scoped>
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: moveInBottom 1s ease-out;
    /* min-height: 400px; */
    min-height: 295px;
}

.testimony-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: moveInBottom 1s ease-out;
}

h3 {
    font-family: var(--playfair);
    font-size: 1.7em;
    font-weight: 600;
    text-align: center;
    width: 60%;
    padding: 1em;
}

.author {
    font-family: var(--lato);
    font-weight: 200;
    font-size: 0.7em;
    padding: 1em;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@media (max-width: 420px) {
    h3 {
    font-size: 1.1em;
    width: 100%;
}

.author {
    font-size: 0.7em;
}
}
</style>
