<template>
    <div class="main-container">
        <section class="header-section">
            <nav-header/>
        </section>

        <section class="downloads-section">
            <downloads/>
        </section>

        <section class="footer">
            <nav-footer/>
        </section>
    </div>
</template>

<script>
import NavHeader from '@/components/Resources/Header.vue';
import Downloads from '@/components/Resources/Downloads.vue';
import NavFooter from '@/components/UI/Footer.vue';

export default {
    name: 'Resources',
    components: {
        NavHeader,
        Downloads,
        NavFooter,
    },
    setup() {
        const resources = () => {
         event('resources', { method: 'Google' })
       }
       
       return {
        resources
       }
    },
};
</script>

<style>
.main-container {
    display: flex;
    flex-direction: column;
}

</style>
