<template>
    <div class="main-container">
        doo
        <section class="section1"></section>
        <section class="section2"></section>
        <section class="section3"></section>
        <section class="section4"></section>
    </div>
</template>

<script>
export default {
    name: 'Car',

    data() {
        return {
        };
    },
};
</script>

<style lang="css" scoped>
.main-container {
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
    border: 1px solid goldenrod;
}

.section1 {
    background-image:
    linear-gradient( rgba(5, 5, 5, 0.6), rgba(0, 0, 0, 0.65)),
    url('../assets/images/home/insight_images/ethiopia1_insight_photo.jpg');
    background-size: 100% 100%;
    min-height: 90vh;
}

.section2 {
    background-image:
    linear-gradient( rgba(0, 0, 0, 0.6), rgba(11, 12, 12, 0.65)),
    url('../assets/images/home/insight_images/ethiopia2_insight_photo.jpg');
    background-size: 100% 100%;
    min-height: 90vh;
}

.section3 {
    background-image:
    linear-gradient( rgba(5, 5, 5, 0.6), rgba(11, 12, 12, 0.65)),
    url('../assets/images/home/insight_images/malawi_insights_photo.jpg');
    background-size: 100% 100%;
    min-height: 90vh;
}

.section4 {
    background-image:
    linear-gradient( rgba(5, 5, 5, 0.6), rgba(11, 12, 12, 0.65)),
    url('../assets/images/home/insight_images/mali_insights_photo.jpg');
    background-size: 100% 100%;
    min-height: 90vh;
}

</style>
