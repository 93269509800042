<template>
    <!-- eslint-disable max-len -->
    <div class="content">
        <div class="navbar-section">
            <nav-bar/>
        </div>

        <div class="header-body">
            <div
                class="title"
                id="title"
                ref="mytitle">
                <h1 class="title-header">
                    <span class="leading__title">Explore</span>
                    <span class="middle__title">BEHAVIORAL DESIGN</span>
                    <span class="end__title">
                        <span class="for"> for</span>
                        <span class="maternal">  MATERNAL HEALTH</span>
                    </span>
                </h1>

                <div class="background-info">
                    <span>
                    Jhpiego and ThinkPlace collaborated with hundreds of women, their families, providers, and community members in Ethiopia, Kenya, Malawi, and Mali to explore how we might develop service delivery approaches that provide safe, respectful and valuable experience for women and their health providers. We have compiled this emerging and ongoing work here, in the hopes it may be helpful to others.
                    </span>
                </div>

                <div class="title-image">
                    <img
                        src="../../assets/images/home/Pregnant_Woman-1.png"
                        alt="woman">
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import NavBar from '@/components/UI/NavBar.vue';

export default {
    name: 'Header',
    components: {
        NavBar,
    },
};
</script>

<style lang="css" scoped>
.content {
    display: flex;
    flex-direction: column;
    min-height: 110vh;
    background-color: var(--dark-green);
    background-image: url('../../assets/images/home/hero-bg.png');
    background-size: 100% 100%;
}

.header-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.title {
    display: flex;
    /* flex: 2; */
    padding: 1em;
    flex-direction: column;
    justify-content: space-evenly;
    color: var(--white);
}

.title-header, .background-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.title-header {
    font-size: 3em;
    font-weight: 800;
    letter-spacing: 2px;
}

.leading__title {
    font-family: var(--playfair);
    font-weight: 900;
    animation: moveInRight 1s ease-out;
}

.middle__title {
    font-family: var(--big-john-bold);
    color: var(--dark-mustard);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
}

.maternal {
    font-family: var(--big-john-bold);
    color: var(--light-green);
    animation: moveInRight 1s ease-out;

}

.for {
    font-family: var(--playfair);
    animation: moveInRight 1s ease-out;
}

.end__title {
    animation: moveInRight 1s ease-out;
}

.background-info {
    font-size: 0.8rem;
    animation: moveInRight 1s ease-out;
}

.title-image {
    visibility: hidden;
    animation: moveInBottom 1s ease-out;
}

.title-image > img{
    height: 350px;
    margin: 1.7em auto 0;
}

/* Animations */

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

/* Responsiveness */
@media only screen and (max-width: 1340px) {
    .header-body {
        font-size: 0.9em;
    }

    .title {
        width: 50%;
    }
}

/* Big screen devices (889px and above) */
@media only screen and (max-width: 889px) {}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
    .content {
        min-height: 109vh;
    }

    .header-body {
        font-size: 0.5em;
    }

    .title {
        width: 50%;
    }
}

@media (max-width: 650px) {
    .content {
        background: var(--dark-green);
    }

     .title {
        width: 95%;
        padding: 1em;
    }

    .title-header {
        font-size: 4em;
    }

    .background-info {
        font-size: 1.3em;
    }

    .title-image {
        visibility: visible;
    }
}

/* For extremely small screen devices (595px and below) */
@media only screen and (max-width: 595px) {}

/* Small screen devices (600px and above) */
@media only screen and (min-width: 600px) {}

/* Medium screen devices (768px and above) */
@media only screen and (min-width: 768px) {}

/* Big screen devices (889px and above) */
@media only screen and (min-width: 889px) {}

/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1200px) {
    .background-info {
        max-width: 520px;
        margin: auto;
    }
}
</style>
