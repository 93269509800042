<template>
    <div class="container">
        <h3>“In my last pregnancy the midwife hurt me during a
            vaginal examination. She was rough and didn’t ask
            me how I was. I lost my trust in midwives. That’s
            why I haven’t been to the CSCOM in my current pregnancy.
            I’m scared the midwives will cause me pain again.”
        </h3>

        <span class="author">
            - Pregnant woman in Mali
        </span>
    </div>
</template>

<script>
export default {

};
</script>

<style lang="css" scoped>
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: moveInBottom 1s ease-out;
    min-height: 295px;
    /* border: 1px solid red; */
}

.testimony-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: moveInBottom 1s ease-out;
}

h3 {
    font-family: var(--playfair);
    font-size: 1.7em;
    font-weight: 600;
    text-align: center;
    width: 60%;
    padding: 1em;
}

.author {
    font-family: var(--lato);
    font-weight: 200;
    font-size: 0.7em;
    padding: 1em;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@media (max-width: 420px) {
    h3 {
    font-size: 1.1em;
    width: 100%;
}

.author {
    font-size: 0.7em;
}
}
</style>
