<template>
    <div class="main-conatiner">

        <div class="parent-container">
            <div class="info-section">
                <h2 class="title">
                    <span>PROJECT</span>
                    <span>OVERVIEW</span>
                </h2>

                <div class="description-section">
                    <span>
                        Beginning in 2020, a team of ThinkPlace designers, Jhpiego colleagues, and local country research teams worked across four countries in Sub-Saharan Africa (Mali, Ethiopia, Kenya, and Malawi). Hover over the map to learn more about the activities and check out the resources section for details.
                    </span>
                    <!-- <span class="click-instruction">
                        Click on a country for more info:</span> -->
                </div>

            </div>

            <div class="map-section-container">
                <div class="map-section">
                    <div
                        class="mali"
                        @mouseover="modalMali = true"></div>
                    <div
                        class="malawi"
                        @mouseover="modalMalawi = true"></div>
                    <div
                        class="ethiopia"
                        @mouseover="modalEthiopia = true"></div>
                    <div
                        class="kenya"
                        @mouseover="modalKenya = true"></div>
                </div>
            </div>

            <!-- <div class="card-section">
                <div class="info-card">
                    <mali-info/>
                    <malawi-info/>
                    <ethiopia-info/>
                    <kenya-info/>
                </div>
            </div> -->
        </div>

        <!-- Modal Mali -->
        <transition
            name="fade"
            appear>
            <div
                class="modal-overlay"
                v-if="modalMali"
                @click="modalMali = false"></div>
        </transition>
        <transition
            name="slide"
            appear>
            <div
                class="modal"
                v-if="modalMali">

                <div class="close-btn">
                    <button
                        @click="modalMali = false"
                        class="inline-flex
                            items-center
                            justify-center
                            w-6 h-6 mr-2
                            text-red-500
                            transition-colors
                            duration-150
                            font-bold
                            bg-white rounded-full
                            focus:shadow-outline
                            hover:bg-custom-mustard">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor">
                            <path
                                fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0
                                011.414 0L10 8.586l4.293-4.293a1 1 0
                                111.414 1.414L11.414 10l4.293 4.293a1 1
                                0 01-1.414 1.414L10 11.414l-4.293 4.293a1
                                1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>

                <div class="header">
                    <div class="header__sec__1">
                        <span class="stat">286</span>
                        <span class="item">PEOPLE</span>
                    </div>
                    <div class="header__sec__2">
                        <span class="stat">06</span>
                        <span class="item">LOCATIONS</span>
                    </div>
                    <div class="header__sec__3">
                        <span class="stat">31</span>
                        <span class="item">DAYS</span>
                    </div>
                </div>

                <div class="country">
                    <span class="country__heading">
                        MALI
                    </span>

                    <div class="country-block">
                        <span class="country__subheader">
                            MAIN FOCUS
                        </span>
                        <span class="country__description">
                            Emphasizing the care in maternal healthcare services
                        </span>
                    </div>

                    <div class="country-block">
                        <span class="country__subheader">
                            THE CHALLENGE
                        </span>
                        <span class="country__description">
                            How might we improve the maternal healthcare experience for pregnant women, their partners,
and midwives?
                        </span>
                    </div>

                    <div class="country-block">
                        <span class="country__subheader">LOCATIONS</span>
                        <span class="country__description">Bamako</span>
                        <span class="country__description">Kita</span>
                        <span class="country__description">Koutiala</span>
                    </div>

                    <div class="country-block">
                        <span class="country__subheader">REPORTS</span>
                        <span class="country__description">
                            <ul>
                                <li>
                                    <a
                                    :href="`${publicPath}resources/mali/Mali-Insights-Report(English).pdf`"
                                    class="mali-download"
                                    target="_blank">Insights (EN) </a>
                                </li>
                                <li>
                                    <a
                                    :href="`${publicPath}resources/mali/Mali-Insights-Report(French).pdf`"
                                    class="mali-download"
                                    target="_blank"> Insights (FR) </a>
                                </li>
                                <li>
                                    <a
                                    :href="`${publicPath}resources/mali/Mali-Prototyping&Recommendations-Report-Mali_V2.pdf`"
                                    class="mali-download"
                                    target="_blank">Prototyping</a>
                                </li>
                                <li>
                                    <a
                                    :href="`${publicPath}resources/mali/jhpiego_Mali_pitch deck_first_draft.pdf`"
                                    class="mali-download"
                                    target="_blank"> Prototype Refining </a>
                                </li>
                            </ul>
                        </span>
                    </div>

                    <div class="country-block">
                        <span class="country__subheader">STATUS (May 2022)</span>
                        <span class="country__description">Jhpiego, midwives in Mali, and other partners are currently designing and piloting an
                            intervention called Club Courage, a compilation of successful prototypes, to leverage peer-topeer midwife support.</span>
                    </div>
                </div>

            </div>
        </transition>

        <!-- Modal Malawi -->
        <transition
            name="fade"
            appear>
            <div
                class="modal-overlay"
                v-if="modalMalawi"
                @click="modalMalawi = false"></div>
        </transition>
        <transition
            name="slide"
            appear>
            <div
                class="modal"
                v-if="modalMalawi">
                <div class="close-btn">
                    <button
                        @click="modalMalawi = false"
                        class="inline-flex
                            items-center
                            justify-center
                            w-6 h-6 mr-2
                            text-red-500
                            transition-colors
                            duration-150
                            font-bold
                            bg-white rounded-full
                            focus:shadow-outline
                            hover:bg-custom-mustard">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor">
                            <path
                                fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0
                                011.414 0L10 8.586l4.293-4.293a1 1 0
                                111.414 1.414L11.414 10l4.293 4.293a1 1
                                0 01-1.414 1.414L10 11.414l-4.293 4.293a1
                                1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>

                <div class="header header-malawi">
                    <div class="header__sec__1">
                        <span class="stat">136</span>
                        <span class="item">PEOPLE</span>
                    </div>
                    <div class="header__sec__2">
                        <span class="stat">04</span>
                        <span class="item">LOCATIONS</span>
                    </div>
                    <div class="header__sec__3">
                        <span class="stat">28</span>
                        <span class="item">DAYS</span>
                    </div>
                </div>

                <div class="country">
                    <span class="country__heading">
                        MALAWI
                    </span>

                    <div class="country-block">
                        <span class="country__subheader">
                            MAIN FOCUS
                        </span>
                        <span class="country__description">
                            Increase the proportion of women who seek ANC during the first trimester and who complete all
eight recommended ANC contacts. 
                        </span>
                    </div>

                    <div class="country-block">
                        <span class="country__subheader">
                            THE CHALLENGE
                        </span>
                        <span class="country__description">
                            How might we ensure ANC services are responsive to pregnant women’s needs?
                        </span>
                    </div>

                    <div class="country-block">
                        <span class="country__subheader">LOCATIONS</span>
                        <span class="country__description">Limbe</span>
                        <span class="country__description">Mpemba</span>
                        <span class="country__description">Mdeka</span>
                        <span class="country__description">Zingwangwa</span>
                    </div>

                    <div class="country-block">
                        <span class="country__subheader">REPORTS</span>
                        <span class="country__description">
                            <ul>
                                <li>
                                    <a
                                    :href="`${publicPath}resources/malawi/Malawi-Insights&Recommendations-Report.pdf`"
                                    class="malawi-download"
                                    target="_blank">Insights</a>
                                </li>
                                <li>
                                    <a
                                    :href="`${publicPath}resources/malawi/Malawi_Proposed_Solutions_eng.pdf`"
                                    class="malawi-download"
                                    target="_blank"> Prototyping </a>
                                </li>
                            </ul>
                        </span>
                    </div>

                    <div class="country-block">
                        <span class="country__subheader">STATUS (May 2022)</span>
                        <span class="country__description">Jhpiego and John Hopkins Research Project are currently working on a facility-driven quality
                            improvement initiative, work inspired by one of the prototypes ANC 2.0. 
                        </span>
                    </div>
                </div>

            </div>
        </transition>

        <!-- Modal Ethiopia -->
        <transition
            name="fade"
            appear>
            <div
                class="modal-overlay"
                v-if="modalEthiopia"
                @click="modalEthiopia = false"></div>
        </transition>
        <transition
            name="slide"
            appear>
            <div
                class="modal"
                v-if="modalEthiopia">

                <div class="close-btn">
                    <button
                        @click="modalEthiopia = false"
                        class="inline-flex
                            items-center
                            justify-center
                            w-6 h-6 mr-2
                            text-red-500
                            transition-colors
                            duration-150
                            font-bold
                            bg-white rounded-full
                            focus:shadow-outline
                            hover:bg-custom-mustard">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor">
                            <path
                                fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0
                                011.414 0L10 8.586l4.293-4.293a1 1 0
                                111.414 1.414L11.414 10l4.293 4.293a1 1
                                0 01-1.414 1.414L10 11.414l-4.293 4.293a1
                                1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>

                <div class="header header-ethiopia">
                    <div class="header__sec__1">
                        <span class="stat">263</span>
                        <span class="item">PEOPLE</span>
                    </div>
                    <div class="header__sec__2">
                        <span class="stat">03</span>
                        <span class="item">LOCATIONS</span>
                    </div>
                    <div class="header__sec__3">
                        <span class="stat">23</span>
                        <span class="item">DAYS</span>
                    </div>
                </div>

                <div class="country">
                    <span class="country__heading">
                        ETHIOPIA
                    </span>

                    <div class="country-block">
                        <span class="country__subheader">
                            MAIN FOCUS
                        </span>
                        <span class="country__description">
                            Increase the uptake of postnatal
                            care services amongst women in
                            rural Ethiopia
                        </span>
                    </div>

                    <div class="country-block">
                        <span class="country__subheader">
                            THE CHALLENGE
                        </span>
                        <span class="country__description">
                            How might we reach women with key PNC services in the critical first week of life?
                        </span>
                    </div>

                    <div class="country-block">
                        <span class="country__subheader">LOCATIONS</span>
                        <span class="country__description">Arsi</span>
                        <span class="country__description">Bahir Dar</span>
                        <span class="country__description">Farta</span>
                    </div>

                    <div class="country-block">
                        <span class="country__subheader">REPORTS</span>
                        <span class="country__description">
                            <ul>
                                <li>
                                    <a
                                    :href="`${publicPath}resources/ethiopia/Ethiopia-Insights-Report-Cross-sharing-platform.pdf`"
                                    class="ethio-download"
                                    target="_blank">Insights</a>
                                </li>
                                <li>
                                    <a
                                    :href="`${publicPath}resources/ethiopia/Ethiopia-Prototyping-Report.pdf`"
                                    class="ethio-download"
                                    target="_blank"> Prototyping </a>
                                </li>
                            </ul>
                        </span>
                    </div>

                    <div class="country-block">
                        <span class="country__subheader">STATUS (May 2022)</span>
                        <span class="country__description">Jhpiego, the Ethiopian Ministry of Health, Dire Health Center, and other partners designed and
                            are piloting Family-Led Postnatal Care (FPNC), an intervention that draws upon successful,
                            tested prototypes to bring PNC services directly into the homes of postnatal women. </span>
                    </div>
                </div>

            </div>
        </transition>

        <!-- Modal Kenya -->
        <transition
            name="fade"
            appear>
            <div
                class="modal-overlay"
                v-if="modalKenya"
                @click="modalKenya = false"></div>
        </transition>
        <transition
            name="slide"
            appear>
            <div
                class="modal"
                v-if="modalKenya">

                <div class="close-btn">
                    <button
                        @click="modalKenya = false"
                        class="inline-flex
                            items-center
                            justify-center
                            w-6 h-6 mr-2
                            text-red-500
                            transition-colors
                            duration-150
                            font-bold
                            bg-white rounded-full
                            focus:shadow-outline
                            hover:bg-custom-mustard">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor">
                            <path
                                fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0
                                011.414 0L10 8.586l4.293-4.293a1 1 0
                                111.414 1.414L11.414 10l4.293 4.293a1 1
                                0 01-1.414 1.414L10 11.414l-4.293 4.293a1
                                1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>

                <div class="header header-kenya">
                    <div class="header__sec__1">
                        <span class="stat">130</span>
                        <span class="item">PEOPLE</span>
                    </div>
                    <div class="header__sec__2">
                        <span class="stat">02</span>
                        <span class="item">LOCATIONS</span>
                    </div>
                    <div class="header__sec__3">
                        <span class="stat">23</span>
                        <span class="item">DAYS</span>
                    </div>
                </div>

                <div class="country">
                    <span class="country__heading">
                        KENYA
                    </span>

                    <div class="country-block">
                        <span class="country__subheader">
                            MAIN FOCUS
                        </span>
                        <span class="country__description">
                            Improving the maternal healthcare service experience during ANC, delivery, and PNC
                        </span>
                    </div>

                    <div class="country-block">
                        <span class="country__subheader">
                            THE CHALLENGE
                        </span>
                        <span class="country__description">
                            How might we enable pregnant women to receive quality, respectful care throughout her
pregnancy journey? 
                        </span>
                    </div>

                    <div class="country-block">
                        <span class="country__subheader">LOCATIONS</span>
                        <span class="country__description">Eastleigh</span>
                        <span class="country__description">Kibera</span>
                        <span class="country__description">Ahero</span>
                    </div>

                    <div class="country-block">
                        <span class="country__subheader">REPORTS</span>
                        <span class="country__description">
                            <ul>
                                <li> 
                                    <a
                                    :href="`${publicPath}resources/kenya/Community_Consultations_Insights_Report.pdf`"
                                    class="ken-download"
                                    target="_blank">Community Consultation</a> 
                                </li> 
                                <li> 
                                <a
                                    :href="`${publicPath}resources/kenya/Concept_Notes.pdf`"
                                    class="ken-download"
                                    target="_blank">Concept Notes</a>
                                </li>
                            </ul>
                        </span>
                    </div>

                    <div class="country-block">
                        <span class="country__subheader">STATUS  (May 2022)</span>
                        <span class="country__description">Jhpiego, the Muranga Country Health Management Team, and others are supporting intrapartum
                            care through the development of a Birth Companion intervention package. </span>
                    </div>
                </div>

            </div>
        </transition>
    </div>
</template>

<script>


export default {
    name: 'Overview',

    data() {
        return {
            publicPath: process.env.BASE_URL,
            modalMali: false,
            modalMalawi: false,
            modalEthiopia: false,
            modalKenya: false,
        };
    },
};
</script>

<style lang="css" scoped>
.main-conatiner {
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    /* min-height: 90vh; */
    padding: 2em 1em;
    background: var(--light-blue);
    /* border: 1px solid goldenrod; */
}

.parent-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    padding: 1em;
}

.info-section, .title, .description-section {
    display: flex;
    flex-direction: column;
}

.info-section {
    flex: 1;
    /* padding: 2em; */
    justify-content: center;
    /* border: 1px solid red; */
    /* border: 1px solid red; */
}

.title {
    font-weight: 900;
    font-size: 3em;
    color: #055975;
    font-family: var(--big-john-bold);
}

.description-section {
    margin: 1.5em 0;
    font-size: 0.8rem;
    color: black;
}

.map-section-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

.map-section {
    position: relative;
    background: url('../../assets/images/home/Map.svg');
    background-size: 100% 100%;
    max-width: 100vw;
    height: 500px;
    width: 500px;
    /* border: 1px solid red; */
}

.mali, .malawi, .ethiopia, .kenya {
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.9);
    animation: pulse 2s linear 1s infinite;
}

.mali {
    top: 25%;
    left: 19.4%;
}

.malawi {
    top:67.5%;
    left:70%;
}

.ethiopia {
    top: 36%;
    left:76.5%;
}

.kenya {
    top: 48.4%;
    left:75.2%;
}

/* for mobile devices  */
.card-section {
    display: none;
}

/* modal styles */
.modal-overlay {
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 z-index: 98;
 background-color: rgba(0, 0, 0, 0.3);
}

.modal {
 position: fixed;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 z-index: 99;
 width: 100%;
 max-width: 350px;
 background: transparent;
 border-radius: 16px;
 /* overflow: scroll; */
 max-height: calc(90vh - 5px);
 overflow-y: auto;
}

.close-btn {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 0.5em;
    /* background: turquoise; */
}

.fade-enter-active,
.fade-leave-active {
 transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
 opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
 transition: transform .5s;
}

.slide-enter,
.slide-leave-to {
 transform: translateY(-50%) translateX(100vw);
}

/* modal styles */
.header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1em;
    height: 120px;
    background: var(--teal);
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}

.header__sec__1,
.header__sec__2,
.header__sec__3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
}

.header__sec__1, .header__sec__2 {
    border-right: 4px solid var(--white);
}

.stat {
    font-size: 5em;
    font-family: var(--bebas);
}

.item {
    font-size: 0.6em;
    font-family: var(--lato);
    margin-top: -30px;
}

.country {
    padding: 1em;
    background-color: #FFF;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}
.country ul li {list-style: disc;}
.country ul{padding-left:20px;}
.country__heading {
    color: var(--dark-green-3);
    font-family: var(--big-john-bold);
    font-size: 2em;
}

.country-block {
    display: flex;
    flex-direction: column;
}

.country__subheader, .country__description {
    color: var(--dark-green-3);
    font-family: var(--lato);
}

.country__subheader {
    font-weight: 600;
    margin-top: 0.5em;
}

.country__description {
    font-weight: 500;
    font-size: 0.7em;
}

.header-malawi {
    background: var(--dark-green);
}
.header-ethiopia {
    background: var(--mustard);
}
.header-kenya {
    background: var(--orange);
}

@keyframes pulse {
  0% { box-shadow:0 0 8px #fff, inset 0 0 8px #fff; }
  50% { box-shadow:0 0 26px #fff, inset 0 0 24px #fff; }
  100% { box-shadow:0 0 8px #fff, inset 0 0 8px #fff; }
}

/* media queries */
@media (max-width: 780px) {

    .parent-container {
        display: flex;
        flex-direction: column;
    }

    .info-section {
        text-align: center;
    }

    .card-section {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .map-section {
        /* display: none; */
    }

    .click-instruction {
        display: none;
    }
}

@media (max-width: 470px) {
    .modal {
        top: 50%;
        left: 50%;
        max-width: 250px;
        font-size: 0.8em;
    }

    .mali {
        top: 29.5%;
        left: 17%;
    }

    .malawi {
        top:63.3%;
        left:69.5%;
    }

    .ethiopia {
        top: 38.2%;
        left:76.8%;
    }

    .kenya {
        top: 48.2%;
        left:75.1%;
    }

    .map-section {
        padding: 1em;
        height: 500px;
        width: 400px;
    }
}
</style>
