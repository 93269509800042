<template>
    <div class="insight-breakdown">
        <div class="insight-title">
            <div
                class="main-section"
                :style="newStyle">
                <div class="insight-number">
                    <span class="word">Insight</span>
                    <span class="number">03</span>
                </div>

                <div
                    class="insight-description"
                    :style="insightDescStyle">
                    The intersectionality of sociocultural factors that determine a
                    person's relative power and privilege, such as power distance,
                    wealth, age, and ethnic discrimination, influence the interaction
                    between pregnant women and professionals providing,
                    maternal health services.
                </div>
            </div>

            <div
                class="context-header"
                :style="subHeaderStyle">
                {{subHeader}}
            </div>

            <div class="country-info">
                <!-- <transition
                    name="component-fade"
                    appear> -->
                <component
                    :is="view"></component>
                <!-- </transition> -->
            </div>

            <div class="country-buttons">
                <button
                    @click="viewMali()"
                    class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-turquoise
                        hover:bg-gray-500
                        focus:outline-none">
                    MALI
                </button>

                <button
                    @click="viewMalawi()"
                    class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-button-green
                        hover:bg-gray-500
                        focus:outline-none">
                    MALAWI
                </button>

                <button
                    @click="viewEthio()"
                    class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-mustard
                        hover:bg-gray-500
                        focus:outline-none">
                    ETHIOPIA
                </button>

                <button
                    @click="viewKen()"
                    class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-orange
                        hover:bg-gray-500
                        focus:outline-none">
                    KENYA
                </button>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            view: '',
            newStyle: '',
            insightDescStyle: '',
            subHeader: 'SELECT COUNTRY TO LEARN MORE ABOUT THIS INSIGHT',
            subHeaderStyle: '',
        };
    },

    components: {
        componentMali: {
            template: '<div class="message-section message-mali">Money influences the way a pregnant woman is treated when seeking ANC and delivery care: better and faster inter-personal care might be granted to those who look wealthier, while those from modest backgrounds might be asked to wait more or seek another midwife. For midwives, bribes, corruption and small gifts unfortunately represent a considerable benefit given their very low and irregular salaries.</div>',
        },
        componentMalawi: {
            template: '<div class="message-section message-malawi">The high-power distance orientation in Malawi increases the potential for discrimination based on wealth or origin of the pregnant woman. There is a common perception among pregnant women that, in order to be seen by a health professional, they need to look worthy and clean. However, many women lack the income to purchase basic self-care items (e.g., a razor) or what they perceive is appropriate clothing (e.g. Chitenge wrap). The reluctance to show up as they are, fearing to face (or provide) a negative experience, prevents them from seeking ANC services and receiving care.</div>',
        },
        componentEthio: {
            template: '<div class="message-section message-ethio">The community associates women who deliver at home as poorer and of lower social status. At the health  facility, the quality of care that these women of lower social status receive is often less than that of those with higher social status. This negative perception also affects Women’s Development Army volunteers(WDAs) who assist women to deliver at home. WDAs acknowledge that their age, wealth, and literacy influence their ability to provide, and be respected for, the support they are appointed to offer to recent mothers in their community.</div>',
        },
        componentKen: {
            template: '<div class="message-section message-ken">The age of a pregnant woman highly determines how she is treated in different touch points at a health facility during the provision of maternal health services. Young pregnant girls are often openly criticized, challenged, or mocked because of behavior (i.e. having sex at an early age), or their lack of preparedness. Older women experience a different type of criticism. They are ill-treated or called out for poor pain management during delivery, especially if they have many children. Likely due to a previous poor experience, pregnant women assume midwives do not have time to answer their questions, even when they do. In general, younger midwives are perceived to be better educated and more approachable and discreet by pregnant women, than older health professionals.</div>',
        },
    },

    methods: {
        viewMali() {
            if (this.view !== 'componentMali') {
                this.view = 'componentMali';
            } else {
                this.view = 'componentMali';
            }

            this.subHeader = 'CONTEXTUAL BACKGROUND';
            this.newStyle = 'display : flex; flex-direction : row;  ';
            this.insightDescStyle = 'text-align: left; width: 70%';
            this.subHeaderStyle = 'text-align: left;';
        },
        viewMalawi() {
            if (this.view !== 'componentMalawi') {
                this.view = 'componentMalawi';
            } else {
                this.view = 'componentMalawi';
            }

            this.subHeader = 'CONTEXTUAL BACKGROUND';
            this.newStyle = 'display : flex; flex-direction : row;  ';
            this.insightDescStyle = 'text-align: left; width: 70%';
            this.subHeaderStyle = 'text-align: left;';
        },
        viewEthio() {
            if (this.view !== 'componentEthio') {
                this.view = 'componentEthio';
            } else {
                this.view = 'componentEthio';
            }

            this.subHeader = 'CONTEXTUAL BACKGROUND';
            this.newStyle = 'display : flex; flex-direction : row;  ';
            this.insightDescStyle = 'text-align: left;  width: 70%';
            this.subHeaderStyle = 'text-align: left;';
        },
        viewKen() {
            if (this.view !== 'componentKen') {
                this.view = 'componentKen';
            }

            this.subHeader = 'CONTEXTUAL BACKGROUND';
            this.newStyle = 'display : flex; flex-direction : row;  ';
            this.insightDescStyle = 'text-align: left; width: 70%';
            this.subHeaderStyle = 'text-align: left;';

            // this.mainSecStyle = 'font-size: 0.7em';
        },
    },
};
</script>

<style lang="css" scoped>

.insight-breakdown {
    display: flex;
    flex-direction: row;
    /* width: 60%; */
    /* margin: 3em auto; */
    /* border: 3px solid green; */
    justify-content: center;
    animation: moveInRight 1s ease-out;
}

.insight-number {
    width: 190px;
    height: 190px;
    background: var(--white);
    color: var(--text-gray);
    margin: 0 auto;
    /* border: 3px solid red; */
    border-radius: 50%; /* remove if you don't care about IE8 */
}

.insight-number {
    font-weight: 700;
    font-family: var(--bebas);
/* } */

/* .insight-number > h2 { */
    display: flex;
    flex-direction: column;
    text-align: center;
}

.word {
    margin-top: 1.5em;
    font-size: 1.7em;
    /* border: 1px solid red; */
}

.number {
    flex: 1;
    margin-top: -30px;
    font-size: 5em;
    /* border: 1px solid red; */

}

.insight-description {
    display: flex;
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    margin-top: 1em;
    font-family: var(--big-john-bold);
    font-size: 1.5em;
    /* padding: 1.5em; */
    font-weight: 600;
    text-align: center;
    /* border: 1px solid red; */
}

.context-header {
    text-align: center;
    letter-spacing: 2px;
    font-family: var(--lato);
    margin: 3em auto;
    font-size: 0.7em;
    font-weight: 700;
    /* margin: 0 auto; */
    width: 80%;
    /* border: 1px solid red; */
}

.country-buttons {
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    align-items: center;
    margin: 0 auto;
    margin-top: 2em;
    /* border: 1px solid red; */
    width: 80%;
    font-size: 1em;
}

button {
    padding: 0.4em auto;
}

.main-section {
    display: flex;
    flex-direction: column;
    /* border: 1px solid blue; */
}

.message-section {
    position: relative;
    padding: 2em;
    border-radius: 7px;
    /* font-size: 1.4em; */
    font-family: var(--lato);
    font-weight: 700;
    width: 80%;
    margin: 0 auto;
    /* border: 1px solid blue; */
}

.message-ken {
    background-color: var(--orange);
    animation: moveInRight 1s ease-out;
}

.message-ken:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid var(--orange);
  border-top: 20px solid var(--orange);
  border-bottom: 20px solid transparent;
  left: 86%;
  bottom: -8%;
}

.message-mali {
    background-color: var(--light-green);
    animation: moveInLeft 1s ease-out;
}

.message-mali:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--light-green);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--light-green);
  border-bottom: 20px solid transparent;
  left: 5%;
  bottom: -12%;
}
.message-malawi {
    background-color: var(--dark-green-2);
    animation: moveInLeft 1s ease-out;
}

.message-malawi:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--dark-green-2);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--dark-green-2);
  border-bottom: 20px solid transparent;
  left: 32%;
  bottom: -10%;
}

.message-ethio {
    background-color: var(--light-mustard);
    animation: moveInRight 1s ease-out;
}

.message-ethio:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid var(--light-mustard);
  border-top: 20px solid var(--light-mustard);
  border-bottom: 20px solid transparent;
  left: 57%;
  bottom: -10%;
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@media (max-width: 500px) {
    .main-section {

    }

    .insight-description {
        font-size: 0.8em;
        padding: 0.5em;
        /* border: 1px solid red; */
        width: 70%;
        margin:0 auto;
    }

    .insight-number {
        margin-top: 1em;
        width: 150px;
        font-size: 0.85em;
        height: 150px;
        /* border: 1px solid red; */
    }

    .country-buttons {
        margin-top: 1.8em;
        font-size: 0.5em;
        /* border: 1px solid red; */
    }

    button {
        padding: 0.5em 0.4em;
    }

    .message-ken:before {
        border-left: 11px solid transparent;
        border-right: 11px solid var(--orange);
        border-top: 11px solid var(--orange);
        border-bottom: 11px solid transparent;
        left: 86%;
        bottom: -6%;
    }

    .message-mali:before {
        border-left: 11px solid var(--light-green);
        border-right: 11px solid transparent;
        border-top: 11px solid var(--light-green);
        border-bottom: 11px solid transparent;
        left: 5%;
        bottom: -6%;
    }

    .message-malawi:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 11px solid var(--dark-green-2);
        border-right: 11px solid transparent;
        border-top: 11px solid var(--dark-green-2);
        border-bottom: 11px solid transparent;
        left: 32%;
        bottom: -6%;
    }

    .message-ethio:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 11px solid transparent;
        border-right: 11px solid var(--light-mustard);
        border-top: 11px solid var(--light-mustard);
        border-bottom: 11px solid transparent;
        left: 57%;
        bottom: -6%;
    }

}
</style>
