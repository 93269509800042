import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/tailwind.css';
import VueGtag from "vue-gtag";

createApp(App).use(VueGtag, {
    config: { 
        id: "G-1QLW3XLNKH",
        params: {
            anonymize_ip: true
        } 
    }
  }).use(store).use(router).mount('#app');
