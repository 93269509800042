<template>
    <footer>
        <div class="section-one">
            For more detailed information about this project, please see the reports on
            <router-link
                        to="/resources">
                        Resources
                    </router-link>
        </div>

        <div class="section-two"></div>
        <span>&copy; <a href="https://www.thinkplaceglobal.com/" target="_blank">ThinkPlace</a> | &copy;  <a href="https://www.jhpiego.org/" target="_blank">Jhpiego</a></span>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
};
</script>

<style lang="css" scoped>
footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    color: white;
    width: 100%;
    background: var(--dark-green);
    font-family: var(--lato);
    font-size: 0.9em;
}
footer a{
    color: #dcc332;
}
.resources-link {
    font-family: var(--big-john-bold);
    color: var(--mustard);
    text-decoration: underline;
    /* animation: pulse 3s linear 1s infinite; */
}

@-webkit-keyframes pulse {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        opacity: 0.5;
    }
}
</style>
