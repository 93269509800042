<template>
    <div class="insight-breakdown">
        <div class="insight-title">
            <div
                class="main-section"
                :style="newStyle">
                <div class="insight-number">
                    <span class="word">Insight</span>
                    <span class="number">05</span>
                </div>

                <div
                    class="insight-description"
                    :style="insightDescStyle">
                    Poor structural and logistic design of maternal health services characterized
                    by abusive social expectations on women workers i.e. midwives, and inefficient
                    and inconsistent allocation of staff and physical space, unequal payment and
                    lack of feedback, perpetuate the negative experience and exhaustion of health
                    practitioners, leading to callous treatment of women.
                </div>
            </div>

            <div
                class="context-header"
                :style="subHeaderStyle">
                {{subHeader}}
            </div>

            <div class="country-info">
                <!-- <transition
                    name="component-fade"
                    appear> -->
                <component
                    :is="view"></component>
                <!-- </transition> -->
            </div>

            <div class="country-buttons">
                <button
                    @click="viewMali()"
                    class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-turquoise
                        hover:bg-gray-500
                        focus:outline-none">
                    MALI
                </button>

                <button
                    @click="viewMalawi()"
                    class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-button-green
                        hover:bg-gray-500
                        focus:outline-none">
                    MALAWI
                </button>

                <button
                    @click="viewEthio()"
                    class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-mustard
                        hover:bg-gray-500
                        focus:outline-none">
                    ETHIOPIA
                </button>

                <button
                    @click="viewKen()"
                    class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-orange
                        hover:bg-gray-500
                        focus:outline-none">
                    KENYA
                </button>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            view: '',
            newStyle: '',
            insightDescStyle: '',
            subHeader: 'SELECT COUNTRY TO LEARN MORE ABOUT THIS INSIGHT',
            subHeaderStyle: '',
        };
    },

    components: {
        componentMali: {
            template: '<div class="message-section message-mali">Health professionals experience daily stress because of the nature of their job: irregular payroll, constant risk of obstetric complications they cannot deal with, and often working without medical history and exams data (fault of whom is usually attributed to pregnant women for not taking exams). Midwives cannot show or share their strong need for emotional, psychological and professional support as they are typically expected to be boundlessly altruistic and devoted to their work. There are no opportunities to outlet their frustrations, and they lack positive supervision and almost never receive constructive feedback, leaving them with poor self-efficacy. Midwives don’t receive training on how to transfer medical information most effectively. They offer generic, complicated, and dense information to pregnant women who are often not able to retain or value it.​ In the midst of their work there are very limited opportunities for positive or constructive interactions between pregnancy women and midwives.</div>',
        },
        componentMalawi: {
            template: '<div class="message-section message-malawi">The allocation of administrative and technical tasks to midwives alone leads to an overwhelming load and limited schedule that limits their capacity to dedicate the time and attention required in 1:1 consultations with pregnant women – services they have expertise and confidence delivering. Because many women delay attendance and tests during their pregnancy, health providers often lack the historical information about the pregnancy and their condition. This can be a frustrating and stressful experience and a hindrance for midwives to provide quality and timely care.</div>',
        },
        componentEthio: {
            template: '<div class="message-section message-ethio">There are gaps in the coordination and handover between Health Extension Workers,Health/Women Development Army volunteers and midwives that create tension among providers and inefficiencies in care (i.e. little information sharing, irregular income, unclear recruitment or roles, unequal updates and training). As a result, women and their families may hold a negative attitude towards Health/Women Development Army volunteers because they perceive the information shared by them to be inconsistent and of no value.</div>',
        },
        componentKen: {
            template: '<div class="message-section message-ken">Community Health Workers are not usually accounted for as part of maternal health service providers. Although they are trained, they own job aids and have positive relationships in the community, they are not formally acknowledged or paid for the work. At health facilities, midwives are found to be overwhelmed with compact appointments and limited infrastructure (e.g. must often carry out consultations while standing, in the presence of several trainees). Younger medical students who are tasked with administrative matters have more opportunities to interact with women and are often preferred to by women and their families. This division of tasks and the poor work environment, instead of being an opportunity or incentive for improved service, actually fosters conflict and competition amongst midwives and limits time for personal connections.</div>',
        },
    },

    methods: {
        viewMali() {
            if (this.view !== 'componentMali') {
                this.view = 'componentMali';
            } else {
                this.view = 'componentMali';
            }

            this.subHeader = 'CONTEXTUAL BACKGROUND';
            this.newStyle = 'display : flex; flex-direction : row;  ';
            this.insightDescStyle = 'text-align: left; width: 70%';
            this.subHeaderStyle = 'text-align: left;';
        },
        viewMalawi() {
            if (this.view !== 'componentMalawi') {
                this.view = 'componentMalawi';
            } else {
                this.view = 'componentMalawi';
            }

            this.subHeader = 'CONTEXTUAL BACKGROUND';
            this.newStyle = 'display : flex; flex-direction : row;  ';
            this.insightDescStyle = 'text-align: left; width: 70%';
            this.subHeaderStyle = 'text-align: left;';
        },
        viewEthio() {
            if (this.view !== 'componentEthio') {
                this.view = 'componentEthio';
            } else {
                this.view = 'componentEthio';
            }

            this.subHeader = 'CONTEXTUAL BACKGROUND';
            this.newStyle = 'display : flex; flex-direction : row;  ';
            this.insightDescStyle = 'text-align: left;  width: 70%';
            this.subHeaderStyle = 'text-align: left;';
        },
        viewKen() {
            if (this.view !== 'componentKen') {
                this.view = 'componentKen';
            }

            this.subHeader = 'CONTEXTUAL BACKGROUND';
            this.newStyle = 'display : flex; flex-direction : row;  ';
            this.insightDescStyle = 'text-align: left; width: 70%';
            this.subHeaderStyle = 'text-align: left;';

            // this.mainSecStyle = 'font-size: 0.7em';
        },
    },
};
</script>

<style lang="css" scoped>

.insight-breakdown {
    display: flex;
    flex-direction: row;
    /* width: 60%; */
    /* margin: 3em auto; */
    /* border: 3px solid green; */
    justify-content: center;
    animation: moveInRight 1s ease-out;
}

.insight-number {
    width: 190px;
    height: 190px;
    background: var(--white);
    color: var(--text-gray);
    margin: 0 auto;
    /* border: 3px solid red; */
    border-radius: 50%; /* remove if you don't care about IE8 */
}

.insight-number {
    font-weight: 700;
    font-family: var(--bebas);
/* } */

/* .insight-number > h2 { */
    display: flex;
    flex-direction: column;
    text-align: center;
}

.word {
    margin-top: 1.5em;
    font-size: 1.7em;
    /* border: 1px solid red; */
}

.number {
    flex: 1;
    margin-top: -30px;
    font-size: 5em;
    /* border: 1px solid red; */

}

.insight-description {
    display: flex;
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    margin-top: 1em;
    font-family: var(--big-john-bold);
    font-size: 1.5em;
    /* padding: 1.5em; */
    font-weight: 600;
    text-align: center;
    /* border: 1px solid red; */
}

.context-header {
    text-align: center;
    letter-spacing: 2px;
    font-family: var(--lato);
    margin: 3em auto;
    font-size: 0.7em;
    font-weight: 700;
    /* margin: 0 auto; */
    width: 80%;
    /* border: 1px solid red; */
}

.country-buttons {
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    align-items: center;
    margin: 0 auto;
    margin-top: 2em;
    /* border: 1px solid red; */
    width: 80%;
    font-size: 1em;
}

button {
    padding: 0.4em auto;
}

.main-section {
    display: flex;
    flex-direction: column;
    /* border: 1px solid blue; */
}

.message-section {
    position: relative;
    padding: 2em;
    border-radius: 7px;
    /* font-size: 1.4em; */
    font-family: var(--lato);
    font-weight: 700;
    width: 80%;
    margin: 0 auto;
    /* border: 1px solid blue; */
}

.message-ken {
    background-color: var(--orange);
    animation: moveInRight 1s ease-out;
}

.message-ken:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid var(--orange);
  border-top: 20px solid var(--orange);
  border-bottom: 20px solid transparent;
  left: 86%;
  bottom: -6%;
}

.message-mali {
    background-color: var(--light-green);
    animation: moveInLeft 1s ease-out;
}

.message-mali:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--light-green);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--light-green);
  border-bottom: 20px solid transparent;
  left: 5%;
  bottom: -5%;
}
.message-malawi {
    background-color: var(--dark-green-2);
    animation: moveInLeft 1s ease-out;
}

.message-malawi:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--dark-green-2);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--dark-green-2);
  border-bottom: 20px solid transparent;
  left: 32%;
  bottom: -10%;
}

.message-ethio {
    background-color: var(--light-mustard);
    animation: moveInRight 1s ease-out;
}

.message-ethio:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid var(--light-mustard);
  border-top: 20px solid var(--light-mustard);
  border-bottom: 20px solid transparent;
  left: 57%;
  bottom: -10%;
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@media (max-width: 500px) {
    .main-section {

    }

    .insight-description {
        font-size: 0.8em;
        padding: 0.5em;
        /* border: 1px solid red; */
        width: 70%;
        margin:0 auto;
    }

    .insight-number {
        margin-top: 1em;
        width: 150px;
        font-size: 0.85em;
        height: 150px;
        /* border: 1px solid red; */
    }

    .country-buttons {
        margin-top: 1.8em;
        font-size: 0.5em;
        /* border: 1px solid red; */
    }

    button {
        padding: 0.5em 0.4em;
    }

    .message-ken:before {
        border-left: 11px solid transparent;
        border-right: 11px solid var(--orange);
        border-top: 11px solid var(--orange);
        border-bottom: 11px solid transparent;
        left: 86%;
        bottom: -6%;
    }

    .message-mali:before {
        border-left: 11px solid var(--light-green);
        border-right: 11px solid transparent;
        border-top: 11px solid var(--light-green);
        border-bottom: 11px solid transparent;
        left: 5%;
        bottom: -6%;
    }

    .message-malawi:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 11px solid var(--dark-green-2);
        border-right: 11px solid transparent;
        border-top: 11px solid var(--dark-green-2);
        border-bottom: 11px solid transparent;
        left: 32%;
        bottom: -6%;
    }

    .message-ethio:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 11px solid transparent;
        border-right: 11px solid var(--light-mustard);
        border-top: 11px solid var(--light-mustard);
        border-bottom: 11px solid transparent;
        left: 57%;
        bottom: -6%;
    }

}
</style>
