<template>
    <div class="container">

        <div class="flip-card">
            <h3>MY NEWSPAPER ARTICLE</h3>
            <div class="flip-card-inner">
                <div class="flip-card-front card1">
                    <img
                        src="../../../assets/images/home/toolkit/my-newspaper-article.svg"
                        alt="Avatar">
                </div>
                <div class="flip-card-back back1">
                    <!-- <h1>Toolkit Title</h1> -->
                    <p>Health professionals and members of
                        ASACOs describe how they would
                        like to be presented if they were
                        interviewed in the national newspaper.</p>

                </div>
            </div>
        </div>

        <div class="flip-card">
            <h3>MY IDEAL EXPERIENCE</h3>
            <div class="flip-card-inner">
                <div class="flip-card-front ">
                    <img
                        src="../../../assets/images/home/toolkit/my_ideal_experience.svg"
                        alt="Avatar">
                </div>
                <div class="flip-card-back back2">
                    <!-- <h1>Toolkit Title</h1> -->
                    <p>An activity in which
                        participants are asked to
                        describe their ideal pregnancy
                        and childbirth, using
                        questions and visual aids.</p>

                </div>
            </div>
        </div>

        <div class="flip-card">
            <h3>GOOD ADVICE CORNER</h3>
            <div class="flip-card-inner">
                <div class="flip-card-front card3">
                    <img
                        src="../../../assets/images/home/toolkit/good-advice-corner.svg"
                        alt="Avatar">
                </div>
                <div class="flip-card-back back3">
                    <!-- <h1>Toolkit Title</h1> -->
                    <p>Participants are presented with scenarios
                        related to MCH services (user side and
                        health worker side) in which the
                        character faces a challenge and asks the
                        participants for advice.</p>

                </div>
            </div>
        </div>

        <div class="flip-card">
            <h3>FOLLOWING MY EMOTIONS</h3>
            <div class="flip-card-inner">
                <div class="flip-card-front card1">
                    <img
                        src="../../../assets/images/home/toolkit/following-my-emotions.svg"
                        alt="Avatar">
                </div>
                <div class="flip-card-back back4">
                    <!-- <h1>Toolkit Title</h1> -->
                    <p>Participants are given simple images
                        representing several emotional states.
                        Participants are invited to choose the
                        image that best corresponds to their
                        emotional state at the different stages
                        of the maternal healthcare journey.</p>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
};
</script>

<style lang="css" scoped>
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  /* border: 1px solid blue; */
  /* height: auto; */
  padding: 2em;
  animation: moveInBottom 1s ease-out;
}

.card1 {
  /* background: url('../../../assets/images/home/toolkit/my-newspaper-article.svg'); */
  background-size: 50% 50%;
  background-repeat: no-repeat;
}

.card2 {
  background: url('../../../assets/images/home/tool_2.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.card3 {
  /* background: url('../../../assets/images/home/tool_3.png'); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.flip-card {
  background-color: transparent;
  width: 250px;
  height: 350px;
  /* perspective: 1000px; */
  margin: 2em 2px;
  border-radius: 10px;
}

.flip-card > h3 {
  color: var(--toolkit-orange);
  font-family: var(--big-john-bold);
  font-size: 1em;
  text-align: center;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-bottom: 1em;
  /* color: black; */
}

.flip-card-front > img {
  height: 100px;
}

.flip-card-back {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: var(--dark-blue);
  padding: 1em;
  transform: rotateY(180deg);
}

.back1 {
    /* background: var(--light-green); */
}

.back2 {
    /* background: var(--orange); */
}

.back3 {
    /* background: var(--mustard); */
}

.back4 {
    /* background: var(--dark-blue); */
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }
</style>
