<template>
    <!-- eslint-disable max-len -->
    <!-- eslint-disable vue/max-attributes-per-line -->
    <div class="content"
         :style="[currentSlide == 'Insight1' ? { backgroundImage: `linear-gradient( rgba(23, 26, 27, 0.6), rgba(14, 15, 15, 0.65)), linear-gradient( rgba(23, 26, 27, 0.6), rgba(14, 15, 15, 0.65)), url(${require('@/assets/images/home/insight_images/' + imgUrl.image1)})`} : currentSlide == 'Insight2' ? { backgroundImage: `linear-gradient( rgba(5, 5, 5, 0.6), rgba(11, 12, 12, 0.65)), url(${require('@/assets/images/home/insight_images/' + imgUrl.image2)})`} : currentSlide == 'Insight3' ? { backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.6), rgba(11, 12, 12, 0.65)), url(${require('@/assets/images/home/insight_images/' + imgUrl.image3)})`} : currentSlide == 'Insight4' ? { backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.6), rgba(11, 12, 12, 0.65)), url(${require('@/assets/images/home/insight_images/' + imgUrl.image4)})`} : currentSlide == 'Insight5' ? { backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.6), rgba(11, 12, 12, 0.65)), url(${require('@/assets/images/home/insight_images/' + imgUrl.image5)})`} : null]">

        <div class="back-section">
            <button
                onclick='history.back()'
                class="back__button
                text-white-500
                hover:text-red-500xx
                rounded inline-flex items-center">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#ffffff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="M19 12H6M12 5l-7 7 7 7"/>
                </svg>
                <span> &nbsp;Back</span>
            </button>
        </div>

        <!-- carousel insights section-->
        <div class="desktop-insights">
            <div class="carousel-section">
                <div class="prev-button">
                    <button
                        @click="prevButton()"
                        class="inline-flex
                            items-center
                            justify-center
                            w-12 h-12
                            text-gray-500
                            transition-colors
                            duration-150
                            font-bold
                            bg-white rounded-full
                            focus:shadow-outline
                            hover:bg-custom-mustard">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-10 w-10"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M15 19l-7-7 7-7" />
                        </svg>
                    </button>
                </div>

                <div class="components-container">
                    <component
                        :is="currentSlide">
                    </component>
                </div>

                <div class="next-button">
                    <button
                        @click="nextButton()"
                        class="inline-flex
                            items-center
                            justify-center
                            w-12 h-12
                            text-gray-500
                            transition-colors
                            duration-150
                            font-bold
                            bg-white rounded-full
                            focus:shadow-outline
                            hover:bg-custom-mustard">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-10 w-10"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M9 5l7 7-7 7" />
                        </svg>
                    </button>
                </div>
            </div>

            <div class="carousel-nav">
                <div
                    class="insight-navigation"
                    @click="currentSlide = 'Insight1'"
                    :style="[currentSlide == 'Insight1' ? {'--text-color' : `${backColor}`, '--back-color' : `${textColor}`} : null]">
                    1
                </div>
                <div
                    class="insight-navigation"
                    @click="currentSlide = 'Insight2'"
                    :style="[currentSlide == 'Insight2' ? {'--text-color' : `${backColor}`, '--back-color' : `${textColor}`} : null]">
                    2
                </div>
                <div
                    class="insight-navigation"
                    @click="currentSlide = 'Insight3'"
                    :style="[currentSlide == 'Insight3' ? {'--text-color' : `${backColor}`, '--back-color' : `${textColor}`} : null]">
                    3
                </div>
                <div
                    class="insight-navigation"
                    @click="currentSlide = 'Insight4'"
                    :style="[currentSlide == 'Insight4' ? {'--text-color' : `${backColor}`, '--back-color' : `${textColor}`} : null]">
                    4
                </div>
                <div
                    class="insight-navigation"
                    @click="currentSlide = 'Insight5'"
                    :style="[currentSlide == 'Insight5' ? {'--text-color' : `${backColor}`, '--back-color' : `${textColor}`} : null]">
                    5
                </div>
            </div>
        </div>

        <!-- mobile insights section-->
        <div class="mobile-insights">
            <mobile-insights/>
        </div>
    </div>
</template>

<script>
import Insight1 from '@/components/Insights/InsightSlides/Insight1.vue';
import Insight2 from '@/components/Insights/InsightSlides/Insight2.vue';
import Insight3 from '@/components/Insights/InsightSlides/Insight3.vue';
import Insight4 from '@/components/Insights/InsightSlides/Insight4.vue';
import Insight5 from '@/components/Insights/InsightSlides/Insight5.vue';

// mobile components
import MobileInsights from '@/components/Insights/MobileInsights/MobileInsights.vue';

export default {
    name: 'InsightBreakdown',
    data() {
        return {
            index: 0,
            currentSlide: 'Insight1',
            textColor: '#DCC332',
            backColor: '#026480',
            imgUrl: {
                image1: 'IMG_2793.png',
                image2: 'ethiopia1_insight_photo.jpg',
                image3: 'ethiopia2_insight_photo.jpg',
                image4: 'malawi_insights_photo.jpg',
                image5: 'mali_insights_photo.jpg',
            },
            slides: [
                'Insight1',
                'Insight2',
                'Insight3',
                'Insight4',
                'Insight5',
            ],
        };
    },

    components: {
        Insight1,
        Insight2,
        Insight3,
        Insight4,
        Insight5,
        MobileInsights,
    },

    methods: {
        mod(n, m) {
            return ((n % m) + m) % m;
        },

        nextButton() {
            this.index = this.mod(this.index + 1, this.slides.length);
            this.currentSlide = this.slides[this.index];
        },

        prevButton() {
            this.index = this.mod(this.index - 1, this.slides.length);
            this.currentSlide = this.slides[this.index];
        },
    },

};
</script>

<style lang="css" scoped>
.content {
    display: flex;
    flex-direction: column;
    min-height: 90vh;
    /* background-image:
    linear-gradient( rgba(23, 26, 27, 0.6), rgba(14, 15, 15, 0.65)),
    url('../../assets/images/home/IMG_2793.png');
    background-size: 100% 100%; */
    background-size: cover;
}

.back-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 1em;
    width: 80%;
    margin: 0 auto;
    border-bottom: 4px solid var(--dark-green);
}

.carousel-section {
    display:flex;
    justify-content: center;
    margin: 1em 0 1.5em 0;
    /* border: 2px solid blue; */
}

.mobile-insights {
    padding: 0.5em;
    }

.desktop-insights {
    display: block;
    }

.mobile-insights {
    display: none;
    }

.components-container {
    max-width: 60%;
    /* border: 1px solid goldenrod; */
}

.prev-button,
.next-button {
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 2em;
}

.carousel-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1em;
}

.insight-navigation {
    --text-color: #DCC332;
    --back-color: #026480;
    background: var(--back-color);
    color: var(--text-color);
    text-align: center;
    font-size: 12px;
    font-family: var(--big-john-bold);
    padding: 0 auto;
    margin: 3px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid var(--text-color);
}

.insight-navigation:hover {
    color: var(--back-color);
    background: var(--text-color);
}

@media (max-width: 910px) {
    .desktop-insights {
        display: none;
    }

    .mobile-insights {
        display: block;
    }
}

@media (max-width: 500px) {
    .components-container {
        max-width: 70%;
    }

    .prev-button,
    .next-button {
        display:flex;
        justify-content: center;
        align-items: center;
        padding: 0em;
        }
}
</style>
