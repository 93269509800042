<template>
    <div class="insight-content">
        <div
            :class="currentClass"
            :style="countryColor">
            {{countryMessage}}
        </div>

        <div class="country-controls">
            <button
                @click="mali()"
                class="country-button button-mali">
                MALI
            </button>

            <button
                @click="malawi()"
                class="country-button button-malawi">
                MALAWI
            </button>

            <button
                @click="ethiopia()"
                class="country-button button-ethiopia">
                ETHIOPIA
            </button>

            <button
                @click="kenya()"
                class="country-button button-kenya">
                KENYA
            </button>
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            countryColor: '--gradient: #29CAD3;',
            currentClass: 'country-message country-message-mali',
            countryMessage: 'Health professionals experience daily stress because of the nature of their job: irregular payroll, constant risk of obstetric complications they cannot deal with, and often working without medical history and exams data (fault of whom is usually attributed to pregnant women for not taking exams). Midwives cannot show or share their strong need for emotional, psychological and professional support as they are typically expected to be boundlessly altruistic and devoted to their work. There are no opportunities to outlet their frustrations, and they lack positive supervision and almost never receive constructive feedback, leaving them with poor self-efficacy. Midwives don’t receive training on how to transfer medical information most effectively. They offer generic, complicated, and dense information to pregnant women who are often not able to retain or value it.​ In the midst of their work there are very limited opportunities for positive or constructive interactions between pregnancy women and midwives.',

        };
    },
    methods: {
        mali() {
            this.countryColor = '--gradient: #29CAD3;';
            this.currentClass = 'country-message country-message-mali';
            this.countryMessage = 'Health professionals experience daily stress because of the nature of their job: irregular payroll, constant risk of obstetric complications they cannot deal with, and often working without medical history and exams data (fault of whom is usually attributed to pregnant women for not taking exams). Midwives cannot show or share their strong need for emotional, psychological and professional support as they are typically expected to be boundlessly altruistic and devoted to their work. There are no opportunities to outlet their frustrations, and they lack positive supervision and almost never receive constructive feedback, leaving them with poor self-efficacy. Midwives don’t receive training on how to transfer medical information most effectively. They offer generic, complicated, and dense information to pregnant women who are often not able to retain or value it. In the midst of their work there are very limited opportunities for positive or constructive interactions between pregnancy women and midwives.';
        },

        malawi() {
            this.countryColor = '--gradient: #00475C;';
            this.currentClass = 'country-message country-message-malawi';
            this.countryMessage = 'The allocation of administrative and technical tasks to midwives alone leads to an overwhelming load and limited schedule that limits their capacity to dedicate the time and attention required in 1:1 consultations with pregnant women – services they have expertise and confidence delivering. Because many women delay attendance and tests during their pregnancy, health providers often lack the historical information about the pregnancy and their condition. This can be a frustrating and stressful experience and a hindrance for midwives to provide quality and timely care.';
        },

        ethiopia() {
            this.countryColor = '--gradient: #DCC332;';
            this.currentClass = 'country-message country-message-ethiopia';
            this.countryMessage = 'There are gaps in the coordination and handover between Health Extension Workers,Health/Women Development Army volunteers and midwives that create tension among providers and inefficiencies in care (i.e. little information sharing, irregular income, unclear recruitment or roles, unequal updates and training). As a result, women and their families may hold a negative attitude towards Health/Women Development Army volunteers because they perceive the information shared by them to be inconsistent and of no value.';
        },

        kenya() {
            this.countryColor = '--gradient: #ED6E53;';
            this.currentClass = 'country-message country-message-ken';
            this.countryMessage = 'Community Health Workers are not usually accounted for as part of maternal health service providers. Although they are trained, they own job aids and have positive relationships in the community, they are not formally acknowledged or paid for the work. At health facilities, midwives are found to be overwhelmed with compact appointments and limited infrastructure (e.g. must often carry out consultations while standing, in the presence of several trainees). Younger medical students who are tasked with administrative matters have more opportunities to interact with women and are often preferred to by women and their families. This division of tasks and the poor work environment, instead of being an opportunity or incentive for improved service, actually fosters conflict and competition amongst midwives and limits time for personal connections.';
        },
    },

};
</script>

<style lang="css" scoped>
.country-message {
    background: var(--gradient);
    color: var(--white);
}

.country-message {
    position: relative;
    padding: 1em;
    border-radius: 7px;
    font-family: var(--lato);
    font-weight: 600;
    font-size: 0.8em;
    width: 90%;
    margin: 0 auto;
}

/* .country-message-mali:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--gradient);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 5%;
  bottom: -17%;
}

.country-message-malawi:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--gradient);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 32%;
  bottom: -10%;
}

.country-message-ethiopia:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid var(--gradient);
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 57%;
  bottom: -10%;
}

.country-message-ken:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid var(--gradient);
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 80%;
  bottom: -10%;
} */

.country-controls {
    display: flex;
    justify-content: space-between;
    padding: 1em;
}

.country-button {
    flex: 1;
    color: var(--white);
    font-size: 0.8em;
    font-weight: 600;
    padding: 0.5em;
    border-radius: 7px;
    margin: 2px;
    opacity: 1;
}

.button-mali {
    background: var(--teal);
}

.button-malawi {
    background: var(--dark-green);
}

.button-ethiopia {
    background: var(--dark-mustard);
}

.button-kenya {
    background: var(--orange);
}
</style>
