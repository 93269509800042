<template>
    <div class="insight-content">
        <div
            :class="currentClass"
            :style="countryColor">
            {{countryMessage}}
        </div>

        <div class="country-controls">
            <button
                @click="mali()"
                class="country-button button-mali">
                MALI
            </button>

            <button
                @click="malawi()"
                class="country-button button-malawi">
                MALAWI
            </button>

            <button
                @click="ethiopia()"
                class="country-button button-ethiopia">
                ETHIOPIA
            </button>

            <button
                @click="kenya()"
                class="country-button button-kenya">
                KENYA
            </button>
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            countryColor: '--gradient: #29CAD3;',
            currentClass: 'country-message country-message-mali',
            countryMessage: 'Partners have many roles to play during pregnancy: from financing to gathering information to “policing” a woman’s pregnancy journey. Yet, few fathers feel the need or the desire to visit the health facility at any point of the pregnancy, as neither they nor their partners see such facilities as appropriate place for men. Partners thus rarely receive quality health information in a way that directly speaks to their fears (financial, emotional, social) and perceived needs (e.g. planning, working, saving, supporting their family). Ultimately, it is women who visit the health facility and receive recommendations or requests from health providers that are responsible to inform, justify and persuade possibly ill-informed decision-making gate keepers.',

        };
    },
    methods: {
        mali() {
            this.countryColor = '--gradient: #29CAD3;';
            this.currentClass = 'country-message country-message-mali';
            this.countryMessage = 'Partners have many roles to play during pregnancy: from financing to gathering information to “policing” a woman’s pregnancy journey. Yet, few fathers feel the need or the desire to visit the health facility at any point of the pregnancy, as neither they nor their partners see such facilities as appropriate place for men. Partners thus rarely receive quality health information in a way that directly speaks to their fears (financial, emotional, social) and perceived needs (e.g. planning, working, saving, supporting their family). Ultimately, it is women who visit the health facility and receive recommendations or requests from health providers that are responsible to inform, justify and persuade possibly ill-informed decision-making gate keepers.';
        },

        malawi() {
            this.countryColor = '--gradient: #00475C;';
            this.currentClass = 'country-message country-message-malawi';
            this.countryMessage = 'Men’s participation in ANC is hampered by a service that is at odds with the community’s definition of masculinity. Men want to be involved in the pregnancy journey. However, the interaction points at the health facility conflict with their social and cultural roles. Men want a service that affirms their masculinity, characterized by being shorter, faster, more discreet, and separate from women-led activities. In Malawi, the attendance of husbands to the first ANC appointment is de facto mandatory. Health facilities and communities actively incentivize compliant men by giving them first place in line, but penalize others by strategies like charging fines or requiring written justification from chief elders for the absence of men in ANC.';
        },

        ethiopia() {
            this.countryColor = '--gradient: #DCC332;';
            this.currentClass = 'country-message country-message-ethiopia';
            this.countryMessage = 'The men who live within the household, are in general the first to be consulted if a pregnant woman has a question. In case a man does not know the answer to a question or the solution to a problem, he is responsible to look for support. This is likely to happen at first within his trusted social network, e.g., direct family, religious leaders or healers, and second from the formal health system. In case of a complication, however, husbands become strong advocates for formal healthcare support. Despite their sense of responsibility, men or their direct family are rarely engaged in the information exchange between health providers and women who just delivered at a health facility.';
        },

        kenya() {
            this.countryColor = '--gradient: #ED6E53;';
            this.currentClass = 'country-message country-message-ken';
            this.countryMessage = 'Male involvement is not perceived by women in the community as necessary or desirable. Men arenot expected to be available for such involvement either. When men are keen to be involved in appointment or the delivery, they are often met with resistance and pushed away, e.g., actively advised against holding a newborn. A pregnant woman is more likely to count on female elders from the family or from her community to accompany her to the health facility. This matters because many decisions about a pregnant woman’s life are often made by her family members and not herself.';
        },
    },

};
</script>

<style lang="css" scoped>
.country-message {
    background: var(--gradient);
    color: var(--white);
}

.country-message {
    position: relative;
    padding: 1em;
    border-radius: 7px;
    font-family: var(--lato);
    font-weight: 600;
    font-size: 0.8em;
    width: 90%;
    margin: 0 auto;
}

/* .country-message-mali:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--gradient);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 5%;
  bottom: -17%;
}

.country-message-malawi:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--gradient);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 32%;
  bottom: -10%;
}

.country-message-ethiopia:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid var(--gradient);
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 57%;
  bottom: -10%;
}

.country-message-ken:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid var(--gradient);
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 80%;
  bottom: -10%;
} */

.country-controls {
    display: flex;
    justify-content: space-between;
    padding: 1em;
}

.country-button {
    flex: 1;
    color: var(--white);
    font-size: 0.8em;
    font-weight: 600;
    padding: 0.5em;
    border-radius: 7px;
    margin: 2px;
    opacity: 1;
}

.button-mali {
    background: var(--teal);
}

.button-malawi {
    background: var(--dark-green);
}

.button-ethiopia {
    background: var(--dark-mustard);
}

.button-kenya {
    background: var(--orange);
}
</style>
