<template>
    <div class="content">
        <div class="section-left">
            <h2 class="sub-title">
                <span>THE </span>
                <span class="sub-title-yellow">
                    CHALLENGE
                </span>
            </h2>

            <div class="description description-left">
                Early entry, continuity, and quality of antenatal and postnatal care all remain priorities for health
systems and maternal and newborn health. As part of a Bill & Melinda Gates Foundation funded
in Antenatal and Postnatal Care Research Collective (ARC), Jhpiego and ThinkPlace worked to
generate behavioral insights and prototypes to design and improve ANC and PNC services in
Ethiopia, Kenya, Malawi, and Mali. This platform consolidates and shares early learnings from
the behavioral design process. 

            </div>
        </div>

        <div class="section-right">
            <h2 class="sub-title">
                <span>
                    THE
                    <span class="sub-title-blue">
                        HUMANS
                    </span>
                </span>
                <span>WE
                    <span class="sub-title-blue">
                        DESIGNED WITH
                    </span>
                </span>
            </h2>

            <div class="humans-info">
                <div class="description description-right">
                    To uncover deep insights, ThinkPlace engaged with pregnant
                    women in urban and rural settings, with young and old,
                    first-time and experienced mothers, and a large diversity
                    of maternal health care providers (i.e. nurses, midwives,
                    doctors and community health workers) and community members:
                    sisters, parents, mothers-in-law, partners and neighbors.
                </div>

                <div class="info-image">
                    <img
                        src="../../assets/images/home/women_group.svg"
                        class="women-group"
                        alt="women">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChallengeSection',
};
</script>

<style lang="css" scoped>
.content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* border: 1px solid goldenrod; */
}

.section-right, .section-left {
    flex: 1;
    /* margin: 0 auto; */
    /* padding: 1.5em; */
    /* border: 1px solid goldenrod; */
}

.section-left {
    background-image: linear-gradient( rgba(23, 26, 27, 0.6), rgba(14, 15, 15, 0.65)),
    url('../../assets/images/home/IMG_2793.png');
    background-size: cover;
    /* height: 70vh; */
}

.section-right {
    background-color: var(--orange);
    /* border: 2px solid greenyellow; */
}

.sub-title {
    display: flex;
    flex-direction: column;
    font-family: var(--big-john-bold);
    font-size: 2em;
    font-weight: 700;
    margin: 1em 2em;
}

.sub-title-yellow {
    color: var(--mustard);
}

.sub-title-blue {
    color: var(--dark-blue);
}

.description {
    font-size: 0.8em;
    width: 345px;
    margin-left: 5em;
    padding: 1em;
    margin-bottom: 1em;
    /* border: 1px solid goldenrod; */
}

.description-left {
    width: 39%;
}

.humans-info {
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
    /* border: 1px solid goldenrod; */
    /* margin-top: 57em; */
    /* height: 56%; */
}

.info-image {
    /* flex: 2; */
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.women-group {
    width: 300px;
}

@media (max-width: 990px) {
    .content {
        flex-direction: column;
    }

    .description-right {
        width: 50%;
    }
}

@media (max-width: 560px) {
    .sub-title {
        margin: 1em 2em;
    }

    .description {
        width: 80%;
    }

    .info-image {
        display: none;
    }
}
</style>
