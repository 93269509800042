<template>
    <!-- eslint-disable max-len  -->
    <div class="main-container">
        <div class="tab-section">
            <button
                @click="insightOne()"
                class="tab-btn"
                :style="[activeTab == 'insightOne' ? {'--text-col' : '#00475C', '--back-col' : '#fff'} : null]">

                <span class="small-title">Insight 1</span>
            </button>
            <button
                @click="insightTwo()"
                class="tab-btn"
                :style="[activeTab == 'insightTwo' ? {'--text-col' : '#00475C', '--back-col' : '#fff' } : {'--text-col' : '#D7BB2C', '--back-col' : '#00475C'}]">

                <span class="small-title">Insight 2</span>
            </button>
            <button
                @click="insightThree()"
                class="tab-btn"
                :style="[activeTab == 'insightThree' ? {'--text-col' : '#00475C', '--back-col' : '#fff'} : null]">

                <span class="small-title">Insight 3</span>
            </button>
            <button
                @click="insightFour()"
                class="tab-btn"
                :style="[activeTab == 'insightFour' ? {'--text-col' : '#00475C', '--back-col' : '#fff'} : null]">

                <span class="small-title">Insight 4</span>
            </button>
            <button
                @click="insightFive()"
                class="tab-btn"
                :style="[activeTab == 'insightFive' ? {'--text-col' : '#00475C', '--back-col' : '#fff'} : null]">

                <span class="small-title">Insight 5</span>
            </button>
        </div>

        <div class="title-section">
            <span class="insight-heading">{{insightTitle}}</span>
            <span class="insight-description">
                {{insightDescription}}
            </span>
            <span class="sub-heading">
                CONTEXTUAL BACKGROUND:
            </span>
        </div>

        <div class="message-section">
            <div class="insight">
                <component :is="selectedComponent"></component>
            </div>

        </div>

    </div>
</template>

<script>
import InsightMessages1 from '@/components/Insights/MobileInsights/InsightMessages/InsightMesage1.vue';
import InsightMessages2 from '@/components/Insights/MobileInsights/InsightMessages/InsightMesage2.vue';
import InsightMessages3 from '@/components/Insights/MobileInsights/InsightMessages/InsightMesage3.vue';
import InsightMessages4 from '@/components/Insights/MobileInsights/InsightMessages/InsightMesage4.vue';
import InsightMessages5 from '@/components/Insights/MobileInsights/InsightMessages/InsightMesage5.vue';

export default {
    name: 'PregnancyBreakdown',
    components: {
        InsightMessages1,
        InsightMessages2,
        InsightMessages3,
        InsightMessages4,
        InsightMessages5,
    },

    data() {
        return {
            showModal: false,
            selectedComponent: 'InsightMessages1',
            insightTitle: 'Insight 01',
            insightDescription: 'Pregnancy is a socially dictated performance which often disguises the reality that a woman experiences in different stages of her pregnancy journey. These societal expectations prevent her from receiving the most adequate and desired support.',
            activeTab: 'insightOne',

        };
    },

    methods: {
        async insightOne() {
            this.activeTab = 'insightOne';
            this.selectedComponent = 'InsightMessages1';

            this.insightTitle = 'Insight 01';
            this.insightDescription = 'Pregnancy is a socially dictated performance which often disguises the reality that a woman experiences in different stages of her pregnancy journey. These societal expectations prevent her from receiving the most adequate and desired support.';
        },

        async insightTwo() {
            this.activeTab = 'insightTwo';
            this.selectedComponent = 'InsightMessages2';

            this.insightTitle = 'Insight 02';
            this.insightDescription = 'Male partners and trusted family members of pregnant women, whom they must rely on to make decisions, are usually excluded of maternal health sevices. Services are directed at women without a deliberate effort to either, increase their self-determination in relation to their health, or to embrace others’ responsibilities and views. This exacerbates the solitude that a mother experiences in the facility-based pregnancy and postpartum journeys.';
        },

        async insightThree() {
            this.activeTab = 'insightThree';
            this.selectedComponent = 'InsightMessages3';

            this.insightTitle = 'Insight 03';
            this.insightDescription = 'The intersectionality of sociocultural factors that determine a person\'s relative power and privilege, such as power distance, wealth, age, and ethnic discrimination, influence the interaction between pregnant women and professionals providing, maternal health services.';
        },

        async insightFour() {
            this.activeTab = 'insightFour';
            this.selectedComponent = 'InsightMessages4';

            this.insightTitle = 'Insight 04';
            this.insightDescription = 'The unpredictability and the lack of differentiation of maternal health services to women in different circumstances, fosters negative preconceptions and low expectations about the value these services, and the social interactions they imply, in regard to the time and money women invest.';
        },

        async insightFive() {
            this.activeTab = 'insightFive';
            this.selectedComponent = 'InsightMessages5';

            this.insightTitle = 'Insight 05';
            this.insightDescription = 'Poor structural and logistic design of maternal health services characterized by abusive social expectations on women workers i.e. midwives, and inefficient and inconsistent allocation of staff and physical space, unequal payment and lack of feedback, perpetuate the negative experience and exhaustion of health practitioners, leading to callous treatment of women.';
        },
    },
};
</script>

<style lang="css" scoped>
.main-container {
    background: transparent;
    color: var(--text-gray);
    height: auto;
    animation: fadein 2s;
}

.tab-section {
    display: flex;
    justify-content: center;
    font-size: 0.8em;
    background: rgba(255, 255, 255, 0.1);
}

.tab-btn {
    --text-col: #D7BB2C;
    --back-col: #00475C;
    color: var(--text-col);
    background-color: var(--back-col);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: 0 1px;
    padding: 0.5em;
}

.title-section {
    display: flex;
    flex-direction: column;
    padding: 1em;
    background: var(--white);
}

.insight-heading {
    /* color: var(--text-gray); */
    color: var(--mustard);
    font-family: var(--big-john-bold);
    font-weight: 700;
    font-size: 2em;
}

.insight-description {
    color: #535353;
    font-weight: 800;
    font-size: 0.8em;
}

.sub-heading {
    color: var(--dark-green-3);
    font-size: 0.6em;
    font-weight: 600;
    word-spacing: 3px;
    margin-top: 1em;
}

.message-section {
    display: flex;
    flex-direction: column;
    background: var(--white);
}

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
</style>
