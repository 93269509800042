<template>
    <!-- eslint-disable max-len  -->
    <div class="content">
        <div class="back-section">
            <button
                onclick='history.back()'
                class="back__button
                text-white-500
                hover:text-red-500xx
                rounded inline-flex items-center">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#ffffff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="M19 12H6M12 5l-7 7 7 7"/>
                </svg>
                <span> &nbsp;Back</span>
            </button>
        </div>

        <div class="breakdown-section">
            <div class="globe-section">

                <div class="path-section">

                    <div class="mothers-section">

                        <!-- numbers  -->
                        <div
                            @click="phaseOne()"
                            class="number one">
                            <img
                                src="../../assets/images/pregnancy/0.svg"
                                alt="0">
                        </div>

                        <div
                            @click="phaseTwo()"
                            class="number two">
                            <img
                                src="../../assets/images/pregnancy/1.svg"
                                alt="1">
                        </div>

                        <div
                            @click="phaseThree()"
                            class="number three">
                            <img
                                src="../../assets/images/pregnancy/2.svg"
                                alt="2">
                        </div>

                        <div
                            @click="phaseFour()"
                            class="number four">
                            <img
                                src="../../assets/images/pregnancy/3.svg"
                                alt="3">
                        </div>

                        <div
                            @click="phaseFive()"
                            class="number five">
                            <img
                                src="../../assets/images/pregnancy/4.svg"
                                alt="4">
                        </div>

                        <div
                            @click="phaseSix()"
                            class="number six">
                            <img
                                src="../../assets/images/pregnancy/5.svg"
                                alt="5">
                        </div>

                        <div
                            @click="phaseSeven()"
                            class="number seven">
                            <img
                                src="../../assets/images/pregnancy/6.svg"
                                alt="6">
                        </div>

                        <!-- mothers -->

                        <div class="stage woman-one">
                            <img
                                src="../../assets/images/pregnancy/woman-stage-1.svg"
                                alt="">
                        </div>

                        <div class="stage woman-two">
                            <img
                                src="../../assets/images/pregnancy/woman-stage-2.svg"
                                alt="">
                        </div>

                        <div class="stage woman-three">
                            <img
                                src="../../assets/images/pregnancy/woman-stage-3.svg"
                                alt="">
                        </div>

                        <div class="stage woman-four">
                            <img
                                src="../../assets/images/pregnancy/woman-stage-4.svg"
                                alt="">
                        </div>

                        <div class="stage woman-five">
                            <img
                                src="../../assets/images/pregnancy/woman-stage-5.svg"
                                alt="">
                        </div>

                        <div class="stage woman-six">
                            <img
                                src="../../assets/images/pregnancy/woman-stage-6.svg"
                                alt="">
                        </div>

                        <div class="stage woman-seven">
                            <img
                                src="../../assets/images/pregnancy/woman-stage-7.svg"
                                alt="">
                        </div>

                        <div class="cloud cloud-one">
                            <img
                                src="../../assets/images/pregnancy/cloud_1.png"
                                alt="">
                        </div>

                        <div class="cloud cloud-two">
                            <img
                                src="../../assets/images/pregnancy/cloud_2.png"
                                alt="">
                        </div>

                        <!-- Tooltips -->
                        <div class="tooltip tip-one">
                            DISCOVERY
                        </div>

                        <div class="tooltip tip-two">
                            TRIMESTER 1
                        </div>

                        <div class="tooltip tip-three">
                            TRIMESTER 2
                        </div>

                        <div class="tooltip tip-four">
                            TRIMESTER 3
                        </div>

                        <div class="tooltip tip-five">NEWBORN</div>

                        <div class="tooltip tip-six">BABY</div>

                        <div class="tooltip tip-seven">IMMUNISATION</div>
                    </div>

                </div>

            </div>
        </div>

        <div class="text__description">
            <span>
                CLICK ON THE STEP OF THE JOURNEY THAT YOU WANT TO EXPLORE
            </span>
            <span>OR</span>
        </div>

        <div class="button__area">
            <button
                @click="phaseOne()"
                class="
                    font-bold
                    rounded
                    px-3
                    py-2
                    m-1
                    text-white
                    bg-custom-blue
                    hover:bg-custom-turquoise
                    transition duration-500 ease-in-out
                    transform hover:-translate-y-1 hover:scale-110
                    focus:outline-none">
                START THE JOURNEY
            </button>
        </div>

        <!-- modal -->
        <transition
            name="fade"
            appear>
            <div
                class="modal-overlay"
                v-if="showModal"
                @click="showModal = false">
            </div>
        </transition>
        <transition
            name="slide"
            appear>
            <div
                class="modal"
                v-if="showModal">

                <div class="modal-content">
                    <div class="close-btn">
                        <button
                            @click="showModal = false"
                            class="inline-flex
                            items-center
                            justify-center
                            w-6 h-6 mr-2
                            text-red-500
                            transition-colors
                            duration-150
                            font-bold
                            bg-white rounded-full
                            focus:shadow-outline
                            hover:bg-custom-mustard">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                    fill-rule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clip-rule="evenodd" />
                            </svg>
                        </button>
                    </div>

                    <div class="tab-section">
                        <button
                            @click="phaseOne()"
                            class="tab-btn"
                            :style="[activeTab == 'phaseOne' ? {'--text-col' : '#00475C', '--back-col' : '#fff'} : null]">
                            <span class="large-title">DISCOVERY</span>
                            <span class="small-title">Phase 0</span>
                        </button>
                        <button
                            @click="phaseTwo()"
                            class="tab-btn"
                            :style="[activeTab == 'phaseTwo' ? {'--text-col' : '#00475C', '--back-col' : '#fff' } : {'--text-col' : '#D7BB2C', '--back-col' : '#00475C'}]">

                            <span class="large-title">TRIMESTER 1</span>
                            <span class="small-title">Phase 1</span>
                        </button>
                        <button
                            @click="phaseThree()"
                            class="tab-btn"
                            :style="[activeTab == 'phaseThree' ? {'--text-col' : '#00475C', '--back-col' : '#fff'} : null]">

                            <span class="large-title">TRIMESTER 2</span>
                            <span class="small-title">Phase 2</span>
                        </button>
                        <button
                            @click="phaseFour()"
                            class="tab-btn"
                            :style="[activeTab == 'phaseFour' ? {'--text-col' : '#00475C', '--back-col' : '#fff'} : null]">

                            <span class="large-title">TRIMESTER 3</span>
                            <span class="small-title">Phase 3</span>
                        </button>
                        <button
                            @click="phaseFive()"
                            class="tab-btn"
                            :style="[activeTab == 'phaseFive' ? {'--text-col' : '#00475C', '--back-col' : '#fff'} : null]">

                            <span class="large-title">NEWBORN</span>
                            <span class="small-title">Phase 4</span>
                        </button>
                        <button
                            @click="phaseSix()"
                            class="tab-btn"
                            :style="[activeTab == 'phaseSix' ? {'--text-col' : '#00475C', '--back-col' : '#fff'} : null]">

                            <span class="large-title">BABY</span>
                            <span class="small-title">Phase 5</span>
                        </button>
                        <button
                            @click="phaseSeven()"
                            class="tab-btn"
                            :style="[activeTab == 'phaseSeven' ? {'--text-col' : '#00475C', '--back-col' : '#fff'} : null]">

                            <span class="large-title">IMMUNISATION</span>
                            <span class="small-title">Phase 6</span>
                        </button>
                    </div>

                    <div class="modal-title">
                        <h3>{{journeyTitle}}</h3>
                        <div class="text-area">
                            <!-- {{phaseDescription}} -->
                        </div>
                        <span class="insights-title">
                            SELECT COUNTRY TO READ INSIGHTS
                        </span>
                    </div>
                    <div class="country-section">
                        <component :is="selectedComponent"></component>
                    </div>
                </div>
            </div>
        </transition>

    </div>
</template>

<script>
import Phase1 from '@/components/Pregnancy/Phases/Phase1.vue';
import Phase2 from '@/components/Pregnancy/Phases/Phase2.vue';
import Phase3 from '@/components/Pregnancy/Phases/Phase3.vue';
import Phase4 from '@/components/Pregnancy/Phases/Phase4.vue';
import Phase5 from '@/components/Pregnancy/Phases/Phase5.vue';
import Phase6 from '@/components/Pregnancy/Phases/Phase6.vue';
import Phase7 from '@/components/Pregnancy/Phases/Phase7.vue';

export default {
    name: 'PregnancyBreakdown',
    components: {
        Phase1,
        Phase2,
        Phase3,
        Phase4,
        Phase5,
        Phase6,
        Phase7,
    },

    data() {
        return {
            showModal: false,
            selectedComponent: 'Phase1',
            journeyTitle: '',
            phaseDescription: '',
            activeTab: '',

        };
    },

    methods: {
        async phaseOne() {
            this.activeTab = 'phaseOne';
            this.selectedComponent = 'Phase1';
            this.showModal = true;

            this.journeyTitle = 'DISCOVERS PREGNANCY';
            this.phaseDescription = 'Phase1: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.';
        },

        async phaseTwo() {
            this.activeTab = 'phaseTwo';
            this.selectedComponent = 'Phase2';
            this.showModal = true;

            this.journeyTitle = '0-3 MONTHS PREGNANT';
            this.phaseDescription = 'Phase2: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.';
        },

        async phaseThree() {
            this.activeTab = 'phaseThree';
            this.selectedComponent = 'Phase3';
            this.showModal = true;

            this.journeyTitle = '4-6 MONTHS PREGNANT';
            this.phaseDescription = 'Phase3: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.';
        },

        async phaseFour() {
            this.activeTab = 'phaseFour';
            this.selectedComponent = 'Phase4';
            this.showModal = true;

            this.journeyTitle = '7-9 MONTHS AND DELIVERY';
            this.phaseDescription = 'Phase4: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.';
        },

        async phaseFive() {
            this.activeTab = 'phaseFive';
            this.selectedComponent = 'Phase5';
            this.showModal = true;

            this.journeyTitle = 'UP TO 48H AFTER DELIVERY';
            this.phaseDescription = 'Phase5: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.';
        },

        async phaseSix() {
            this.activeTab = 'phaseSix';
            this.selectedComponent = 'Phase6';
            this.showModal = true;

            this.journeyTitle = '6-5 WEEKS';
            this.phaseDescription = 'Phase6: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.';
        },

        async phaseSeven() {
            this.activeTab = 'phaseSeven';
            this.selectedComponent = 'Phase7';
            this.showModal = true;

            this.journeyTitle = 'IMMUNIZATION DAY';
            this.phaseDescription = 'Phase7: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.';
        },

        async auto() {
            this.phaseOne();

            setTimeout(
                () => {
                    this.phaseTwo();
                }, 5000,
            );

            setTimeout(
                () => {
                    this.phaseThree();
                }, 8000,
            );

            setTimeout(
                () => {
                    this.phaseFour();
                }, 11000,
            );

            setTimeout(
                () => {
                    this.phaseFive();
                }, 14000,
            );

            setTimeout(
                () => {
                    this.phaseSix();
                }, 17000,
            );

            setTimeout(
                () => {
                    this.phaseSeven();
                }, 20000,
            );
            // window.setInterval(() => {
            //     this.phaseOne();
            //     this.phaseTwo();
            // }, 3000);

            // this.phaseOne();

            // setTimeout(() => true, 7000);

            // this.phaseTwo();

            // setTimeout(() => true, 7000);

            // this.phaseFour();
        },

    },
};
</script>

<style lang="css" scoped>
.overflow-hidden {
  overflow: hidden;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    background-color: var(--light-orange);

}

.back-section,
.breakdown-section {
    width: 80%;
    padding: 1em;
    }

.back-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 1em;
}

.breakdown-section {
    display: flex;
    justify-content: center;
}

.text__description {
    display: flex;
    flex-direction: column;
    text-align: center;
    letter-spacing: 1.7px;
    font-family: var(--lato);
    font-size: 0.7em;
    font-weight: 600;
}

.text__description > span {
    margin-top: 1.5em;
    padding: 0 1em;
}

.button__area {
   text-align: center;
   padding: 1em 0 1em 0;
}

.globe-section {
    position: relative;
    background: url('../../assets/images/pregnancy/Globe.svg');
    background-size: 100% 100%;
    max-width: 100vw;
    height: 700px;
    width: 700px;
}

.path-section {
    position: relative;
    background: url('../../assets/images/pregnancy/Stairs.svg');
    background-size: 100% 100%;
    max-width: 100vw;
    height: 700px;
    width: 700px;
}

.mothers-section {
    /* position: relative; */
    /* background: url('../../assets/images/pregnancy/Mothers.svg'); */
    background-size: 100% 100%;
    max-width: 100vw;
    height: 700px;
    width: 700px;
}

/* numbers  */
.number {
    position: absolute;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    border: 10px solid rgba(255, 255, 255, 0.2);
    animation: pulse 2s linear 1s infinite;
}

.number > img {
    height: 55px;
    width: 55px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
}

.number:hover {
    transform: scale(1.2);
    cursor: pointer;
    }

.one {
    top: 22%;
    left: 10%;
}

.two {
    top: 19%;
    left: 42%;
}

.three {
    top: 29%;
    left: 60%;
}

.four {
    top: 34%;
    left: 12%;
}

.five {
    top: 60%;
    left: 7%;
}

.six {
    top: 56%;
    left: 35%;
}

.seven {
    top: 66%;
    left: 80%;
}

/* mothers  */

.stage {
    position: absolute;
    width: 58px;
    border-radius: 50%;
}

.woman-one {
    top: 10%;
    left: 2%;
}

.woman-two {
    top: 0%;
    left: 42%;
    width: 10%;
}

.woman-three {
    top: 18%;
    left: 72%;
    width: 13%;
}

.woman-four {
    top: 35%;
    left: 1%;
    width: 10%;
}

.woman-five {
    top: 63%;
    left: 1%;
}

.woman-six {
    top: 56%;
    left: 21%;
    width: 12%;
}

.woman-seven {
    top: 45%;
    left: 90%;
    width: 15%;
}

/* cloud  */
.cloud {
    position: absolute;
}

.cloud > img {
    width: 100px;
    height: 60px;
}

.cloud-one {
    top: 45%;
    left: -7%;
    width: 11%;
    animation: horizontalOne 5s infinite;
    animation-timing-function: ease;
}

.cloud-two {
    top: 12%;
    left: 90%;
    width: 15%;
    animation: horizontalTwo 5s infinite;
    animation-timing-function: ease;
}

/* Tooltips */

.tooltip {
    position: absolute;
    background: var(--dark-green);
    width: 150px;
    text-align: center;
    color: var(--musard);
    font-family: var(--big-john-bold);
    font-size: 1em;
    border-radius: 9px;
    padding: 0.3em;
    border: 1px solid var(--mustard);
}

.tip-one {
    top: 24%;
    left: 20%;
}

.tip-two {
    top: 22%;
    left: 51%;
}

.tip-three {
    top: 32%;
    left: 70%;
}

.tip-four {
    top: 37%;
    left: 22%;
}

.tip-five {
    top: 63%;
    left: 17%;
}

.tip-six {
    top: 59%;
    left: 45%;
}

.tip-seven {
    top: 69%;
    left: 88%;
}
.tooltip { display: none;}

.one:hover ~ .tip-one,
.two:hover ~ .tip-two,
.three:hover ~ .tip-three,
.four:hover ~ .tip-four,
.five:hover ~ .tip-five,
.six:hover ~ .tip-six,
.seven:hover ~ .tip-seven {
            display: block;
            animation: moveInRight 1s ease-out;
        }

/* animations  */
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); }
}

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(0); }
  100% {
    opacity: 1;
    transform: translate(3rem); }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes pulse {
  0% { box-shadow:0 0 8px #fff, inset 0 0 8px #fff; }
  50% { box-shadow:0 0 26px #fff, inset 0 0 24px #fff; }
  100% { box-shadow:0 0 8px #fff, inset 0 0 8px #fff; }
}

@keyframes horizontalTwo {
  0%,
  100% {
    left: 90%;
  }
  50% {
    left: 87%;
  }
}

@keyframes horizontalOne {
  0%,
  100% {
    left: -7%;
  }
  50% {
    left: -4%;
  }
}

/* modal  */
.tab-section {
    display: flex;
    flex-direction: row;
    font-size: 0.8em;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    margin-bottom: -1px;
    /* border: 1px solid red; */
}

.large-title {
    display: block;
}

.small-title {
    display: none;
}

.tab-btn {
    --text-col: #D7BB2C;
    --back-col: #00475C;
    color: var(--text-col);
    background-color: var(--back-col);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 0.5em;
    margin: 1em 0.5px 0 0.5px;
    font-family: var(--big-john-bold);
    /* border: 1px solid red; */
}

.tab-btn:hover{
    transform: scale(1.1);
}

.leading {
    color: #00475C;
    background-color: #fff;
}

.modal-title {
    background-color: white;
    padding: 1em;
}

.modal-title > h3{
    font-family: var(--big-john-bold);
    font-weight: 500;
    font-size: 1.7em;
    letter-spacing: 1px;
    color: #D7BB2C;
}

.text-area {
    color: var(--text-gray);
    font-size: 0.8em;
    font-family: var(--lato);
    margin-bottom: 1em;
}

.insights-title {
    color: #00475C;
    font-size: 0.87em;
    font-weight: 600;
    letter-spacing: 2px;
    font-family: var(--lato);
}

.controls {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 1em;

}

.close{
    width: 30px;
    top: -30px;
    text-align: center;
    background-color: #fff;
    color: red !important;
    border-radius: 50%;
}

/* vue modal styles */
.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 98;
    background-color: rgba(0, 0, 0, 0.3);
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    width: 60%;
    max-height: calc(90vh - 5px);
    overflow-y: auto;

}

.close-btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1em;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter-from,
.fade-leave-to {
 opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
    transition: transform .5s;
}

.slide-enter,
.slide-leave-to {
    transform: translateY(-50%) translateX(100vw);
}

/* media queries */
/* for modal navigation */
@media (max-width: 1100px) {
    .large-title {
        display: none;
        }

    .small-title {
        display: block;
        }
}
@media (max-width: 892px) {
    .tab-btn {
        padding: 0.3em;
    }
}

@media (max-width: 710px) {
    .modal {
        font-size: 0.8em;
        width: 90%;
    }
}

@media (max-width: 500px) {
    .number > img {
        height: 35px;
        width: 35px;
    }

    .one {
        top: 34%;
        left: 10%;
    }

    .two {
        top: 33%;
        left: 40%;
    }

    .three {
        top: 38%;
        left: 56%;
    }

    .four {
        top: 43%;
        left: 2%;
    }

    .five {
        top: 55%;
        left: 10%;
    }

    .six {
        top: 52%;
        left: 43%;
    }

    .seven {
        top: 57%;
        left: 75%;
    }

    /* mothers  */

    .stage {
        position: absolute;
        width: 58px;
        border-radius: 50%;
    }

    .woman-one {
        top: 28%;
        left: 5%;
        width: 7%;
    }

    .woman-two {
        top: 23%;
        left: 40%;
        width: 12%;
    }

    .woman-three {
        top: 33%;
        left: 72%;
        width: 13%;
    }

    .woman-four {
        top: 40%;
        left: 1%;
        width: 7%;
    }

    .woman-five {
        top: 52%;
        left: 1%;
        width: 11%;
    }

    .woman-six {
        top: 55%;
        left: 24%;
        width: 11%;
    }

    .woman-seven {
        top: 45%;
        left: 87.5%;
        width: 15%;
    }

    /* clouds  */

    .cloud > img {
        height: 30px;
    }

    .cloud-one {
        top: 45%;
        left: -7%;
        width: 11%;
    }

    .cloud-two {
        top: 25%;
        left: 60%;
        width: 15%;
    }
}
</style>
