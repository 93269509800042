<template>
    <div class="insight-breakdown">
        <div class="insight-title">
            <div
                class="main-section"
                :style="newStyle">
                <div class="insight-number">
                    <span class="word">Insight</span>
                    <span class="number">04</span>
                </div>

                <div
                    class="insight-description"
                    :style="insightDescStyle">
                    The unpredictability and the lack of differentiation of maternal
                    health services to women in different circumstances, fosters negative
                    preconceptions and low expectations about the value these services,
                    and the social interactions they imply, in regard to the time and money women
                    invest.
                </div>
            </div>

            <div
                class="context-header"
                :style="subHeaderStyle">
                {{subHeader}}
            </div>

            <div class="country-info">
                <!-- <transition
                    name="component-fade"
                    appear> -->
                <component
                    :is="view"></component>
                <!-- </transition> -->
            </div>

            <div class="country-buttons">
                <button
                    @click="viewMali()"
                    class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-turquoise
                        hover:bg-gray-500
                        focus:outline-none">
                    MALI
                </button>

                <button
                    @click="viewMalawi()"
                    class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-button-green
                        hover:bg-gray-500
                        focus:outline-none">
                    MALAWI
                </button>

                <button
                    @click="viewEthio()"
                    class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-mustard
                        hover:bg-gray-500
                        focus:outline-none">
                    ETHIOPIA
                </button>

                <button
                    @click="viewKen()"
                    class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-orange
                        hover:bg-gray-500
                        focus:outline-none">
                    KENYA
                </button>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            view: '',
            newStyle: '',
            insightDescStyle: '',
            subHeader: 'SELECT COUNTRY TO LEARN MORE ABOUT THIS INSIGHT',
            subHeaderStyle: '',
        };
    },

    components: {
        componentMali: {
            template: '<div class="message-section message-mali">A pregnant woman’s ANC consultation is usually conducted with more than 4 people in the room (both midwives and interns) who are rarely introduced and often discuss amongst themselves during consultations. This setting, along with the rushed and procedural dynamics of the visit, offers very little space for pregnant women to ask potentially embarrassing questions or share intimate information that might have medical &amp; emotional relevance. Midwives do not share medical information effectively, offering generic, complicated, and dense information to pregnant women who often do not retain or value it. The low expectation on the  value brought by ANC appointments, and the uncertainty of the actual costs of services for families, caused by the accumulation of small, and unforeseen costs, hinders the timely adherence to ANC and related exams.</div>',
        },
        componentMalawi: {
            template: '<div class="message-section message-malawi">There is inconsistent messaging between what the health service requires from pregnant women and what they are asked to do at health facilities. Often, pregnant women in early stages of pregnancy are sent back home for not being “pregnant enough” or for late attendance to health talks. It is not clear for many women when and why to visit a health facility. This uncertainty leads women to feel anxious regarding what to expect from healthcare providers during their pregnancy journey. They fear being tested and traveling to the health facility or pharmacy when they might not be supplied with what they need. They fear being sent back, because of the facility’s daily capacity. Multigravidas do not see ANC visits as valuable because they feel they are familiar with the process and know what to expect. As a result, they intentionally delay or deprioritize ANC attendance, feeling like there is nothing new for them to learn from it. Finally, women do not usually interact with their pregnancy health booklet unless to remember a specific appointment. The information therein is considered only relevant for health providers and not women themselves.</div>',
        },
        componentEthio: {
            template: '<div class="message-section message-ethio">Postnatal care is not considered a service as such – it is largely reduced to the need for child immunization, where women are unlikely to receive individual attention to discuss their situation. Instead, they are attended simultaneously with other mothers. Family members (in postnatal, mostly mothers in law), are considered closer and more familiar to pregnant women because they follow traditional rituals and culture. They are also more available both in terms of time and emotion to listen and to and support pregnant women. Their advice, which often differs from advice of health providers, is therefore easier to receive, retain, and follow. Women are usually not familiarized with danger signs. A PNC visit is normally used as a last resource and often when their baby, or themselves, have reached a critical health situation. Upon arrival at the health facility, they expect immediate attention from health professionals, who are usually perceived to be rude or aggressive, in an interaction of few mutual empathy.</div>',
        },
        componentKen: {
            template: '<div class="message-section message-ken">Having learned about pregnancy and motherhood from friends, female relatives, community health workers, or their pharmacist, young women perceived the information they receive at a health facility as not useful. In addition, the information and care provided is not often differentiated between first time, single mothers or experienced mothers, nor adapted to their different behaviors, psychological and emotional needs. There are generally very low expectations among women and girls, about the emotional fulfillment that may come from an ANC visit. Pregnant women often reduce the importance of these services to the registration and collection of a health booklet that are required for the birth at a facility, as well as an appointment where they plan and prepare for delivery.<br /> Pregnant women and their husbands experience frustration and aversion for health facilities due to the lack of privacy and clear communication about certain procedures. Those who visit health facilities often describe unpleasant interactions that discourage them to return such as unexpected medical procedures that are either painful or intimidating.</div>',
        },
    },

    methods: {
        viewMali() {
            if (this.view !== 'componentMali') {
                this.view = 'componentMali';
            } else {
                this.view = 'componentMali';
            }

            this.subHeader = 'CONTEXTUAL BACKGROUND';
            this.newStyle = 'display : flex; flex-direction : row;  ';
            this.insightDescStyle = 'text-align: left; width: 70%';
            this.subHeaderStyle = 'text-align: left;';
        },
        viewMalawi() {
            if (this.view !== 'componentMalawi') {
                this.view = 'componentMalawi';
            } else {
                this.view = 'componentMalawi';
            }

            this.subHeader = 'CONTEXTUAL BACKGROUND';
            this.newStyle = 'display : flex; flex-direction : row;  ';
            this.insightDescStyle = 'text-align: left; width: 70%';
            this.subHeaderStyle = 'text-align: left;';
        },
        viewEthio() {
            if (this.view !== 'componentEthio') {
                this.view = 'componentEthio';
            } else {
                this.view = 'componentEthio';
            }

            this.subHeader = 'CONTEXTUAL BACKGROUND';
            this.newStyle = 'display : flex; flex-direction : row;  ';
            this.insightDescStyle = 'text-align: left;  width: 70%';
            this.subHeaderStyle = 'text-align: left;';
        },
        viewKen() {
            if (this.view !== 'componentKen') {
                this.view = 'componentKen';
            }

            this.subHeader = 'CONTEXTUAL BACKGROUND';
            this.newStyle = 'display : flex; flex-direction : row;  ';
            this.insightDescStyle = 'text-align: left; width: 70%';
            this.subHeaderStyle = 'text-align: left;';

            // this.mainSecStyle = 'font-size: 0.7em';
        },
    },
};
</script>

<style lang="css" scoped>

.insight-breakdown {
    display: flex;
    flex-direction: row;
    /* width: 60%; */
    /* margin: 3em auto; */
    /* border: 3px solid green; */
    justify-content: center;
    animation: moveInRight 1s ease-out;
}

.insight-number {
    width: 190px;
    height: 190px;
    background: var(--white);
    color: var(--text-gray);
    margin: 0 auto;
    /* border: 3px solid red; */
    border-radius: 50%; /* remove if you don't care about IE8 */
}

.insight-number {
    font-weight: 700;
    font-family: var(--bebas);
/* } */

/* .insight-number > h2 { */
    display: flex;
    flex-direction: column;
    text-align: center;
}

.word {
    margin-top: 1.5em;
    font-size: 1.7em;
    /* border: 1px solid red; */
}

.number {
    flex: 1;
    margin-top: -30px;
    font-size: 5em;
    /* border: 1px solid red; */

}

.insight-description {
    display: flex;
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    margin-top: 1em;
    font-family: var(--big-john-bold);
    font-size: 1.5em;
    /* padding: 1.5em; */
    font-weight: 600;
    text-align: center;
    /* border: 1px solid red; */
}

.context-header {
    text-align: center;
    letter-spacing: 2px;
    font-family: var(--lato);
    margin: 3em auto;
    font-size: 0.7em;
    font-weight: 700;
    /* margin: 0 auto; */
    width: 80%;
    /* border: 1px solid red; */
}

.country-buttons {
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    align-items: center;
    margin: 0 auto;
    margin-top: 2em;
    /* border: 1px solid red; */
    width: 80%;
    font-size: 1em;
}

button {
    padding: 0.4em auto;
}

.main-section {
    display: flex;
    flex-direction: column;
    /* border: 1px solid blue; */
}

.message-section {
    position: relative;
    padding: 2em;
    border-radius: 7px;
    /* font-size: 1.4em; */
    font-family: var(--lato);
    font-weight: 700;
    width: 80%;
    margin: 0 auto;
    /* border: 1px solid blue; */
}

.message-ken {
    background-color: var(--orange);
    animation: moveInRight 1s ease-out;
}

.message-ken:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid var(--orange);
  border-top: 20px solid var(--orange);
  border-bottom: 20px solid transparent;
  left: 86%;
  bottom: -5%;
}

.message-mali {
    background-color: var(--light-green);
    animation: moveInLeft 1s ease-out;
}

.message-mali:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--light-green);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--light-green);
  border-bottom: 20px solid transparent;
  left: 5%;
  bottom: -6%;
}
.message-malawi {
    background-color: var(--dark-green-2);
    animation: moveInLeft 1s ease-out;
}

.message-malawi:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--dark-green-2);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--dark-green-2);
  border-bottom: 20px solid transparent;
  left: 32%;
  bottom: -5%;
}

.message-ethio {
    background-color: var(--light-mustard);
    animation: moveInRight 1s ease-out;
}

.message-ethio:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid var(--light-mustard);
  border-top: 20px solid var(--light-mustard);
  border-bottom: 20px solid transparent;
  left: 57%;
  bottom: -6%;
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@media (max-width: 500px) {
    .main-section {

    }

    .insight-description {
        font-size: 0.8em;
        padding: 0.5em;
        /* border: 1px solid red; */
        width: 70%;
        margin:0 auto;
    }

    .insight-number {
        margin-top: 1em;
        width: 150px;
        font-size: 0.85em;
        height: 150px;
        /* border: 1px solid red; */
    }

    .country-buttons {
        margin-top: 1.8em;
        font-size: 0.5em;
        /* border: 1px solid red; */
    }

    button {
        padding: 0.5em 0.4em;
    }

    .message-ken:before {
        border-left: 11px solid transparent;
        border-right: 11px solid var(--orange);
        border-top: 11px solid var(--orange);
        border-bottom: 11px solid transparent;
        left: 86%;
        bottom: -6%;
    }

    .message-mali:before {
        border-left: 11px solid var(--light-green);
        border-right: 11px solid transparent;
        border-top: 11px solid var(--light-green);
        border-bottom: 11px solid transparent;
        left: 5%;
        bottom: -6%;
    }

    .message-malawi:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 11px solid var(--dark-green-2);
        border-right: 11px solid transparent;
        border-top: 11px solid var(--dark-green-2);
        border-bottom: 11px solid transparent;
        left: 32%;
        bottom: -6%;
    }

    .message-ethio:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 11px solid transparent;
        border-right: 11px solid var(--light-mustard);
        border-top: 11px solid var(--light-mustard);
        border-bottom: 11px solid transparent;
        left: 57%;
        bottom: -6%;
    }

}
</style>
