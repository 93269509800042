<template>
    <!-- eslint-disable max-len -->
    <div class="content">

        <!-- component slider-->
        <div class="carousel-section">
            <div class="components-container">
                <component
                    :is="currentSlide">
                </component>

                <div class="controls">
                    <div
                        class="testimony-nav"
                        @click="currentSlide = 'Testimony1'"
                        :style="[currentSlide == 'Testimony1' ? {'--secondary-color' : `${primaryColor}`, '--primary-color' : `${secondaryColor}`} : null]">
                    </div>

                    <div
                        class="testimony-nav"
                        @click="currentSlide = 'Testimony2'"
                        :style="[currentSlide == 'Testimony2' ? {'--secondary-color' : `${primaryColor}`, '--primary-color' : `${secondaryColor}`} : null]">
                    </div>

                    <div
                        class="testimony-nav"
                        @click="currentSlide = 'Testimony3'"
                        :style="[currentSlide == 'Testimony3' ? {'--secondary-color' : `${primaryColor}`, '--primary-color' : `${secondaryColor}`} : null]">
                    </div>

                    <div
                        class="testimony-nav"
                        @click="currentSlide = 'Testimony4'"
                        :style="[currentSlide == 'Testimony4' ? {'--secondary-color' : `${primaryColor}`, '--primary-color' : `${secondaryColor}`} : null]">
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Testimony1 from '@/components/Home/TestimonialSlides/Testimony1.vue';
import Testimony2 from '@/components/Home/TestimonialSlides/Testimony2.vue';
import Testimony3 from '@/components/Home/TestimonialSlides/Testimony3.vue';
import Testimony4 from '@/components/Home/TestimonialSlides/Testimony4.vue';

export default {
    name: 'Testimonials',
    data() {
        return {
            index: 1,
            currentSlide: 'Testimony1',
            primaryColor: '#1FACB5',
            secondaryColor: '#FFF',
            slides: [
                'Testimony1',
                'Testimony2',
                'Testimony3',
                'Testimony4',
            ],
        };
    },

    components: {
        Testimony1,
        Testimony2,
        Testimony3,
        Testimony4,
    },

    mounted() {
        window.setInterval(() => {
            this.prevButton();
        }, 7000);
    },

    methods: {
        async prevButton() {
            const currentIndex = this.index;
            const selectedSlide = this.slides[currentIndex];
            this.currentSlide = selectedSlide;

            // eslint-disable-next-line no-plusplus
            this.index++;
            if (this.index > this.slides.length - 1) {
                this.index = 0;
            } else if (this.index < 0) {
                this.index = this.slides.length - 1;
            }
        },

        async nextButton() {
            const currentIndex = this.index;
            const selectedSlide = this.slides[currentIndex];
            this.currentSlide = selectedSlide;

            // eslint-disable-next-line no-plusplus
            this.index--;
            if (this.index > this.slides.length - 1) {
                this.index = 0;
            } else if (this.index < 0) {
                this.index = this.slides.length - 1;
            }
        },
    },

};
</script>

<style lang="css" scoped>
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*very sus*/
    min-height: 600px;
    padding: 1em;
    background-image:
    linear-gradient( rgba(23, 26, 27, 0.6), rgba(14, 15, 15, 0.65)),
    url('../../assets/images/home/testimonials/community2.jpeg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    }

.carousel-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.components-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em;
}

.controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.testimony-nav {
    --primary-color: #1FACB5;
    --secondary-color: #FFF;
    background: var(--primary-color);
    height: 15px;
    width: 15px;
    margin: 3px;
    border-radius: 50%;
}

.testimony-nav:hover {
    border: 2px solid var(--secondary-color);
}
</style>
