<template>
    <div class="main-container">
        <section class="header-section">
            <nav-header/>
        </section>

        <section class="breakdown-section">
            <insight-breakdown/>
        </section>

        <section class="footer">
            <nav-footer/>
        </section>
    </div>
</template>

<script>
import NavHeader from '@/components/Insights/Header.vue';
import InsightBreakdown from '@/components/Insights/InsightBreakdown.vue';
import NavFooter from '@/components/UI/Footer.vue';

export default {
    name: 'MacroInsights',
    components: {
        NavHeader,
        InsightBreakdown,
        NavFooter,
    },

};
</script>

<style>
.main-container {
    display: flex;
    flex-direction: column;
}

</style>
