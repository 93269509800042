<template>
    <!-- eslint-disable max-len -->
    <div class="container">
        <div class="header-section">
            <div class="title-section">
                <h2>Download Reports</h2>
            </div>

            <div class="description-section">
                These reports served as outputs and reference materials to assist in our intervention
                development. We are sharing them to inspire and inform others.
            </div>
        </div>

        <div class="body-section">
            <div class="mali">
                <h3>MALI</h3>
                <ul class="list">                
                    <li>
                        <a
                            :href="`${publicPath}resources/mali/Mali-Insights-Report(French).pdf`"
                            class="mali-download"
                            target="_blank">
                            Insights Report (FR)
                        </a>
                    </li>
                
                    <li>
                        <a
                            :href="`${publicPath}resources/mali/Mali-Insights-Report(English).pdf`"
                            class="mali-download"
                            target="_blank">
                            Insights Report (EN)
                        </a>
                    </li>
               
                    <li>
                        <a
                            :href="`${publicPath}resources/mali/Mali-Prototyping&Recommendations-Report-Mali_V2.pdf`"
                            class="mali-download"
                            target="_blank">
                            Prototyping Report
                        </a>
                    </li>
                
                    <li>
                        <a
                            :href="`${publicPath}resources/mali/jhpiego_Mali_pitch deck_first_draft.pdf`"
                            class="mali-download"
                            target="_blank">
                            Prototype Testing and Refining Report
                        </a>
                    </li>
                </ul> 

            </div>

            <div class="malawi">
                <h3>MALAWI</h3>
                <ul class="list">
                    <li>
                        <a
                        :href="`${publicPath}resources/malawi/Malawi-Insights&Recommendations-Report.pdf`"
                        class="malawi-download"
                        target="_blank">Insights</a>
                    </li>
                    <li>
                        <a
                        :href="`${publicPath}resources/malawi/Malawi_Proposed_Solutions_eng.pdf`"
                        class="malawi-download"
                        target="_blank"> Prototyping </a>
                    </li>
                </ul>

                <!-- <div class="download">
                    <span>
                        Prototyping Report
                    </span>

                    <span>
                        <a
                            :href="`${publicPath}resources/jhpiego-learnings.pdf`"
                            class="malawi-download"
                            download>
                            Download
                        </a>
                    </span>
                </div> -->
            </div>

            <div class="kenya">
                <h3>KENYA</h3>
                <ul class="list">
                    <li> 
                        <a
                        :href="`${publicPath}resources/kenya/Community_Consultations_Insights_Report.pdf`"
                        class="ken-download"
                        target="_blank">Community Consultation</a> 
                    </li>
                        
                    <li>  
                        <a
                        :href="`${publicPath}resources/kenya/Concept_Notes.pdf`"
                        class="ken-download"
                        target="_blank">Concept Notes</a>
                    </li>
                </ul>
				
            </div>

            <div class="ethiopia">
                <h3>ETHIOPIA</h3>
                <ul class="list">
                    <li>
                        <a
                        :href="`${publicPath}resources/ethiopia/Ethiopia-Insights-Report-Cross-sharing-platform.pdf`"
                        class="ethio-download"
                        target="_blank">Insights</a>
                    </li>
                    <li>
                        <a
                        :href="`${publicPath}resources/ethiopia/Ethiopia-Prototyping-Report.pdf`"
                        class="ethio-download"
                        target="_blank"> Prototyping </a>
                    </li>
                </ul>
            </div>

            <div class="general">
                <h3>General</h3>
                <ul class="list">
                    <li>
                        <a
                        :href="`${publicPath}resources/general/Journey-of-Anxiety.pdf`"
                        class="general-download"
                        target="_blank">
                        Journey of Anxiety
                        </a>
                    </li>
                    <li>
                        <a
                        :href="`${publicPath}resources/general/Macro-Level-Insights.pdf`"
                        class="general-download"
                        target="_blank">
                        Macro-level Insights
                        </a>
                    </li>
                </ul>              
                <!-- <div class="download">
                    <span>
                        Prototyping Report
                    </span>

                    <span>
                        <a
                            :href="`${publicPath}
                            resources/ethiopia/Ethiopia-Prototyping-Report.pdf`"
                            class="general-download"
                            download>
                            Download
                        </a>
                    </span>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Downloads',

    data() {
        return {
            publicPath: process.env.BASE_URL,
        };
    },
};
</script>

<style lang="css" scoped>
.container {
    display: flex;
    flex-direction: column;
    min-height: 90vh;
    width: 70%;
    margin: 0 auto;
    padding: 1em 0;
    color: var(--text-gray);
}
ul.list {padding-left:40px;}
ul.list li{list-style:disc;}
.header-section,
.body-section {
    padding: 1em;
}

.title-section,
.description-section {
    padding: 1em;
}

h2 {
    font-family: var(--big-john-bold);
    color: var(--dark-mustard);
    font-size: 2em;
}

h3 {
    font-family: var(--lato);
    font-size: 1em;
    font-weight: 900;
}

.download {
    display: flex;
    justify-content: space-between;
    font-family: var(--lato);
    font-size: 0.8em;
    margin-top: 1em;
    border-bottom: 1px solid var(--text-gray);
}

.mali,
.malawi,
.kenya,
.ethiopia,
.general {
    padding: 1em;
}

.mali > h3 {
    color: var(--light-green);
    border-bottom: 5px solid var(--light-green);
}

.malawi > h3 {
    color: var(--dark-blue);
    border-bottom: 5px solid var(--dark-blue);
}

.kenya > h3 {
    color: var(--orange);
    border-bottom: 5px solid var(--orange);
}

.ethiopia > h3 {
    color: var(--dark-mustard);
    border-bottom: 5px solid var(--dark-mustard);
}

.general > h3 {
    color: var(--text-gray);
    border-bottom: 5px solid var(--text-gray);
}

.mali-download:hover {
    color: var(--light-green);
    font-weight: 600;

}

.malawi-download:hover {
    color: var(--dark-blue);
    font-weight: 600;

}

.ethio-download:hover {
    color: var(--mustard);
    font-weight: 600;
}

.ken-download:hover {
    color: var(--orange);
    font-weight: 600;
}

.general-download:hover {
    color: var(--text-gray);
    font-weight: 600;
}
</style>
