<template>
    <div class="container">
        <div class="controls">
            <button
                @click="mali"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-turquoise
                        hover:bg-custom-turquoise-hover
                        focus:outline-none">
                MALI
            </button>

            <!-- <button
                @click="malawi"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-button-green
                        hover:bg-button-green-hover
                        focus:outline-none">
                MALAWI
            </button> -->

            <button
                @click="ethiopia"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-mustard
                        hover:bg-custom-yellow
                        focus:outline-none">
                ETHIOPIA
            </button>

            <button
                @click="kenya"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-orange
                        hover:bg-custom-orange-hover
                        focus:outline-none">
                KENYA
            </button>
        </div>
        <div class="message-section">
            <div
                class="card"
                :style="cardColor ">
                <span class="card-title">
                    {{cardTitleOne}}
                </span>
                <div class="card-message">
                    {{cardMessageOne}}
                </div>
                <div class="card-image">
                    <img
                        src="../../../assets/images/pregnancy/mother-icon.png"
                        alt="">
                </div>
            </div>

            <div
                class="card"
                :style="cardColor ">
                <span class="card-title">
                    {{cardTitleTwo}}
                </span>
                <div class="card-message">
                    {{cardMessageTwo}}
                </div>
                <div class="card-image">
                    <img
                        src="../../../assets/images/pregnancy/people-icon.png"
                        alt="">
                </div>
            </div>

            <div
                class="card"
                :style="cardColor">
                <span class="card-title">
                    {{cardTitleThree}}
                </span>
                <div class="card-message">
                    {{cardMessageThree}}
                </div>
                <div class="card-image">
                    <img
                        src="../../../assets/images/pregnancy/nurse-icon.png"
                        alt="">
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Phase1',

    data() {
        return {
            cardColor: '--gradient: #29CAD3;',
            cardTitleOne: 'PREGNANT WOMAN',
            cardTitleTwo: 'SOCIETY’S EXPECTATION',
            cardTitleThree: 'HEALTH PRACTITIONNERS',
            cardMessageOne: 'The unpredictable environment of the health centres, caused by health risks, corruption & poor health practitioner training, lead pregnant women in labour to feel lost, stressed & vulnerable. This is further exacerbated by the fact that in the health centres, orienting users upon arrival is an unworthy task, which leave women in labour unattended both medically and emotionally until the delivery is actually happening.',
            cardMessageTwo: 'The experience of labour and delivery is an unpleasant one at best as both midwives and pregnant women are stressed since “death is around the corner”. Deliveries are considered to be “une affaire de femmes”, i.e. women’s business, where women interact spontaneously with each other with no filters of social etiquette. Yet, none of this emotionally heavy backstage should go beyond the labour room.',
            cardMessageThree: 'Midwives are unprepared for the life-threatening dangers of delivery. “As a midwife, you’re not trained for what’s coming.“ Overworked, undertrained and unmonitored, the most stressful aspect of being a midwife at a community health centre is the daily threat of delivery complications..',

        };
    },
    methods: {
        mali() {
            this.cardColor = '--gradient: #29CAD3;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'The unpredictable environment of the health centres, caused by health risks, corruption & poor health practitioner training, lead pregnant women in labour to feel lost, stressed & vulnerable. This is further exacerbated by the fact that in the health centres, orienting users upon arrival is an unworthy task, which leave women in labour unattended both medically and emotionally until the delivery is actually happening.';
            this.cardMessageTwo = 'The experience of labour and delivery is an unpleasant one at best as both midwives and pregnant women are stressed since “death is around the corner”. Deliveries are considered to be “une affaire de femmes”, i.e. women’s business, where women interact spontaneously with each other with no filters of social etiquette. Yet, none of this emotionally heavy backstage should go beyond the labour room.';
            this.cardMessageThree = 'Midwives are unprepared for the life-threatening dangers of delivery. “As a midwife, you’re not trained for what’s coming.“ Overworked, undertrained and unmonitored, the most stressful aspect of being a midwife at a community health centre is the daily threat of delivery complications..';
        },

        malawi() {
            this.cardColor = '--gradient: #00475C;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'No findings.';
            this.cardMessageTwo = 'No findings.';
            this.cardMessageThree = 'No findings.';
        },

        ethiopia() {
            this.cardColor = '--gradient: #DCC332;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'As I am about to enter labour, I reach the health centre expecting immediate care, or at least orientations around what I should do to deal with the pain and labour. I often get frustrated when I’m simply told to wait in a common waiting room, without further advice or support.';
            this.cardMessageTwo = 'Husbands suffer a lot waiting for news, they stand together outside the health centre. They feel responsible for the health of the baby and worry about the mother’s health, after they only focus on baby.';
            this.cardMessageThree = 'Midwives have a lot of responsabilities, and just want a fast and healthy delivery. They feel a lot of pressure to do their work well because the health centre is always full, and there is no bed capacity for all. Any complication, delay or medical error would have lasting trickle-down effects on the whole team’s work & stress-load.';
        },

        kenya() {
            this.cardColor = '--gradient: #ED6E53;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'As a first time mother, I often feel anxious about my delivery and any potential complications. I look for support and reassurance from the nurses, other delivering mothers and/or family members. Those delivering in larger hospitals tend to feel more neglected.';
            this.cardMessageTwo = 'As a midwife, I expect that first time pregnant women will know what is expected of them when they come to ANC and delivery. While I don’t actively inform first-time mothers of what to expect, I will feel frustrated when they don’t meet my expectations.';
            this.cardMessageThree = 'Women’s preferences are rarely considered when it comes to delivery. Service providers mostly feel overstretched and unable to provide personalized / high quality care even where they would like to. Their impatience can lead them to treat the mothers with hostility rather than patience, and focus on delivering the baby without addressing the psychosocial needs of the mother at this time.';
        },
    },

};
</script>

<style lang="css" scoped>
.controls {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 1em;
    margin: -5px 0;
    /* flex-wrap: wrap; */

}

.controls > button {
    /* padding: 0.5em 45px 0.5em 45px; */
      width: 100%;

}

.message-section {
     display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1em;
    /* border: 1px solid blue; */
    background-color: white;

}

.card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* word-wrap: break-word; */
    overflow-wrap: break-word;
    /* --gradient:#29CAD3; */
    background-color: var(--gradient);
    width: 30%;
    min-width: 250px;
    border-radius: 5px;
    padding: 1em;
    margin: auto;
    margin-bottom: 3em;
    position: relative;
    animation: moveInBottom 1s ease-out;
}

.card-title {
    font-family: var(--big-john-bold);
    font-size: 0.7em;
    margin: 1.5em 0 1em 0;
}

.card-message {
    font-family: var(--lato);
    font-size: 0.7em;
    min-height: 200px;
}

.card-image {
    margin: 1em auto -3.7em auto;
    height: 55px;
    width: 55px;
}

.card:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--gradient);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 197px;
  bottom: -19px;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }
</style>
