<template>
    <div class="insight-content">
        <div
            :class="currentClass"
            :style="countryColor">
            {{countryMessage}}
        </div>

        <div class="country-controls">
            <button
                @click="mali()"
                class="country-button button-mali">
                MALI
            </button>

            <button
                @click="malawi()"
                class="country-button button-malawi">
                MALAWI
            </button>

            <button
                @click="ethiopia()"
                class="country-button button-ethiopia">
                ETHIOPIA
            </button>

            <button
                @click="kenya()"
                class="country-button button-kenya">
                KENYA
            </button>
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            countryColor: '--gradient: #29CAD3;',
            currentClass: 'country-message country-message-mali',
            countryMessage: 'Money influences the way a pregnant woman is treated when seeking ANC and delivery care: better and faster inter-personal care might be granted to those who look wealthier, while those from modest backgrounds might be asked to wait more or seek another midwife. For midwives, bribes, corruption and small gifts unfortunately represent a considerable benefit given their very low and irregular salaries.',

        };
    },
    methods: {
        mali() {
            this.countryColor = '--gradient: #29CAD3;';
            this.currentClass = 'country-message country-message-mali';
            this.countryMessage = 'Money influences the way a pregnant woman is treated when seeking ANC and delivery care: better and faster inter-personal care might be granted to those who look wealthier, while those from modest backgrounds might be asked to wait more or seek another midwife. For midwives, bribes, corruption and small gifts unfortunately represent a considerable benefit given their very low and irregular salaries.';
        },

        malawi() {
            this.countryColor = '--gradient: #00475C;';
            this.currentClass = 'country-message country-message-malawi';
            this.countryMessage = 'The high-power distance orientation in Malawi increases the potential for discrimination based on wealth or origin of the pregnant woman. There is a common perception among pregnant women that, in order to be seen by a health professional, they need to look worthy and clean. However, many women lack the income to purchase basic self-care items (e.g., a razor) or what they perceive is appropriate clothing (e.g. Chitenge wrap). The reluctance to show up as they are, fearing to face (or provide) a negative experience, prevents them from seeking ANC services and receiving care.';
        },

        ethiopia() {
            this.countryColor = '--gradient: #DCC332;';
            this.currentClass = 'country-message country-message-ethiopia';
            this.countryMessage = 'The community associates women who deliver at home as poorer and of lower social status. At the health  facility, the quality of care that these women of lower social status receive is often less than that of those with higher social status. This negative perception also affects Women’s Development Army volunteers(WDAs) who assist women to deliver at home. WDAs acknowledge that their age, wealth, and literacy influence their ability to provide, and be respected for, the support they are appointed to offer to recent mothers in their community.';
        },

        kenya() {
            this.countryColor = '--gradient: #ED6E53;';
            this.currentClass = 'country-message country-message-ken';
            this.countryMessage = 'The age of a pregnant woman highly determines how she is treated in different touch points at a health facility during the provision of maternal health services. Young pregnant girls are often openly criticized, challenged, or mocked because of behavior (i.e. having sex at an early age), or their lack of preparedness. Older women experience a different type of criticism. They are ill-treated or called out for poor pain management during delivery, especially if they have many children. Likely due to a previous poor experience, pregnant women assume midwives do not have time to answer their questions, even when they do. In general, younger midwives are perceived to be better educated and more approachable and discreet by pregnant women, than older health professionals.';
        },
    },

};
</script>

<style lang="css" scoped>
.country-message {
    background: var(--gradient);
    color: var(--white);
}

.country-message {
    position: relative;
    padding: 1em;
    border-radius: 7px;
    font-family: var(--lato);
    font-weight: 600;
    font-size: 0.8em;
    width: 90%;
    margin: 0 auto;
}

/* .country-message-mali:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--gradient);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 5%;
  bottom: -17%;
}

.country-message-malawi:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--gradient);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 32%;
  bottom: -10%;
}

.country-message-ethiopia:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid var(--gradient);
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 57%;
  bottom: -10%;
}

.country-message-ken:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid var(--gradient);
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 80%;
  bottom: -10%;
} */

.country-controls {
    display: flex;
    justify-content: space-between;
    padding: 1em;
}

.country-button {
    flex: 1;
    color: var(--white);
    font-size: 0.8em;
    font-weight: 600;
    padding: 0.5em;
    border-radius: 7px;
    margin: 2px;
    opacity: 1;
}

.button-mali {
    background: var(--teal);
}

.button-malawi {
    background: var(--dark-green);
}

.button-ethiopia {
    background: var(--dark-mustard);
}

.button-kenya {
    background: var(--orange);
}
</style>
