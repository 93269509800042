<template>
    <div class="container">
        <div class="controls">
            <button
                @click="mali"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-turquoise
                        hover:bg-custom-turquoise-hover
                        focus:outline-none">
                MALI
            </button>

            <button
                @click="malawi"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-button-green
                        hover:bg-button-green-hover
                        focus:outline-none">
                MALAWI
            </button>

            <button
                @click="ethiopia"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-mustard
                        hover:bg-custom-yellow
                        focus:outline-none">
                ETHIOPIA
            </button>

            <button
                @click="kenya"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-orange
                        hover:bg-custom-orange-hover
                        focus:outline-none">
                KENYA
            </button>
        </div>
        <div class="message-section">
            <div
                class="card"
                :style="cardColor ">
                <span class="card-title">
                    {{cardTitleOne}}
                </span>
                <div class="card-message">
                    {{cardMessageOne}}
                </div>
                <div class="card-image">
                    <img
                        src="../../../assets/images/pregnancy/mother-icon.png"
                        alt="">
                </div>
            </div>

            <div
                class="card"
                :style="cardColor"
                v-show="cardMessageTwo">
                <span class="card-title">
                    {{cardTitleTwo}}
                </span>
                <div class="card-message">
                    {{cardMessageTwo}}
                </div>
                <div class="card-image">
                    <img
                        src="../../../assets/images/pregnancy/people-icon.png"
                        alt="">
                </div>
            </div>

            <div
                class="card"
                :style="cardColor"
                v-show="cardMessageThree">
                <span class="card-title">
                    {{cardTitleThree}}
                </span>
                <div class="card-message">
                    {{cardMessageThree}}
                </div>
                <div class="card-image">
                    <img
                        src="../../../assets/images/pregnancy/nurse-icon.png"
                        alt="">
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            cardColor: '--gradient: #29CAD3;',
            cardTitleOne: 'PREGNANT WOMAN',
            cardTitleTwo: 'SOCIETY’S EXPECTATION',
            cardTitleThree: 'HEALTH PRACTITIONNERS',
            cardMessageOne: 'Pregnancy it not just a one-time payment, it’s a constant money-drain, in which the stressful, tiring and costly investments aren’t often rewarded by what I expect as benefits: I want information, reassurance, and courage.',
            cardMessageTwo: 'Facing multiple social expectations (donor, supervisor, informant, etc.), what pregnancy brings to a man’s mind is “Fear and Joy”. Yet, to keep his pride, the fear linked to the stressful unpredictability of both health and financial outcomes can’t be disclosed openly.',
            cardMessageThree: 'Midwives’ burden is to be altruistic beyond reason: Midwives are expected to be strong performers, top notch stress managers, involved emotional supporters and willing to donate much of their time. While they’re not allowed to complain or make mistakes, they’re rarely taught or equipped to ensure best practice. They wish for more upskilling and professional support to improve their capacities and potentially earn more.',

        };
    },
    methods: {
        mali() {
            this.cardColor = '--gradient: #29CAD3;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'Pregnancy it not just a one-time payment, it’s a constant money-drain, in which the stressful, tiring and costly investments aren’t often rewarded by what I expect as benefits: I want information, reassurance, and courage.';
            this.cardMessageTwo = 'Facing multiple social expectations (donor, supervisor, informant, etc.), what pregnancy brings to a man’s mind is “Fear and Joy”. Yet, to keep his pride, the fear linked to the stressful unpredictability of both health and financial outcomes can’t be disclosed openly.';
            this.cardMessageThree = 'Midwives’ burden is to be altruistic beyond reason: Midwives are expected to be strong performers, top notch stress managers, involved emotional supporters and willing to donate much of their time. While they’re not allowed to complain or make mistakes, they’re rarely taught or equipped to ensure best practice. They wish for more upskilling and professional support to improve their capacities and potentially earn more.';
        },

        malawi() {
            this.cardColor = '--gradient: #00475C;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'I need to start preparing for my delivery day. I’ll start going to ANC so that I can start engaging with the nurses. This way, I might have a smoother delivery day.';
            this.cardMessageTwo = 'I would like to know how my baby is progressing. But I am afraid of what my friends will think of me if I accompany my wife. Some people will think that she has bewitched me, or that I’m too dedicated to her.';
            this.cardMessageThree = '';
        },

        ethiopia() {
            this.cardColor = '--gradient: #DCC332;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'Pregnancy is harder for mothers that don’t have any health centre near by. They often travel to the health facility 2 to 3 weeks before labour, usualy with their husband or/and mother-in-law.';
            this.cardMessageTwo = '';
            this.cardMessageThree = '';
        },

        kenya() {
            this.cardColor = '--gradient: #ED6E53;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'I begin to worry about my delivery day and what I need to prepare before the baby arrives. I need to have a card because the doctor will ask me for it on delivery day. I hope that I will have an experienced doctor in case of any emergency. Multigravida usually do not expect complications with their delivery if their previous pregnancies went smoothly.';
            this.cardMessageTwo = 'Families begin preparing for the arrival of the baby, and seeking information about good clinics or hospitals to deliver in from their networks.';
            this.cardMessageThree = 'Health practitioners usually have high expectations at this stage of women attending their ANC sessions.';
        },
    },

};
</script>

<style lang="css" scoped>
.controls {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 1em;
    margin: -5px 0;
    /* flex-wrap: wrap; */

}

.controls > button {
    /* padding: 0.5em 45px 0.5em 45px; */
      width: 100%;

}

.message-section {
     display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1em;
    /* border: 1px solid blue; */
    background-color: white;

}

.card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* word-wrap: break-word; */
    overflow-wrap: break-word;
    /* --gradient:#29CAD3; */
    background-color: var(--gradient);
    width: 30%;
    min-width: 250px;
    border-radius: 5px;
    padding: 1em;
    margin: auto;
    margin-bottom: 3em;
    position: relative;
    animation: moveInBottom 1s ease-out;
}

.card-title {
    font-family: var(--big-john-bold);
    font-size: 0.7em;
    margin: 1.5em 0 1em 0;
}

.card-message {
    font-family: var(--lato);
    font-size: 0.7em;
    min-height: 200px;
}

.card-image {
    margin: 1em auto -3.7em auto;
    height: 55px;
    width: 55px;
}

.card:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--gradient);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 197px;
  bottom: -19px;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }
</style>
