<template>
    <div class="container">

        <!-- <div class="flip-card">
            <h3>INTERVIEW GUIDE</h3>
            <div class="flip-card-inner">
                <div class="flip-card-front">
                    <img
                        src="../../../assets/images/home/toolkit/interview-guide.svg"
                        alt="Avatar">
                </div>
                <div class="flip-card-back back1">

                    <p>Questions to explore with
                        participants. The researchers chose
                        the themes to focus on before and
                        during the interview (corresponding
                        with the lines of investigation).</p>

                </div>
            </div>
        </div> -->

        <div class="flip-card">
            <h3>A DAY IN THE LIFE DIARY</h3>
            <div class="flip-card-inner">
                <div class="flip-card-front">
                    <img
                        src="../../../assets/images/home/toolkit/a_day_in_life.svg"
                        alt="Avatar">
                </div>
                <div class="flip-card-back back2">
                    <!-- <h1>Toolkit Title</h1> -->
                    <p>‘A day in the life’ is a diary/journal with
                        empty spaces which pregnant women used
                        to document their day-to-day life.</p>

                </div>
            </div>
        </div>

        <div class="flip-card">
            <h3>GOOD ADVICE CORNER</h3>
            <div class="flip-card-inner">
                <div class="flip-card-front">
                    <img
                        src="../../../assets/images/home/toolkit/good_advice_corner2.svg"
                        alt="Avatar">
                </div>
                <div class="flip-card-back back3">
                    <!-- <h1>Toolkit Title</h1> -->
                    <p>Good advice corner is a scripted story
                        packaged in a 1-2 minute video clip. The
                        character faces a particular challenge related
                        to ANC. The research participants are
                        requested to advise the character on how to
                        solve her challenge.</p>

                </div>
            </div>
        </div>

        <div class="flip-card">
            <h3>GLOBAL MUMS</h3>
            <div class="flip-card-inner">
                <div class="flip-card-front">
                    <img
                        src="../../../assets/images/home/toolkit/global_mums.svg"
                        alt="Avatar">
                </div>
                <div class="flip-card-back back4">
                    <!-- <h1>Toolkit Title</h1> -->
                    <p>Global Mums is a tool comprising a set of audio
                        stories from women in different countries
                        sharing how news of a pregnancy is received in
                        their contexts and the support they receive
                        during pregnancy.</p>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
};
</script>

<style lang="css" scoped>
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  /* border: 1px solid blue; */
  /* height: auto; */
  padding: 2em;
  animation: moveInBottom 1s ease-out;
}

.card1 {
  background: url('../../../assets/images/home/tool_1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.card2 {
  background: url('../../../assets/images/home/tool_2.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.card3 {
  background: url('../../../assets/images/home/tool_3.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.flip-card {
  background-color: transparent;
  width: 270px;
  height: 350px;
  /* perspective: 1000px; */
  margin: 2em 2px;
  border-radius: 10px;
  /* border: 1px solid blue; */
}

.flip-card > h3 {
  color: var(--toolkit-orange);
  font-family: var(--big-john-bold);
  font-size: 1em;
  text-align: center;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-bottom: 1em;
  /* color: black; */
}

.flip-card-front > img {
  height: 100px;
}

.flip-card-back {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: var(--dark-blue);
  padding: 1em;
  transform: rotateY(180deg);
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }
</style>
