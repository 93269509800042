<template>
    <div class="container">

        <!-- <div class="flip-card">
            <h3>VISUAL GUIDES</h3>
            <div class="flip-card-inner">
                <div class="flip-card-front card1"></div>

                <div class="flip-card-back back1">
                    <p>Visual aids are a set of pictures used to
                        stimulate conversations about the presented
                        scenario. We used them to uncover research
                        participants’ attitudes and beliefs about their
                        community and the social norms they perceive.</p>

                </div>
            </div>
        </div> -->

        <div class="flip-card">
            <h3>CHAMPION MOMS</h3>
            <div class="flip-card-inner">
                <div class="flip-card-front">
                    <img
                        src="../../../assets/images/home/toolkit/champion_mums.svg"
                        alt="Avatar">
                </div>
                <div class="flip-card-back back2">
                    <!-- <h1>Toolkit Title</h1> -->
                    <p>This tool engaged research participants to
                        design a skit that portrays a mother as a
                        champion. To inspire the skit, the group
                        was asked to think about, what is the
                        greatest challenge for a mother? Who are
                        her accomplices and “sidekicks”?</p>

                </div>
            </div>
        </div>

        <div class="flip-card">
            <h3>SILENT DEBATE</h3>
            <div class="flip-card-inner">
                <div class="flip-card-front">
                    <img
                        src="../../../assets/images/home/toolkit/silent_debate.svg"
                        alt="Avatar">
                </div>
                <div class="flip-card-back back3">
                    <!-- <h1>Toolkit Title</h1> -->
                    <p>This tool comprises a set of statements about
                        common pregnancy/motherhood-related
                        scenarios. Research participants are tasked to
                        indicate whether they agree or disagree with
                        the statements presented.</p>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
};
</script>

<style lang="css" scoped>
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  /* border: 1px solid blue; */
  /* height: auto; */
  padding: 2em;
  animation: moveInBottom 1s ease-out;
}

.card1 {
  background: url('../../../assets/images/home/toolkit/visual.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.card2 {
  background: url('../../../assets/images/home/toolkit/champion.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.card3 {
  background: url('../../../assets/images/home/toolkit/silent.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.flip-card {
  background-color: transparent;
  width: 250px;
  height: 350px;
  /* perspective: 1000px; */
  margin: 2em 2px;
  border-radius: 10px;
}

.flip-card > h3 {
  color: var(--toolkit-orange);
  font-family: var(--big-john-bold);
  font-size: 1em;
  text-align: center;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-bottom: 1em;
  /* color: black; */
}

.flip-card-front > img {
  height: 100px;
}

.flip-card-back {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: var(--dark-blue);
    padding: 1em;
    transform: rotateY(180deg);
}

.back1 {
    /* background: var(--light-green); */
}

.back2 {
    /* background: var(--orange); */
}

.back3 {
    /* background: var(--mustard); */
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@media (max-width: 500px) {
  .flip-card {
    width: 90%;
  }
}
</style>
