import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import MacroInsights from '../views/MacroInsights.vue';
import PregnancyJourney from '../views/Pregnancy.vue';
import ContactUs from '../views/Contact.vue';
import Resources from '../views/Resources.vue';
import Car from '../views/Car.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/insights',
        name: 'MacroInsights',
        component: MacroInsights,
    },
    {
        path: '/pregnancy',
        name: 'PregnancyJourney',
        component: PregnancyJourney,
    },
    {
        path: '/contact',
        name: 'ContactUs',
        component: ContactUs,
    },
    {
        path: '/resources',
        name: 'Resources',
        component: Resources,
    },
    {
        path: '/car',
        name: 'Car',
        component: Car,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    },
});

export default router;
