<template>
    <div class="container">

        <div class="flip-card">
            <h3>DEBATES</h3>
            <div class="flip-card-inner">
                <div class="flip-card-front">
                    <img
                        src="../../../assets/images/home/toolkit/debates.svg"
                        alt="Avatar">
                </div>
                <div class="flip-card-back back1">
                    <!-- <h1>Toolkit Title</h1> -->
                    <p>A light debate organised between
                        participants on maternal health care in
                        which two teams of participants are
                        invited to defend opposing points of
                        view and then to discuss the
                        arguments and ideas that have been
                        debated.</p>

                </div>
            </div>
        </div>

        <div class="flip-card">
            <h3>MIDWIVES OF THE WORLD</h3>
            <div class="flip-card-inner">
                <div class="flip-card-front">
                    <img
                        src="../../../assets/images/home/toolkit/midwives-of-the-world.svg"
                        alt="Avatar">
                </div>
                <div class="flip-card-back back2">
                    <!-- <h1>Toolkit Title</h1> -->
                    <p>Short video clips showing the
                        pregnancy and MCH experience of
                        midwives from different countries of
                        the World.</p>

                </div>
            </div>
        </div>

        <div class="flip-card">
            <h3>OBSERVATION GUIDE</h3>
            <div class="flip-card-inner">
                <div class="flip-card-front">
                    <img
                        src="../../../assets/images/home/toolkit/observation-guide.svg"
                        alt="Avatar">
                </div>
                <div class="flip-card-back back3">
                    <!-- <h1>Toolkit Title</h1> -->
                    <p>The researchers observed health
                        professionals and pregnant women
                        for half a day. They used a
                        pre-filled guide for note-taking
                        during 2-3 hours of observation.</p>

                </div>
            </div>
        </div>

        <div class="flip-card">
            <h3>MY DIARY</h3>
            <div class="flip-card-inner">
                <div class="flip-card-front">
                    <img
                        src="../../../assets/images/home/toolkit/my-diary.svg"
                        alt="Avatar">
                </div>
                <div class="flip-card-back back4">
                    <!-- <h1>Toolkit Title</h1> -->
                    <p>A tool through which the researchers
                        collected the feelings and thoughts of the
                        participants over the course of a week,
                        either in writing in a logbook (midwives)
                        or by voice message and photo (pregnant
                        women).</p>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
};
</script>

<style lang="css" scoped>
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  /* border: 1px solid blue; */
  /* height: auto; */
  padding: 2em;
  animation: moveInBottom 1s ease-out;
}

.card1 {
  background: url('../../../assets/images/home/tool_1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.card2 {
  background: url('../../../assets/images/home/tool_2.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.card3 {
  background: url('../../../assets/images/home/tool_3.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.flip-card {
  background-color: transparent;
  width: 250px;
  height: 350px;
  /* perspective: 1000px; */
  margin: 2em 2px;
  border-radius: 10px;
}

.flip-card > h3 {
  color: var(--toolkit-orange);
  font-family: var(--big-john-bold);
  font-size: 1em;
  text-align: center;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-bottom: 1em;
  /* color: black; */
}

.flip-card-front > img {
  height: 100px;
}

.flip-card-back {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: var(--dark-blue);
  padding: 1em;
  transform: rotateY(180deg);
}

.back1 {
    /* background: var(--light-green); */
}

.back2 {
    /* background: var(--orange); */
}

.back3 {
    /* background: var(--mustard); */
}

.back4 {
    /* background: var(--dark-blue); */
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }
</style>
