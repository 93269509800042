<template>
    <div class="insight-content">
        <div
            :class="currentClass"
            :style="countryColor">
            {{countryMessage}}
        </div>

        <div class="country-controls">
            <button
                @click="mali()"
                class="country-button button-mali">
                MALI
            </button>

            <button
                @click="malawi()"
                class="country-button button-malawi">
                MALAWI
            </button>

            <button
                @click="ethiopia()"
                class="country-button button-ethiopia">
                ETHIOPIA
            </button>

            <button
                @click="kenya()"
                class="country-button button-kenya">
                KENYA
            </button>
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            countryColor: '--gradient: #29CAD3;',
            currentClass: 'country-message country-message-mali',
            countryMessage: 'A pregnant woman’s ANC consultation is usually conducted with more than 4 people in the room (both midwives and interns) who are rarely introduced and often discuss amongst themselves during consultations. This setting, along with the rushed and procedural dynamics of the visit, offers very little space for pregnant women to ask potentially embarrassing questions or share intimate information that might have medical &amp; emotional relevance. Midwives do not share medical information effectively, offering generic, complicated, and dense information to pregnant women who often do not retain or value it. The low expectation on the  value brought by ANC appointments, and the uncertainty of the actual costs of services for families, caused by the accumulation of small, and unforeseen costs, hinders the timely adherence to ANC and related exams.',

        };
    },
    methods: {
        mali() {
            this.countryColor = '--gradient: #29CAD3;';
            this.currentClass = 'country-message country-message-mali';
            this.countryMessage = 'A pregnant woman’s ANC consultation is usually conducted with more than 4 people in the room (both midwives and interns) who are rarely introduced and often discuss amongst themselves during consultations. This setting, along with the rushed and procedural dynamics of the visit, offers very little space for pregnant women to ask potentially embarrassing questions or share intimate information that might have medical &amp; emotional relevance. Midwives do not share medical information effectively, offering generic, complicated, and dense information to pregnant women who often do not retain or value it. The low expectation on the  value brought by ANC appointments, and the uncertainty of the actual costs of services for families, caused by the accumulation of small, and unforeseen costs, hinders the timely adherence to ANC and related exams.';
        },

        malawi() {
            this.countryColor = '--gradient: #00475C;';
            this.currentClass = 'country-message country-message-malawi';
            this.countryMessage = 'There is inconsistent messaging between what the health service requires from pregnant women and what they are asked to do at health facilities. Often, pregnant women in early stages of pregnancy are sent back home for not being “pregnant enough” or for late attendance to health talks. It is not clear for many women when and why to visit a health facility. This uncertainty leads women to feel anxious regarding what to expect from healthcare providers during their pregnancy journey. They fear being tested and traveling to the health facility or pharmacy when they might not be supplied with what they need. They fear being sent back, because of the facility’s daily capacity. Multigravidas do not see ANC visits as valuable because they feel they are familiar with the process and know what to expect. As a result, they intentionally delay or deprioritize ANC attendance, feeling like there is nothing new for them to learn from it. Finally, women do not usually interact with their pregnancy health booklet unless to remember a specific appointment. The information therein is considered only relevant for health providers and not women themselves.';
        },

        ethiopia() {
            this.countryColor = '--gradient: #DCC332;';
            this.currentClass = 'country-message country-message-ethiopia';
            this.countryMessage = 'Postnatal care is not considered a service as such – it is largely reduced to the need for child immunization, where women are unlikely to receive individual attention to discuss their situation. Instead, they are attended simultaneously with other mothers. Family members (in postnatal, mostly mothers in law), are considered closer and more familiar to pregnant women because they follow traditional rituals and culture. They are also more available both in terms of time and emotion to listen and to and support pregnant women. Their advice, which often differs from advice of health providers, is therefore easier to receive, retain, and follow. Women are usually not familiarized with danger signs. A PNC visit is normally used as a last resource and often when their baby, or themselves, have reached a critical health situation. Upon arrival at the health facility, they expect immediate attention from health professionals, who are usually perceived to be rude or aggressive, in an interaction of few mutual empathy.';
        },

        kenya() {
            this.countryColor = '--gradient: #ED6E53;';
            this.currentClass = 'country-message country-message-ken';
            this.countryMessage = 'Having learned about pregnancy and motherhood from friends, female relatives, community health workers, or their pharmacist, young women perceived the information they receive at a health facility as not useful. In addition, the information and care provided is not often differentiated between first time, single mothers or experienced mothers, nor adapted to their different behaviors, psychological and emotional needs. There are generally very low expectations among women and girls, about the emotional fulfillment that may come from an ANC visit. Pregnant women often reduce the importance of these services to the registration and collection of a health booklet that are required for the birth at a facility, as well as an appointment where they plan and prepare for delivery.<br /> Pregnant women and their husbands experience frustration and aversion for health facilities due to the lack of privacy and clear communication about certain procedures. Those who visit health facilities often describe unpleasant interactions that discourage them to return such as unexpected medical procedures that are either painful or intimidating.';
        },
    },

};
</script>

<style lang="css" scoped>
.country-message {
    background: var(--gradient);
    color: var(--white);
}

.country-message {
    position: relative;
    padding: 1em;
    border-radius: 7px;
    font-family: var(--lato);
    font-weight: 600;
    font-size: 0.8em;
    width: 90%;
    margin: 0 auto;
}

/* .country-message-mali:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--gradient);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 5%;
  bottom: -17%;
}

.country-message-malawi:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--gradient);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 32%;
  bottom: -10%;
}

.country-message-ethiopia:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid var(--gradient);
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 57%;
  bottom: -10%;
}

.country-message-ken:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid var(--gradient);
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 80%;
  bottom: -10%;
} */

.country-controls {
    display: flex;
    justify-content: space-between;
    padding: 1em;
}

.country-button {
    flex: 1;
    color: var(--white);
    font-size: 0.8em;
    font-weight: 600;
    padding: 0.5em;
    border-radius: 7px;
    margin: 2px;
    opacity: 1;
}

.button-mali {
    background: var(--teal);
}

.button-malawi {
    background: var(--dark-green);
}

.button-ethiopia {
    background: var(--dark-mustard);
}

.button-kenya {
    background: var(--orange);
}
</style>
