<template>
    <div class="container">
        <div class="controls">
            <!-- <button
                @click="ethiopia"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-gray-500
                        hover:bg-custom-turquoise-hover
                        focus:outline-none">
                MALI
            </button>

            <button
                @click="ethiopia"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-gray-500
                        hover:bg-button-green-hover
                        focus:outline-none">
                MALAWI
            </button> -->

            <button
                @click="ethiopia"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-mustard
                        hover:bg-custom-yellow
                        focus:outline-none">
                ETHIOPIA
            </button>

            <!-- <button
                @click="ethiopia"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-gray-500
                        hover:bg-custom-orange-hover
                        focus:outline-none">
                KENYA
            </button> -->
        </div>
        <div class="message-section">
            <div
                class="card"
                :style="cardColor ">
                <span class="card-title">
                    {{cardTitleOne}}
                </span>
                <div class="card-message">
                    {{cardMessageOne}}
                </div>
                <div class="card-image">
                    <img
                        src="../../../assets/images/pregnancy/mother-icon.png"
                        alt="">
                </div>
            </div>

            <div
                class="card"
                :style="cardColor ">
                <span class="card-title">
                    {{cardTitleTwo}}
                </span>
                <div class="card-message">
                    {{cardMessageTwo}}
                </div>
                <div class="card-image">
                    <img
                        src="../../../assets/images/pregnancy/people-icon.png"
                        alt="">
                </div>
            </div>

            <div
                class="card"
                :style="cardColor">
                <span class="card-title">
                    {{cardTitleThree}}
                </span>
                <div class="card-message">
                    {{cardMessageThree}}
                </div>
                <div class="card-image">
                    <img
                        src="../../../assets/images/pregnancy/nurse-icon.png"
                        alt="">
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            cardColor: '--gradient: #DCC332;',
            cardTitleOne: 'PREGNANT WOMAN',
            cardTitleTwo: 'SOCIETY’S EXPECTATION',
            cardTitleThree: 'HEALTH PRACTITIONNERS',
            cardMessageOne: 'Most of the mothers only return to the health facility for immunisation, and if they haven’t delivered in a health centre they often still go to get their child vaccinated. For many mothers, it’s more important to seek health services on this day than on delivery day.',
            cardMessageTwo: 'PNC is mostly understood and valued for the sake of immunisation. Most people know this is a must-go day for the care & protection of the newborn. For husbands, however, delivery day is often more important, as many believe that if the baby is born healthy, it will always be healthy/ Mothers are expected to be the main responsibles to ensure that this happens.',
            cardMessageThree: 'Midwives feel overwhelmed, a lot of information to give at the same time, they prefer to do immunisation session up to 3 to 5 mothers at the same time for it to be a faster session. Health extension workers can help on immunisation but they barely do – this turns a stressfull moment between health providers.',

        };
    },
    methods: {
        mali() {
            this.cardColor = '--gradient: #29CAD3;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'No findings.';
            this.cardMessageTwo = 'No findings.';
            this.cardMessageThree = 'No findings.';
        },

        malawi() {
            this.cardColor = '--gradient: #00475C;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'No findings.';
            this.cardMessageTwo = 'No findings.';
            this.cardMessageThree = 'No findings.';
        },

        ethiopia() {
            this.cardColor = '--gradient: #DCC332;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'Most of the mothers only return to the health facility for immunisation, and if they haven’t delivered in a health centre they often still go to get their child vaccinated. For many mothers, it’s more important to seek health services on this day than on delivery day.';
            this.cardMessageTwo = 'PNC is mostly understood and valued for the sake of immunisation. Most people know this is a must-go day for the care & protection of the newborn. For husbands, however, delivery day is often more important, as many believe that if the baby is born healthy, it will always be healthy/ Mothers are expected to be mainly responsible to ensure that this happens.';
            this.cardMessageThree = 'Midwives feel overwhelmed, a lot of information to give at the same time, they prefer to do immunisation session up to 3 to 5 mothers at the same time to be a faster session. HEWs can help on immunisation but they barely do – this turns a stressfull moment between health providers.';
        },

        kenya() {
            this.cardColor = '--gradient: #ED6E53;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'No findings.';
            this.cardMessageTwo = 'No findings.';
            this.cardMessageThree = 'No findings.';
        },
    },

};
</script>

<style lang="css" scoped>
.controls {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 1em;
    margin: -5px 0;
    /* flex-wrap: wrap; */

}

.controls > button {
    /* padding: 0.5em 45px 0.5em 45px; */
      width: 100%;

}

.message-section {
     display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1em;
    /* border: 1px solid blue; */
    background-color: white;

}

.card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* word-wrap: break-word; */
    overflow-wrap: break-word;
    /* --gradient:#29CAD3; */
    background-color: var(--gradient);
    width: 30%;
    min-width: 250px;
    border-radius: 5px;
    padding: 1em;
    margin: auto;
    margin-bottom: 3em;
    position: relative;
    animation: moveInBottom 1s ease-out;
}

.card-title {
    font-family: var(--big-john-bold);
    font-size: 0.7em;
    margin: 1.5em 0 1em 0;
}

.card-message {
    font-family: var(--lato);
    font-size: 0.7em;
    min-height: 200px;
}

.card-image {
    margin: 1em auto -3.7em auto;
    height: 55px;
    width: 55px;
}

.card:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--gradient);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 197px;
  bottom: -19px;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }
</style>
