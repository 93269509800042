<template>
    <div class="insight-content">
        <div
            :class="currentClass"
            :style="countryColor">
            {{countryMessage}}
        </div>

        <div class="country-controls">
            <button
                @click="mali()"
                class="country-button button-mali">
                MALI
            </button>

            <button
                @click="malawi()"
                class="country-button button-malawi">
                MALAWI
            </button>

            <button
                @click="ethiopia()"
                class="country-button button-ethiopia">
                ETHIOPIA
            </button>

            <button
                @click="kenya()"
                class="country-button button-kenya">
                KENYA
            </button>
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            countryColor: '--gradient: #29CAD3;',
            currentClass: 'country-message country-message-mali',
            countryMessage: 'Pregnant women are expected to quietly cope with their physiological and emotional transformations while showing respect to (i.e., following the advice of) the many authority figures around them (mother-in-law, husband, health practitioners).',

        };
    },
    methods: {
        mali() {
            this.countryColor = '--gradient: #29CAD3;';
            this.currentClass = 'country-message country-message-mali';
            this.countryMessage = 'Pregnant women are expected to quietly cope with their physiological and emotional transformations while showing respect to (i.e., following the advice of) the many authority figures around them (mother-in-law, husband, health practitioners).';
        },

        malawi() {
            this.countryColor = '--gradient: #00475C;';
            this.currentClass = 'country-message country-message-malawi';
            this.countryMessage = 'The social expectations about women’s self-reliance during pregnancy, play a negative influence on the health-seeking behavior of pregnant women and their families. There are unspoken rules from tradition, enforced by families, that dictate what a pregnant woman should eat or wear, and these are not necessarily aligned with a woman’s (modernity) wishes. The expectation and recommendation to start ANC within the first trimester also conflicts with local cultural beliefs that discourage disclosing pregnancy early / in the first trimester or seeking support outside the household environment.';
        },

        ethiopia() {
            this.countryColor = '--gradient: #DCC332;';
            this.currentClass = 'country-message country-message-ethiopia';
            this.countryMessage = 'Women are expected to have a natural mother’s ‘instinct’ and to know exactly what is best for their baby. After the 10th day following birth, a woman is seen to be fully responsible for what may happen to the baby. If she has doubts or questions this may be interpreted as a sign of ‘bad motherhood’ and is likely to be judged or criticized. Women are not likely to expose themselves to this stigma and therefore, rarely seek support outside the household or their trusted social network. A woman and her partner are likely to feel guilty for a health complication encountered by the baby.';
        },

        kenya() {
            this.countryColor = '--gradient: #ED6E53;';
            this.currentClass = 'country-message country-message-ken';
            this.countryMessage = 'First-time pregnant women are expected to know how to dress, eat, and seek care, as well as experienced pregnant women. First time mothers are often insecure, ill-prepared and fearful. Multigravidas often disregard the importance of health services because it offers nothing new to them; they’ve previously sought care and had an unpleasant and deemed unnecessary experience. Young pregnant girls are more likely to deal with their circumstances alone and only confide in others who ensure secrecy (e.g. friends or pharmacists).';
        },
    },

};
</script>

<style lang="css" scoped>
.country-message {
    background: var(--gradient);
    color: var(--white);
}

.country-message {
    position: relative;
    padding: 1em;
    border-radius: 7px;
    font-family: var(--lato);
    font-weight: 600;
    font-size: 0.8em;
    width: 90%;
    margin: 0 auto;
}

/* .country-message-mali:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--gradient);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 5%;
  bottom: -17%;
}

.country-message-malawi:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--gradient);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 32%;
  bottom: -10%;
}

.country-message-ethiopia:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid var(--gradient);
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 57%;
  bottom: -10%;
}

.country-message-ken:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid var(--gradient);
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 80%;
  bottom: -10%;
} */

.country-controls {
    display: flex;
    justify-content: space-between;
    padding: 1em;
}

.country-button {
    flex: 1;
    color: var(--white);
    font-size: 0.8em;
    font-weight: 600;
    padding: 0.5em;
    border-radius: 7px;
    margin: 2px;
    opacity: 1;
}

.button-mali {
    background: var(--teal);
}

.button-malawi {
    background: var(--dark-green);
}

.button-ethiopia {
    background: var(--dark-mustard);
}

.button-kenya {
    background: var(--orange);
}
</style>
