<template>
    <nav>
        <div class="wrapper">
            <div class="logo">
                <router-link to="/">
                    <img
                        src="../../assets/images/jhpiego-logo-white.svg"
                        alt="logo"
                        class="home-logo">
                </router-link>
            </div>

            <input
                type="radio"
                name="slider"
                id="menu-btn">

            <input
                type="radio"
                name="slider"
                id="close-btn">

            <ul class="nav-links">
                <label
                    for="close-btn"
                    class="btn close-btn">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-7 w-7"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="#fff">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2
                            2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </label>

                <li>
                    <router-link
                        to="/resources">
                        Resources
                    </router-link>
                </li>
                <li>
                    <router-link
                        to="/contact">
                        Contact Us
                    </router-link>
                </li>
            </ul>
            <label
                for="menu-btn"
                class="btn menu-btn">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-8 w-8"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4 6h16M4 12h16M4 18h16" />
                </svg>
            </label>
        </div>
    </nav>
</template>

<style lang="css" scoped>
nav{
  width: 100%;
  min-height: 80px;
  padding: 1em 0;
}

nav .wrapper{
  position: relative;
  /* max-width: 1300px; */
  padding: 0px 30px;
  word-wrap: none;
  line-height: 70px;
  white-space: nowrap;
  /* margin: 0 0 0 3em; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo >>> img{
    height: 70px;
}
.wrapper .nav-links{
  display: inline-flex;
}

.nav-links li{
  list-style: none;
}

.nav-links li a{
  padding: 9px 15px;
  text-decoration: none;
  transition: all 0.3s ease;
}
.nav-links li a:hover{
  background: var(--transparent-white);
}

.wrapper .btn{
  cursor: pointer;
  display: none;
}
.wrapper .btn.close-btn{
  position: absolute;
  right: 30px;
  top: 10px;
}

@media screen and (max-width: 650px) {
  .wrapper .btn{
    display: block;
  }

  .wrapper .nav-links{
    position: fixed;
    /* height: 100vh; */
    width: 100%;
    top: 0;
    left: -100%;
    background: var(--dark-green);
    display: block;
    padding: 50px 10px;
    text-align: center;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0,0,0,0.18);
    transition: all 0.3s ease;
    border-radius: 7px;
    border: 2px solid var(--white);
  }

  .logo >>> img{
      /* height: 40px; */
    }

  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #242526;
  }
  ::-webkit-scrollbar-thumb {
    background: #3A3B3C;
  }

  #menu-btn:checked ~ .nav-links{
    left: 0%;
  }

  #menu-btn:checked ~ .btn.menu-btn{
    display: none;
  }

  #close-btn:checked ~ .btn.menu-btn{
    display: block;
  }

  .nav-links li{
    margin: 15px 10px;
  }

  .nav-links li a{
    padding: 0 20px;
    display: block;
  }
}

nav input{
  display: none;
}

.home-logo {
    height: 30px;
    width: 120px;
}

/* Responsiveness */
@media (max-width: 768px) {
    nav .wrapper{
        margin: auto;
        }
}

@media (max-width: 480px) {
    nav .wrapper{
        margin: auto;
        }

    .home-logo {
      height: 30px;
      width: 70px;
      }
}
</style>
