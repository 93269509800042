<template>
    <div class="content">
        <div class="navbar-section">
            <nav-bar/>
        </div>

        <div class="header-section">
            <h1>
                RESOURCES
            </h1>
        </div>

    </div>
</template>

<script>
import NavBar from '@/components/UI/NavBar.vue';

export default {
    name: 'Header',
    components: {
        NavBar,
    },
};
</script>

<style lang="css" scoped>
.content {
    display: flex;
    flex-direction: column;
    background-color: var(--dark-green);
}

.header-section {
    text-align: center;
    font-family: var(--big-john-bold);
    font-size: 2.5em;
    font-weight: 700;
    letter-spacing: 2px;
    color: var(--mustard);
    padding-bottom: 0.2em;
}

@media (max-width: 500px) {
    .header-section {
        font-size: 2em;
    }
}
</style>
