<template>
    <!-- eslint-disable max-len  -->
    <div class="container">
        <div class="controls">
            <button
                @click="mali"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-turquoise
                        hover:bg-custom-turquoise-hover
                        focus:outline-none">
                MALI
            </button>

            <button
                @click="malawi"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-button-green
                        hover:bg-button-green-hover
                        focus:outline-none">
                MALAWI
            </button>

            <!-- <button
                @click="ethiopia"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-mustard
                        hover:bg-custom-yellow
                        focus:outline-none">
                ETHIOPIA
            </button> -->

            <button
                @click="kenya"
                class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-orange
                        hover:bg-custom-orange-hover
                        focus:outline-none">
                KENYA
            </button>
        </div>
        <div class="message-section">
            <div
                class="card"
                :style="cardColor ">
                <span class="card-title">
                    {{cardTitleOne}}
                </span>
                <div class="card-message">
                    {{cardMessageOne}}
                </div>
                <div class="card-image">
                    <img
                        src="../../../assets/images/pregnancy/mother-icon.png"
                        alt="">
                </div>
            </div>

            <div
                class="card"
                :style="cardColor ">
                <span class="card-title">
                    {{cardTitleTwo}}
                </span>
                <div
                    class="card-message"
                    v-html="cardMessageTwo">

                </div>
                <div class="card-image">
                    <img
                        src="../../../assets/images/pregnancy/people-icon.png"
                        alt="">
                </div>
            </div>

            <div
                class="card"
                :style="cardColor">
                <span class="card-title">
                    {{cardTitleThree}}
                </span>
                <div class="card-message">
                    {{cardMessageThree}}
                </div>
                <div class="card-image">
                    <img
                        src="../../../assets/images/pregnancy/nurse-icon.png"
                        alt="">
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            cardColor: '--gradient: #29CAD3;',
            cardTitleOne: 'PREGNANT WOMAN',
            cardTitleTwo: 'SOCIETY’S EXPECTATION',
            cardTitleThree: 'HEALTH PRACTITIONNERS',
            cardMessageOne: 'I decide to go to ANC to seek a midwive that can play the role of a discrete confident & a life-coach, but I often find an accusatory, indifferent and money-driven midwive. In addition, my consultation is done by 2 midwives and 3 interns who don’t listen, care or respect me. I’m not comfortable asking about the things that I truly want to know.',
            cardMessageTwo: 'A pregnant woman should act like a rational hero: Comply with regularly taking very unpalatable medication & expensive medical tests, hide the fact that she is tired, nauseous or in pain, or she will be called lazy Keep on caring for her household and her daily chores “while smiling”, “with patience” and without complaining, getting “angry or annoyed”.',
            cardMessageThree: 'I expect pregnant women to be rational & respectful, which means to come for ANC early and to follow my medical instructions. Many women don’t, which makes me percieve them as negligent & lazy.',

        };
    },
    methods: {
        mali() {
            this.cardColor = '--gradient: #29CAD3;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'I decide to go to ANC to seek a midwive that can play the role of a discrete confident & a life-coach, but I often find an accusatory, indifferent and money-driven midwive. In addition, my consultation is done by 2 midwives and 3 interns who don’t listen, care or respect me. I’m not comfortable asking about the things that I truly want to know.';
            // eslint-disable-next-line max-len
            // this.cardMessageTwo = '<b>A pregnant woman should act like a rational hero:</b> </br> <ul> <li> &middot; <b>Comply</b> with regularly taking very unpalatable medication & expensive medical tests,</li> <li>&middot; <b>hide the fact that</b> she is tired, nauseous or in pain, or she will be called lazy</li> <li>&middot; <b>Keep on</b> caring for her household and her daily chores “while smiling”, “with patience” and without complaining, getting “angry or annoyed”.</li> </ul>';
            this.cardMessageTwo = 'A pregnant woman should act like a rational hero: Comply with regularly taking very unpalatable medication & expensive medical tests, hide the fact that she is tired, nauseous or in pain, or she will be called lazy. She needs to Keep on caring for her household and her daily chores “while smiling”, “with patience” and without complaining, getting “angry or annoyed”.';
            this.cardMessageThree = 'I expect pregnant women to be rational & respectful, which means to come to ANC early and to follow my medical instructions. Many women don’t, which makes me percieve them as negligent & lazy.';
        },

        malawi() {
            this.cardColor = '--gradient: #00475C;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'I have been pregnant before, so I am confident that I can take care of this pregnancy. I don’t think that it is necessary to go to the hospital right now, unless I am concerned that there is a big issue (this is especially true for multigravidas, while first-timers will be keen to go as scheduled).';
            this.cardMessageTwo = 'A pregnant woman must be accompanied to ANC by the husband. If not, they have to bring a letter from the chief explaining why. It is stupid and silly for men to go to ANC with their wives. The clinic is not a man’s place Men: I am busy at work, so I cannot really go to the clinic with my wife - unless there is a big problem.';
            this.cardMessageThree = 'I wish that pregnant women will come to the health talk early. Otherwise they will miss important information about the things to bring on the delivery day. This makes things more difficult for me.';
        },

        ethiopia() {
            this.cardColor = '--gradient: #DCC332;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'No findings.';
            this.cardMessageTwo = 'No findings.';
            this.cardMessageThree = 'No findings.';
        },

        kenya() {
            this.cardColor = '--gradient: #ED6E53;';
            // card titles
            this.cardTitleOne = 'PREGNANT WOMAN';
            this.cardTitleTwo = 'SOCIETY’S EXPECTATION';
            this.cardTitleThree = 'HEALTH PRACTITIONNERS';

            // card messages
            this.cardMessageOne = 'Contrary to the actual needs for ANC, mothers generally believe that ANC is only important during the late stages of pregnancy. Maternal healthcare is usually framed around surviving, rather than thriving for both the mother and child. Choice of clinic is usually based on recommendations from friends and family on how the doctors and nurses treat women.';
            this.cardMessageTwo = 'Women are often told to attend ANC by their mothers and other female family members. For young mothers the men are usually absent.';
            this.cardMessageThree = 'Health practitioners (nurses and midwives) in smaller maternal health clinics are usually more empathetic and understanding to the concerns and worries that women express than those in larger hospitals, who are often rushed and do not have the time to listen.';
        },
    },

};
</script>

<style lang="css" scoped>
.controls {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 1em;
    margin: -5px 0;
    /* flex-wrap: wrap; */

}

.controls > button {
    /* padding: 0.5em 45px 0.5em 45px; */
      width: 100%;

}

.message-section {
     display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1em;
    /* border: 1px solid blue; */
    background-color: white;

}

.card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* word-wrap: break-word; */
    overflow-wrap: break-word;
    /* --gradient:#29CAD3; */
    background-color: var(--gradient);
    width: 30%;
    min-width: 250px;
    border-radius: 5px;
    padding: 1em;
    margin: auto;
    margin-bottom: 3em;
    position: relative;
    animation: moveInBottom 1s ease-out;
}

.card-title {
    font-family: var(--big-john-bold);
    font-size: 0.7em;
    margin: 1.5em 0 1em 0;
}

.card-message {
    font-family: var(--lato);
    font-size: 0.7em;
    min-height: 200px;
}

.card-image {
    margin: 1em auto -3.7em auto;
    height: 55px;
    width: 55px;
}

.card:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--gradient);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--gradient);
  border-bottom: 20px solid transparent;
  left: 197px;
  bottom: -19px;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }
</style>
