<template>
    <div class="main-container">
        <!-- <div
            id="preloader"
            ref="preloader"></div> -->

        <section class="header-section">
            <nav-header/>
        </section>

        <section class="challenge-section">
            <challenge/>
        </section>

        <section class="overview">
            <overview/>
        </section>

        <section class="insights">
            <insights/>
        </section>

        <section class="pregnancy-journey">
            <pregnancy-journey/>
        </section>

        <section class="hcd-section">
            <toolkit/>
        </section>

        <section class="testimonials">
            <testimonials/>
        </section>

        <section class="footer">
            <nav-footer/>
        </section>
    </div>
</template>

<script>
import NavHeader from '@/components/Home/Header2.vue';
import Challenge from '@/components/Home/Challenge.vue';
import Overview from '@/components/Home/Overview.vue';
import Insights from '@/components/Home/Insights.vue';
import PregnancyJourney from '@/components/Home/PregnancyJourney.vue';
import Toolkit from '@/components/Home/HCD.vue';
import Testimonials from '@/components/Home/Testimonials.vue';
import NavFooter from '@/components/UI/Footer.vue';

export default {
    name: 'Home',
    components: {
        NavHeader,
        Challenge,
        Overview,
        Insights,
        PregnancyJourney,
        Toolkit,
        Testimonials,
        NavFooter,
    },

    // mounted() {
    //     const loader = this.$refs.preloader;

    //     window.addEventListener('load', () => {
    //         loader.style.display = 'none';
    //         // document.body.classList.add('header-section');
    //     });
    // },

};
</script>

<style>
.main-container {
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth
}

#preloader {
    background: linear-gradient(0deg, rgba(2,100,128,1) 0%, rgba(0,71,92,1) 100%)
    url('../assets/images/home/loader.gif') no-repeat center center;
    background-size: 20%;
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 100;
}

</style>
