<template>
    <div class="insight-breakdown">
        <div class="insight-title">
            <div
                class="main-section"
                :style="newStyle">
                <div class="insight-number">
                    <span class="word">Insight</span>
                    <span class="number">02</span>
                </div>

                <div
                    class="insight-description"
                    :style="insightDescStyle">
                    Male partners and trusted family members of pregnant women,
                    whom they must rely on to
                    make decisions, are usually excluded of maternal health sevices.
                    Services are directed at women
                    without a deliberate effort to either, increase their self-determination
                    in relation to their health,
                    or to embrace others’ responsibilities and views. This exacerbates the
                    solitude that a mother
                    experiences in the facility-based pregnancy and postpartum journeys.
                </div>
            </div>

            <div
                class="context-header"
                :style="subHeaderStyle">
                {{subHeader}}
            </div>

            <div class="country-info">
                <!-- <transition
                    name="component-fade"
                    appear> -->
                <component
                    :is="view"></component>
                <!-- </transition> -->
            </div>

            <div class="country-buttons">
                <button
                    @click="viewMali()"
                    class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-turquoise
                        hover:bg-gray-500
                        focus:outline-none">
                    MALI
                </button>

                <button
                    @click="viewMalawi()"
                    class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-button-green
                        hover:bg-gray-500
                        focus:outline-none">
                    MALAWI
                </button>

                <button
                    @click="viewEthio()"
                    class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-mustard
                        hover:bg-gray-500
                        focus:outline-none">
                    ETHIOPIA
                </button>

                <button
                    @click="viewKen()"
                    class="rounded
                        font-bold
                        px-3 py-2
                        m-1 text-white
                        bg-custom-orange
                        hover:bg-gray-500
                        focus:outline-none">
                    KENYA
                </button>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            view: '',
            newStyle: '',
            insightDescStyle: '',
            subHeader: 'SELECT COUNTRY TO LEARN MORE ABOUT THIS INSIGHT',
            subHeaderStyle: '',
        };
    },

    components: {
        componentMali: {
            template: '<div class="message-section message-mali">Partners have many roles to play during pregnancy: from financing to gathering information to “policing” a woman’s pregnancy journey. Yet, few fathers feel the need or the desire to visit the health facility at any point of the pregnancy, as neither they nor their partners see such facilities as appropriate place for men. Partners thus rarely receive quality health information in a way that directly speaks to their fears (financial, emotional, social) and perceived needs (e.g. planning, working, saving, supporting their family). Ultimately, it is women who visit the health facility and receive recommendations or requests from health providers that are responsible to inform, justify and persuade possibly ill-informed decision-making gate keepers.</div>',
        },
        componentMalawi: {
            template: '<div class="message-section message-malawi">Men’s participation in ANC is hampered by a service that is at odds with the community’s definition of masculinity. Men want to be involved in the pregnancy journey. However, the interaction points at the health facility conflict with their social and cultural roles. Men want a service that affirms their masculinity, characterized by being shorter, faster, more discreet, and separate from women-led activities. In Malawi, the attendance of husbands to the first ANC appointment is de facto mandatory. Health facilities and communities actively incentivize compliant men by giving them first place in line, but penalize others by strategies like charging fines or requiring written justification from chief elders for the absence of men in ANC.</div>',
        },
        componentEthio: {
            template: '<div class="message-section message-ethio">The men who live within the household, are in general the first to be consulted if a pregnant woman has a question. In case a man does not know the answer to a question or the solution to a problem, he is responsible to look for support. This is likely to happen at first within his trusted social network, e.g., direct family, religious leaders or healers, and second from the formal health system. In case of a complication, however, husbands become strong advocates for formal healthcare support. Despite their sense of responsibility, men or their direct family are rarely engaged in the information exchange between health providers and women who just delivered at a health facility.</div>',
        },
        componentKen: {
            template: '<div class="message-section message-ken">Male involvement is not perceived by women in the community as necessary or desirable. Men arenot expected to be available for such involvement either. When men are keen to be involved in appointment or the delivery, they are often met with resistance and pushed away, e.g., actively advised against holding a newborn. A pregnant woman is more likely to count on female elders from the family or from her community to accompany her to the health facility. This matters because many decisions about a pregnant woman’s life are often made by her family members and not herself.</div>',
        },
    },

    methods: {
        viewMali() {
            if (this.view !== 'componentMali') {
                this.view = 'componentMali';
            } else {
                this.view = 'componentMali';
            }

            this.subHeader = 'CONTEXTUAL BACKGROUND';
            this.newStyle = 'display : flex; flex-direction : row;  ';
            this.insightDescStyle = 'text-align: left; width: 70%';
            this.subHeaderStyle = 'text-align: left;';
        },
        viewMalawi() {
            if (this.view !== 'componentMalawi') {
                this.view = 'componentMalawi';
            } else {
                this.view = 'componentMalawi';
            }

            this.subHeader = 'CONTEXTUAL BACKGROUND';
            this.newStyle = 'display : flex; flex-direction : row;  ';
            this.insightDescStyle = 'text-align: left; width: 70%';
            this.subHeaderStyle = 'text-align: left;';
        },
        viewEthio() {
            if (this.view !== 'componentEthio') {
                this.view = 'componentEthio';
            } else {
                this.view = 'componentEthio';
            }

            this.subHeader = 'CONTEXTUAL BACKGROUND';
            this.newStyle = 'display : flex; flex-direction : row;  ';
            this.insightDescStyle = 'text-align: left;  width: 70%';
            this.subHeaderStyle = 'text-align: left;';
        },
        viewKen() {
            if (this.view !== 'componentKen') {
                this.view = 'componentKen';
            }

            this.subHeader = 'CONTEXTUAL BACKGROUND';
            this.newStyle = 'display : flex; flex-direction : row;  ';
            this.insightDescStyle = 'text-align: left; width: 70%';
            this.subHeaderStyle = 'text-align: left;';

            // this.mainSecStyle = 'font-size: 0.7em';
        },
    },
};
</script>

<style lang="css" scoped>

.insight-breakdown {
    display: flex;
    flex-direction: row;
    /* width: 60%; */
    /* margin: 3em auto; */
    /* border: 3px solid green; */
    justify-content: center;
    animation: moveInRight 1s ease-out;
}

.insight-number {
    width: 190px;
    height: 190px;
    background: var(--white);
    color: var(--text-gray);
    margin: 0 auto;
    /* border: 3px solid red; */
    border-radius: 50%; /* remove if you don't care about IE8 */
}

.insight-number {
    font-weight: 700;
    font-family: var(--bebas);
/* } */

/* .insight-number > h2 { */
    display: flex;
    flex-direction: column;
    text-align: center;
}

.word {
    margin-top: 1.5em;
    font-size: 1.7em;
    /* border: 1px solid red; */
}

.number {
    flex: 1;
    margin-top: -30px;
    font-size: 5em;
    /* border: 1px solid red; */

}

.insight-description {
    display: flex;
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    margin-top: 1em;
    font-family: var(--big-john-bold);
    font-size: 1.5em;
    /* padding: 1.5em; */
    font-weight: 600;
    text-align: center;
    /* border: 1px solid red; */
}

.context-header {
    text-align: center;
    letter-spacing: 2px;
    font-family: var(--lato);
    margin: 3em auto;
    font-size: 0.7em;
    font-weight: 700;
    /* margin: 0 auto; */
    width: 80%;
    /* border: 1px solid red; */
}

.country-buttons {
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    align-items: center;
    margin: 0 auto;
    margin-top: 2em;
    /* border: 1px solid red; */
    width: 80%;
    font-size: 1em;
}

button {
    padding: 0.4em auto;
}

.main-section {
    display: flex;
    flex-direction: column;
    /* border: 1px solid blue; */
}

.message-section {
    position: relative;
    padding: 2em;
    border-radius: 7px;
    /* font-size: 1.4em; */
    font-family: var(--lato);
    font-weight: 700;
    width: 80%;
    margin: 0 auto;
    /* border: 1px solid blue; */
}

.message-ken {
    background-color: var(--orange);
    animation: moveInRight 1s ease-out;
}

.message-ken:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid var(--orange);
  border-top: 20px solid var(--orange);
  border-bottom: 20px solid transparent;
  left: 86%;
  bottom: -10%;
}

.message-mali {
    background-color: var(--light-green);
    animation: moveInLeft 1s ease-out;
}

.message-mali:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--light-green);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--light-green);
  border-bottom: 20px solid transparent;
  left: 5%;
  bottom: -8%;
}
.message-malawi {
    background-color: var(--dark-green-2);
    animation: moveInLeft 1s ease-out;
}

.message-malawi:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid var(--dark-green-2);
  border-right: 20px solid transparent;
  border-top: 20px solid var(--dark-green-2);
  border-bottom: 20px solid transparent;
  left: 32%;
  bottom: -8%;
}

.message-ethio {
    background-color: var(--light-mustard);
    animation: moveInRight 1s ease-out;
}

.message-ethio:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid var(--light-mustard);
  border-top: 20px solid var(--light-mustard);
  border-bottom: 20px solid transparent;
  left: 57%;
  bottom: -8%;
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@media (max-width: 500px) {
    .main-section {

    }

    .insight-description {
        font-size: 0.8em;
        padding: 0.5em;
        /* border: 1px solid red; */
        width: 70%;
        margin:0 auto;
    }

    .insight-number {
        margin-top: 1em;
        width: 150px;
        font-size: 0.85em;
        height: 150px;
        /* border: 1px solid red; */
    }

    .country-buttons {
        margin-top: 1.8em;
        font-size: 0.5em;
        /* border: 1px solid red; */
    }

    button {
        padding: 0.5em 0.4em;
    }

    .message-ken:before {
        border-left: 11px solid transparent;
        border-right: 11px solid var(--orange);
        border-top: 11px solid var(--orange);
        border-bottom: 11px solid transparent;
        left: 86%;
        bottom: -6%;
    }

    .message-mali:before {
        border-left: 11px solid var(--light-green);
        border-right: 11px solid transparent;
        border-top: 11px solid var(--light-green);
        border-bottom: 11px solid transparent;
        left: 5%;
        bottom: -6%;
    }

    .message-malawi:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 11px solid var(--dark-green-2);
        border-right: 11px solid transparent;
        border-top: 11px solid var(--dark-green-2);
        border-bottom: 11px solid transparent;
        left: 32%;
        bottom: -6%;
    }

    .message-ethio:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 11px solid transparent;
        border-right: 11px solid var(--light-mustard);
        border-top: 11px solid var(--light-mustard);
        border-bottom: 11px solid transparent;
        left: 57%;
        bottom: -6%;
    }

}
</style>
