<template>
    <div class="content">
        <div class="info-container">
            <div class="info-section">
                <h2 class="title">
                    <span>MACRO LEVEL</span>
                    <span>INSIGHTS</span>
                </h2>

                <div class="description-section">
                    <span>ThinkPlace and Jhpiego emphasize five rich “macro” insights that reveal challenging maternal healthcare dynamics beyond borders and across the different geographies. These insights are aimed to inspire and encourage designers and practitioners to further investigate different factors that influence the access and uptake of maternal health services.</span>
                </div>

                <div class="button-section">
                    <router-link to="/insights">
                        <button
                            class="
                            font-bold
                            rounded px-3 py-2
                            m-1 text-white
                            bg-button-green
                            hover:bg-custom-turquoise-hover
                            transition duration-500 ease-in-out
                            transform hover:-translate-y-1 hover:scale-110
                            focus:outline-none">
                            DISCOVER THE INSIGHTS
                        </button>
                    </router-link>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InsightsComp',
};
</script>

<style lang="css" scoped>
.content {
    display: flex;
    justify-content: center;
    padding: 1em;
    background-image: linear-gradient( rgba(1, 72, 95, 0.6), rgba(1, 72, 95, 0.65)),
    url('../../assets/images/home/IMG_3263.png');
    background-size: cover;
    font-family: var(--lato);
    min-height: 80vh;
}

.info-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1em;
    width: 80%;
}

.info-section, .title, .description-section {
    display: flex;
    flex-direction: column;
}

.info-section {
    /* padding: 1.4em; */
    /* margin: 10em 0 0 10em; */
    /* height: 50%; */
    /* margin-left: 1em; */
}

.title {
    font-weight: 900;
    font-size: 3em;
    font-family: var(--big-john-bold);
}

.description-section {
    margin: 1.5em 0;
    font-size: 0.8rem;
}

/* media queries */
@media (max-width: 600px) {
    .info-section {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        text-align: center;
        }
}
/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1200px) {
    .description-section {
        max-width: 400px;
    }
}
</style>
