<template>
    <div class="container">
        <div class="title-area">
            <h2>
                For all enquiries, please email us using the form below
            </h2>
        </div>

        
        <div data-tf-widget="N92jh7O1" data-tf-iframe-props="title=ARC Contact" data-tf-medium="snippet" style="width:100%;height:400px;"></div>

    </div>
</template>

<script>
export default {
    name: 'ContactForm',
    setup() {
    const embedScript = document.createElement("script");
    embedScript.setAttribute(
      "src",
      "//embed.typeform.com/next/embed.js"
    );
    document.head.appendChild(embedScript);
     const contact = () => {
      event('contact', { method: 'Google' })
    }
    
    return {
      contact
    }
    },
};
</script>

<style lang="css" scoped>
.container {
    display: flex;
    flex-direction: column;
    height: auto;
    margin: 0 auto;
    width: 70%;
    min-height: 100vh;
    padding: 2em;
}

.title-area {
    color: var(--dark-mustard);
    font-family: var(--big-john-bold);
    font-size: 1.7em;
    margin: 2em;
}

.form-area {
    color: var(--text-gray);
    font-family: var(--lato);
    font-size: 1em;
    font-weight: 600;
    /* border: 1px solid goldenrod; */
    padding: 1em;
    margin: 0 2.7em;
}

.name-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

span {
    flex: 1;
    display: block;
    margin: 0.5em;
}

input[type=text], textarea {
  width: 99%;
  padding: 0.3em 0.3em;
  border: 1px solid var(--text-gray);
  border-radius: 1px;
}

@media (max-width: 706px){
    .container {
        width: 100%;
        font-size: 0.8em;
    }
}
</style>
