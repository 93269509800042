<template>
    <!-- eslint-disable max-len  -->
    <div class="content">
        <div class="info-header">
            <span class="info-title">HCD TOOLKIT</span>
            <span class="info-description">
                ThinkPlace and Jhpiego collaboratively designed and tailored community engagement
                tools that were used by designers and researchers during the immersive research
                phase. These tools complement traditional qualitative techniques like in-depth
                interviews and observations, and involve interactive activities with participants
                to help uncover information that often is not possible to be collected through
                dialogue.
            </span>
        </div>

        <div class="carousel-section">
            <div class="components-container">
                <component
                    :is="currentSlide">
                </component>

                <div class="controls">
                    <div
                        class="toolkit-nav"
                        @click="currentSlide = 'Toolkit1'"
                        :style="[currentSlide == 'Toolkit1' ? {'--secondary-color' : `${primaryColor}`, '--primary-color' : `${secondaryColor}`} : null]">
                    </div>

                    <div
                        class="toolkit-nav"
                        @click="currentSlide = 'Toolkit2'"
                        :style="[currentSlide == 'Toolkit2' ? {'--secondary-color' : `${primaryColor}`, '--primary-color' : `${secondaryColor}`} : null]">
                    </div>

                    <div
                        class="toolkit-nav"
                        @click="currentSlide = 'Toolkit3'"
                        :style="[currentSlide == 'Toolkit3' ? {'--secondary-color' : `${primaryColor}`, '--primary-color' : `${secondaryColor}`} : null]">
                    </div>

                    <div
                        class="toolkit-nav"
                        @click="currentSlide = 'Toolkit4'"
                        :style="[currentSlide == 'Toolkit4' ? {'--secondary-color' : `${primaryColor}`, '--primary-color' : `${secondaryColor}`} : null]">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Toolkit1 from '@/components/Home/HCD/Toolkit1.vue';
import Toolkit2 from '@/components/Home/HCD/Toolkit2.vue';
import Toolkit3 from '@/components/Home/HCD/Toolkit3.vue';
import Toolkit4 from '@/components/Home/HCD/Toolkit4.vue';

export default {
    name: 'HCD',
    components: {
        Toolkit1,
        Toolkit2,
        Toolkit3,
        Toolkit4,
    },

    data() {
        return {
            index: 1,
            currentSlide: 'Toolkit1',
            primaryColor: '#1FACB5',
            secondaryColor: '#F7941D',
            slides: [
                'Toolkit1',
                'Toolkit2',
                'Toolkit3',
                'Toolkit4',
            ],
        };
    },

    methods: {
        async prevButton() {
            const currentIndex = this.index;
            const selectedSlide = this.slides[currentIndex];
            this.currentSlide = selectedSlide;

            // eslint-disable-next-line no-plusplus
            this.index++;
            if (this.index > this.slides.length - 1) {
                this.index = 0;
            } else if (this.index < 0) {
                this.index = this.slides.length - 1;
            }
        },

        async nextButton() {
            const currentIndex = this.index;
            const selectedSlide = this.slides[currentIndex];
            this.currentSlide = selectedSlide;

            // eslint-disable-next-line no-plusplus
            this.index--;
            if (this.index > this.slides.length - 1) {
                this.index = 0;
            } else if (this.index < 0) {
                this.index = this.slides.length - 1;
            }
        },
    },
};
</script>

<style lang="css" scoped>
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
}

.info-header {
    display: flex;
    flex-direction: column;
    width: 77%;
    padding: 1em 0;
}

.info-title {
    color: var(--dark-blue);
    font-weight: 900;
    font-size: 3em;
    font-family: var(--big-john-bold);
}

.info-description {
    color: rgb(78, 78, 78);
    font-size: 1em;
    font-family: var(--lato);
}

.carousel-section {
    width: 87%;
    padding: 1em;
}

.controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.toolkit-nav {
    --primary-color: #1FACB5;
    --secondary-color: #F7941D;
    background: var(--primary-color);
    height: 15px;
    width: 15px;
    margin: 3px;
    border-radius: 50%;
}

.toolkit-nav:hover {
    border: 2px solid var(--secondary-color);
}

@media (max-width: 500px) {
  .info-header {
      width: 100%;
  }

  .carousel-section {
      width: 100%;
      padding: 0;
}
}
</style>
